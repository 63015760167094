import React from 'react'
import ReactDOM from 'react-dom'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './static/styles/index.scss'
import { BrowserRouter } from 'react-router-dom'
import focusCleanner from './utils/focusCleanner'
import { ReactQuery } from '@components/hoc/ErrorBoundary/ReactQuery'
import moment from 'moment'
import 'moment/locale/ru'
moment.locale('ru')

const app = (
    <ReactQuery>
        <BrowserRouter>
            {/* <React.StrictMode> */}
            <App />
            {/* </React.StrictMode> */}
        </BrowserRouter>
    </ReactQuery>
)

ReactDOM.render(app, document.getElementById('root'))
reportWebVitals()
focusCleanner.activate()