import { action, makeAutoObservable } from "mobx";
import { CourseCatalogCourseItem, CourseCatalogFilterResp } from "types/apiTypes";

import { configure } from 'mobx';
configure({ useProxies: 'never' });

class CourseCatalogFilterStore {
    initial: CourseCatalogFilterResp | null = null
    courses: CourseCatalogCourseItem[] = []

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInit: action.bound,
                addCourses: action.bound,
                removeAllCourses: action.bound,
                clearState: action.bound,
            }
        )
    }

    updateInit = (newData: CourseCatalogFilterResp) => {
        this.initial = newData
    }

    addCourses = (courses: CourseCatalogCourseItem[], page: number) => {
        if (page === 1) return this.courses = courses

        const oldIds = this.courses.map(c => c.id)
        const newUniqCourses = courses.filter(c => !oldIds.includes(c.id))
        this.courses = [...this.courses, ...newUniqCourses]
    }

    removeAllCourses = () => {
        this.courses = []
    }

    clearState = () => {
        this.initial = null
        this.courses = []
    }
}

export const courseCatalogFilterStore = new CourseCatalogFilterStore()