import React from 'react'
import NumberFormat from 'react-number-format'
import './PhoneInput.scss'

interface PhoneInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    wrapClasses?: string[]
    error?: string
    onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void
}

const PhoneInput: React.FC<PhoneInputProps> = (props) => {
    const {
        error, name, value,
        onFocus, onBlur, onChange,
    } = props

    const inputClasses = () => {
        const classes = ['uif-tel-input']
        if (error) classes.push('error')
        return classes.join(' ')
    }

    const onChangeInput = (e : React.ChangeEvent<any>) => {
        console.log(e.target.value);
        if (onChange) {
            if(e.target.value !== "+7 (8__)___-__-__") {
                onChange(e);
            }else{
                e.target.value = "+7 (___)___-__-__"
                onChange(e);
            }
        }
    }
    const onValueChange = (e : any) => {
        console.log(e);
    }
    return (
        <div className={inputClasses()}>
            <NumberFormat
                format="+7 (###)###-##-##"
                allowEmptyFormatting mask="_"
                className="uif-tel-input__input"
                name={name}
                value={value?.toString()}
                onFocus={onFocus}
                onBlur={onBlur}
                onChange={onChangeInput}
                onValueChange={onValueChange}
            />
            {
                error &&
                <p className="uif-tel-input__error">
                    { error }
                </p>
            }
        </div>
    )
}

export default PhoneInput