
export const apiBaseUrl = 'https://upward.uif66.ru'
export const paymentBaseUrl = 'https://api.uif66.ru'

export const mainPageUrl = `${apiBaseUrl}/front/start`
export const institutePageUrl = `${apiBaseUrl}/front/institute`
export const coursePageUrl = `${apiBaseUrl}/front/course`
export const settingsUrl = `${apiBaseUrl}/front/settings`
export const footerUrl = `${apiBaseUrl}/front/footer`
export const courseCatalogUrl = `${apiBaseUrl}/front/get-rasp`
export const timetableCatalogUrl = `${apiBaseUrl}/front/schedule-courses`
export const questionUrl = `${apiBaseUrl}/front/lead`
export const teachersUrl = `${apiBaseUrl}/front/coaches`

export const teacherUrl = `${apiBaseUrl}/front/coach`
export const teacherCoursesUrl = `${apiBaseUrl}/front/coach-courses`

export const paymentOrderNumberUrl = `${paymentBaseUrl}/api/payment/getOrderNumber`
export const paymentOrderCreateUrl = `${paymentBaseUrl}/api/payment/createOrderPay`
export const paymentOrderStatusUrl = `${paymentBaseUrl}/api/payment/getOrderStatus`
