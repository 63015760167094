import React from 'react'
import './PaginateEndMessage.scss'

type PaginateEndMessageProps = {
    itemsLength: number
    isInitFilter: boolean
}

const PaginateEndMessage: React.FC<PaginateEndMessageProps> = (props) => {

    const {
        itemsLength, isInitFilter,
    } = props

    let text = 'По заданным параметрам ничего нет'
    if (itemsLength) text = 'Весь список загружен'
    if (!isInitFilter && !itemsLength) text = 'Загружаем список..'

    return (
        <p className="uif-paginate-end-message">
            {text}
        </p>
    )
}

export default PaginateEndMessage