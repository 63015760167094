import ImgFade from '@components/optimization/ImgFade/ImgFade'
import React from 'react'
import './InstituteDocuments.scss'

interface InstituteDocumentsProps { }

const documents = [
    {
        imgPath: '/static/img/institute/education-docs/diplom-prof.jpg',
        descr: 'Сертификат о повышении квалификации',
    },
    {
        imgPath: '/static/img/institute/education-docs/udostover-up-kvalif.jpg',
        descr: 'Удостоверение о повышение квалификации',
    },
    {
        imgPath: '/static/img/institute/education-docs/sert-up-kvalif.jpg',
        descr: 'Диплом о профессиональной переподготовке',
    },
    {
        imgPath: '/static/img/institute/education-docs/sert-upw.jpg',
        descr: 'Свидетельство о повышение квалификации',
    },
]

const InstituteDocuments: React.FC<InstituteDocumentsProps> = () => {

    return (
        <section className="uif-institute-documents">
            <div className="uif-container">
                <h2 className="uif-institute-documents__title">
                    выпускные
                    <span>документы</span>
                </h2>
                <p className="uif-institute-documents__description">
                    В конце каждого курса проводится итоговая аттестация, по результатам которой выдаются следующие выпускные документы:
                </p>
                <div className="uif-institute-documents__list">
                    {
                        documents.map((d, idx) => (
                            <figure className="uif-institute-documents__item" key={idx}>
                                <div className="uif-institute-documents__item-img-wrapper">
                                    <ImgFade src={d.imgPath} alt="document" />
                                </div>
                                <figcaption className="uif-institute-documents__item-text">
                                    {d.descr}
                                </figcaption>
                            </figure>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default InstituteDocuments