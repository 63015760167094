import React from 'react'
import './InputCheckbox.scss'

interface InputCheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
    title: string
    styleMark?: "box" | "check"
    className?: string
}

const InputCheckbox: React.FC<InputCheckboxProps> = (props) => {
    const {
        title,
        className = "",
        styleMark = "box",
        ...inputProps
    } = props

    return (
        <label className={`uif-checkbox ${className}`}>
            <input
                type="checkbox"
                className="uif-checkbox__input"
                {...inputProps}
            />
            {
                styleMark === "box" &&
                <span className="uif-checkbox__box"></span>
            }
            {
                styleMark === "check" &&
                <span className="uif-checkbox__check">
                    <img src="/static/img/ui/check.svg" alt="✔" />
                </span>
            }
            <span className="uif-checkbox__label-text">
                {title}
            </span>
        </label>
    )
}

export default InputCheckbox