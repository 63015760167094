import React from 'react'
import './ContactsTitle.scss'

interface ContactsTitleProps {}

const ContactsTitle: React.FC<ContactsTitleProps> = () => {

    return (
        <section className="uif-contacts-title">
            <div className="uif-container">
                <h1 className="uif-contacts-title__title">Контакты</h1>
            </div>
        </section>
    )
}

export default ContactsTitle