import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './CourseTeachers.scss'
import CourseTeachersItem from './components/CourseTeachersItem/CourseTeachersItem'
import { useRef } from 'react'


type CourseTeachersProps = {}

const CourseTeachers: React.FC<CourseTeachersProps> = observer(() => {

    const data = store.coursePage.initial?.coaches || []
    const sectionRef = useRef<HTMLDivElement>(null)

    // const [bgTopOffset, setBgTopOffset] = useState<string>('50%')

    // const handleChangeImgOffset = (imgOffsetY: number, imgHeight: number) => {
    //     const section = sectionRef.current
    //     if (section) {
    //         const sectionClientPosition = section.getBoundingClientRect().top
    //         const sectionPagePosition = window.scrollY + sectionClientPosition
    //         const imgPagePosition = window.scrollY + imgOffsetY
    //         const bgYOffset = imgPagePosition + (imgHeight / 2) - sectionPagePosition
    //         setBgTopOffset(bgYOffset + 'px')
    //     }
    // }

    if (!data.length) return null

    return (
        <section className="uif-course-teachers" ref={sectionRef}>
            <div className="uif-container uif-course-teachers__container">
                {/* <div
                    className="uif-course-teachers__bg"
                    style={{ top: bgTopOffset }}
                >
                </div> */}
                <div className="uif-course-teachers__content">
                    <p className="uif-course-teachers__title">
                        Преподаватели
                    </p>
                    {/* <Masonry
                        breakpointCols={breakpointColumnsObj}
                        className="uif-course-teachers__items-block"
                        columnClassName="uif-course-teachers__items-block-column"
                    >
                        {
                            data.map((coach, i) => (
                                <CourseTeachersItem
                                    coach={coach}
                                    key={i}
                                    onChangeImgOffset={i === 0 ? handleChangeImgOffset : undefined}
                                />
                            ))
                        }
                    </Masonry> */}
                    <div className="uif-course-teachers__items">
                        {
                            data.map((coach, i) => (
                                <CourseTeachersItem
                                    coach={coach}
                                    key={i}
                                    // onChangeImgOffset={i === 0 ? handleChangeImgOffset : undefined}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
        </section>
    )
})

// const breakpointColumnsObj = {
//     default: 3,
//     575: 2,
//     480: 1
// };

export default CourseTeachers