import { replaceEntersToSpan } from '@utils/parsers'
import React from 'react'
import './Warranty.scss'

type WarrantyProps = {
    text?: string
    withEnters?: boolean
    classNames?: string
}

const defaultText = 'Помогаем выпускникам получить стажировку и работу в ведущих компаниях'

const Warranty: React.FC<WarrantyProps> = (props) => {

    const {
        text = defaultText,
        withEnters,
        classNames,
    } = props

    const warrantyClasses = classNames ? `uif-main-warranty ${classNames}` : "uif-main-warranty"

    return (
        <section className={warrantyClasses}>
            <div className="uif-container">
                <div className="uif-main-warranty__row">
                    <p className="uif-main-warranty__text">
                        {
                            withEnters
                                ? replaceEntersToSpan(text, 'uif-span-devider')
                                : text
                        }
                    </p>
                    <a
                        href="https://t.me/upward_career"
                        className="uif-main-warranty__link"
                        target="blank"
                        rel="noreferrer"
                    >
                        UPWARD CAREER
                    </a>
                    {/* <div className="uif-main-warranty__logo">
                        <img
                            className="logo-default"
                            src="/static/img/main-bf-logo-mono.svg"
                            alt="logo"
                        />
                        <img
                            className="logo-hover"
                            src="/static/img/main-bf-logo-mono-hover.svg"
                            alt="."
                        />
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default Warranty