import React from 'react'
import './MainSpecialists.scss'
import MainSpecialistsCounter from './components/MainSpecialistsCounter/MainSpecialistsCounter'

type SpecialistsProps = {}

const MainSpecialists: React.FC<SpecialistsProps> = () => {

    // hidden block
    // const isMobile = window.innerWidth <= 575

    // const connectBtnClasses = () => {
    //     const classes = ['uif-main-spec__control uif-btn uif-btn-dark']
    //     if (isMobile) classes.push('active')
    //     return classes.join(' ')
    // }

    return (
        <section className="uif-main-spec">
            <div className="uif-container">
                <div className="uif-main-spec__text">
                    <MainSpecialistsCounter />

                    <div className="uif-main-spec__title-block">
                        <p className="uif-main-spec__title">
                            {`специалистов \nуже с нами`}
                        </p>
                        <p className="uif-main-spec__title-low">
                            {`Уральский институт фитнеса вырос, \nтеперь мы UPWARD INSTITUTE OF FITNESS`}
                        </p>
                    </div>
                </div>

                {/* <img
                    className="uif-main-spec__list"
                    src="/static/img/main-spec-lists.jpg"
                    alt="specialits"
                />
                <img
                    className="uif-main-spec__list-sm"
                    src="/static/img/main-spec-lists-sm.jpg"
                    alt="specialits"
                /> */}



                {/* <div className="uif-main-spec__controls">
                    <UifLinkBtn
                        styleType="dark"
                        href='#'
                        className={connectBtnClasses()}
                        onClick={clickWithScroll}
                    >
                        Присоединиться
                    </UifLinkBtn>
                    <UifLinkBtn
                        styleType="grey"
                        href="#"
                        className="uif-main-spec__control uif-btn uif-btn-grey"
                        onClick={clickWithScroll}
                    >
                        Истории успеха
                    </UifLinkBtn>
                </div> */}
            </div>
        </section>
    )
}

export default MainSpecialists