import { action, makeAutoObservable } from "mobx";
import { SettingsResp } from "types/apiTypes";

class SettingsStore {
    initial: SettingsResp | null = null

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInit: action.bound
            }
        )
    }

    updateInit = (newData: SettingsResp) => {
        this.initial = newData
    }
}

export const settingsStore = new SettingsStore()