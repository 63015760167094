import InputFloatLabel from '@components/form/InputFloatLabel/InputFloatLabel'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './PaymentOrderConfirmHead.scss'
import { store } from '@store/index'

interface PaymentOrderConfirmHeadProps {}

const PaymentOrderConfirmHead: React.FC<PaymentOrderConfirmHeadProps> = observer(() => {

    const orderNumber = store.paymentOrder.orderNumber

    return (
        <section className="uif-payment-order-confirm-head">
            <div className="uif-container">
                <div className="uif-payment-order-confirm-head__content-wrapper">
                    <h1 className="uif-payment-order-confirm-head__title">
                        подтвердить заказ
                    </h1>
                    <InputFloatLabel
                        value={orderNumber || ''}
                        wrapClasses={['uif-payment-order-confirm-head__input-order']}
                        disabled
                    />
                </div>
            </div>
        </section>
    )
})

export default PaymentOrderConfirmHead