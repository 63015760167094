import React from 'react'
import { CSSTransition } from 'react-transition-group'
import './FadeModal.scss'

type FadeModalProps = {
    isShow: boolean
    wrapClasses?: string[]
    onClose?: () => void
    closeByMouseDown?: boolean
    showStyle?: 'flip' | 'fade'
}

const FadeModal: React.FC<FadeModalProps> = (props) => {

    const {
        isShow, wrapClasses, onClose,
        children,
        closeByMouseDown = true,
        showStyle = 'flip',
    } = props

    const modalClasses = () => {
        let classes = ['uif-fade-modal']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        if (showStyle) classes.push(showStyle)
        return classes.join(' ')
    }

    const handleClose = () => {
        if (onClose) onClose()
    }

    return (
        <CSSTransition
            in={isShow}
            timeout={{
                enter: 300,
                exit: 300
            }}
            classNames={{
                enterActive: 'show',
                enterDone: 'show',
                exitActive: 'exit',
            }}
            unmountOnExit
        >
            <div
                className={modalClasses()}
                onClick={closeByMouseDown ? undefined : handleClose}
                onMouseDown={closeByMouseDown ? handleClose : undefined}
            >
                <div
                    className="uif-fade-modal__bg"
                ></div>
                <div className="uif-fade-modal__body-wrapper">
                    {
                        children
                    }
                </div>
            </div>
        </CSSTransition>
    )
}

export default FadeModal