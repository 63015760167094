import UifButton from '@components/ui/buttons/UifButton/UifButton'
import { withBreakpoints } from 'breakpoints'
import React from 'react'
import './TimetableFilterBtn.scss'

type TimetableFilterBtnProps = {
    onClick: () => void
}

const TimetableFilterBtn: React.FC<TimetableFilterBtnProps> = (props) => {

    const {
        onClick
    } = props

    return (
        <section className="uif-timetable-control">
            <div className="uif-container">
                <UifButton
                    styleType="outline"
                    className="uif-timetable-control__filter-btn"
                    onClick={onClick}
                >
                    Фильтры
                </UifButton>
            </div>
        </section>
    )
}

export default withBreakpoints<TimetableFilterBtnProps>(TimetableFilterBtn)