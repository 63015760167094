import React from 'react'
import './InputRadio.scss'

interface InputRadioProps extends React.InputHTMLAttributes<HTMLInputElement> {
    title: string
}

const InputRadio: React.FC<InputRadioProps> = (props) => {
    const {
        title, ...inputProps
    } = props

    return (
        <label className="uif-radio">
            <input
                type="radio"
                className="uif-radio__input"
                {...inputProps}
            />
            <span className="uif-radio__box"></span>
            <span className="uif-radio__label-text">
                {title}
            </span>
        </label>
    )
}

export default InputRadio