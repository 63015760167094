import UifButton from '@components/ui/buttons/UifButton/UifButton'
import { store } from '@store/index'
import { updateSearchParam } from '@utils/parsers'
import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import './PaymentOrderResultError.scss'

interface PaymentOrderResultErrorProps extends RouteComponentProps {
    message: string
}

const PaymentOrderResultError: React.FC<PaymentOrderResultErrorProps> = (props) => {

    const {
        message, history,
    } = props

    const handleCheckAgain = () => {
        store.paymentOrder.clearStore()
        const search = history.location.search
        history.replace(updateSearchParam(search, 'orderId', ''))
    }

    return (
        <section className="uif-payment-order-result-error">
            <div className="uif-container">
                <div className="uif-payment-order-result-error__content-wrapper">
                    <h2 className="uif-payment-order-result-error__title">
                        Оплата не удалась
                    </h2>
                    <p className="uif-payment-order-result-error__text">
                        {message}
                    </p>
                    <div className="uif-payment-order-result-error__controls">
                        <UifButton
                            styleType="dark"
                            className="uif-payment-order-result-error__retry-btn"
                            onClick={handleCheckAgain}
                        >
                            Оплатить еще раз
                        </UifButton>
                        <button
                            className="uif-payment-order-result-error__check-btn"
                            onClick={handleCheckAgain}
                        >
                            <img
                                src="/static/img/ui/arrow.svg"
                                alt=">"
                            />
                            <span>
                                Проверить номер заказа
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default withRouter(PaymentOrderResultError)