import React, { useRef } from 'react'
import { Link } from 'react-router-dom'
import { clickWithScroll } from '@utils/styleUtils'
import { apiBaseUrl } from '@api/urls'
import { CoursePageInitialResp } from 'types/apiTypes'
import { useEffect } from 'react'
import { replaceEntersToSpan } from '@utils/parsers'
import ImgFade from '@components/optimization/ImgFade/ImgFade'

type CourseTeachersItemProps = {
    coach: CoursePageInitialResp['coaches'][number]
    onChangeImgOffset?: (offsetY: number, imgHeight: number) => void
}

const CourseTeachersItem: React.FC<CourseTeachersItemProps> = (props) => {
    const {
        coach, onChangeImgOffset,
    } = props

    const firstImageRef = useRef<HTMLImageElement>(null)

    const changeOffset = (img: HTMLImageElement) => {
        if (!onChangeImgOffset) return null
        const clientY = img.getBoundingClientRect().top
        onChangeImgOffset(clientY, img.clientHeight)
    }

    useEffect(() => {
        const img = firstImageRef.current
        if (onChangeImgOffset && img) {
            changeOffset(img)
            const handler = changeOffset.bind(this, img)
            window.addEventListener('load', handler)
            window.addEventListener('resize', handler)
            img.addEventListener('load', handler)
            return () => {
                window.removeEventListener('load', handler)
                window.removeEventListener('resize', handler)
                img.removeEventListener('load', handler)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div className="uif-course-teachers__item">
            <Link
                to={`/teacher/${coach.id}`}
                className="uif-course-teachers__item-img"
                onClick={clickWithScroll}
            >
                <ImgFade
                    src={`${apiBaseUrl}${coach.avatar}`}
                    alt="Фото"
                    ref={firstImageRef}
                />
            </Link>
            <Link
                to={`/teacher/${coach.id}`}
                className="uif-course-teachers__item-name"
                onClick={clickWithScroll}
            >
                {`${coach.first_name} ${coach.last_name}`}
            </Link>
            <p className="uif-course-teachers__item-description">
                {replaceEntersToSpan(coach.quality, 'uif-span-devider')}
            </p>
        </div>
    )
}

export default CourseTeachersItem