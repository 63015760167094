import React from 'react'

interface FetchPlaceholderProps {
    styles?: React.CSSProperties | undefined
}

const defaultStyles = {
    width: '100%',
    height: '100vh',
} as React.CSSProperties

const FetchPlaceholder: React.FC<FetchPlaceholderProps> = (props) => {

    const styles = props.styles || defaultStyles

    return (
        <div style={styles}>
        </div>
    )
}

export default FetchPlaceholder