import React, { useEffect, useRef, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { InputSelectOption } from 'types/formTypes'
import './InputSelect.scss'

type InputSelectProps = {
    placeholder: string
    value: string | number
    options: InputSelectOption[]
    wrapClasses?: string[]
    classNames?: string[]
    multiple?: boolean
    maxHeightList?: number
    listAlign?: 'center' | 'right'
    onChange: React.ChangeEventHandler<HTMLInputElement>
}

const InputSelect: React.FC<InputSelectProps> = (props) => {

    const {
        placeholder, wrapClasses, multiple,
        options, value, maxHeightList,
        listAlign, classNames,
        onChange,
    } = props

    const listRef = useRef<HTMLDivElement>(null)
    const topRef = useRef<HTMLDivElement>(null)

    const [opened, setOpened] = useState<boolean>(false)
    const [listStyles, setListStyles] = useState<React.CSSProperties>({})

    const selectedOption = options.find(c => c.value === value)

    const selectClasses = () => {
        let classes = ['uif-select']
        if (classNames) classes = [...classes, ...classNames]
        if (opened) classes.push('opened')
        if (listAlign) classes.push(`list-align-${listAlign}`)
        return classes.join(' ')
    }

    const topText = () => {
        if (selectedOption?.value === '') return placeholder
        if (selectedOption?.text) return selectedOption?.text
        return placeholder
    }

    const wrapperClasses = () => {
        let classes = ['uif-select-wrapper']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!multiple) {
            setOpened(false)
            onChange(e)
        }
    }

    useEffect(() => {
        const style = {...listStyles}
        const topWidth = topRef.current?.clientWidth || 0
        const rightOffset = listRef.current?.getBoundingClientRect().right || 0
        const leftOffset = listRef.current?.getBoundingClientRect().left || 0

        if (rightOffset > window.innerWidth) {
            const offset = Math.abs(rightOffset - window.innerWidth)
            style.left = listAlign === 'center'
                ? topWidth / 2 - offset - 5
                : offset - 5
        }
        if (leftOffset < 0) {
            const offset = Math.abs(leftOffset)
            style.left = listAlign === 'center'
                ? topWidth / 2 + offset + 5
                : offset + 5
        }
        setListStyles(style)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listRef.current, topRef.current])

    return (
        <div className={wrapperClasses()}>
            <div
                className={selectClasses()}
                tabIndex={0}
                onFocus={() => setOpened(true)}
                onBlur={() => setOpened(false)}
            >
                <div
                    ref={topRef}
                    className="uif-select__top"
                    onClick={() => setOpened(!opened)}
                    tabIndex={-100}
                    onFocus={e => e.stopPropagation()}
                >
                    <p className="uif-select__top-text">
                        {topText()}
                    </p>
                    <img
                        src="/static/img/arrow-select.svg"
                        alt="^"
                        className="uif-select__top-arrow"
                    />
                </div>
                <div
                    ref={listRef}
                    className="uif-select__list-wrapper"
                    style={listStyles}
                >
                    <Scrollbars
                        style={{ width: '100%', overflowX: 'hidden' }}
                        className="uif-select__list"
                        autoHeightMax={maxHeightList || 288}
                        autoHeight
                    >
                        {
                            options.map(option => (
                                <label
                                    className="uif-select__option"
                                    key={option.value}
                                >
                                    <input
                                        type="checkbox"
                                        className="uif-select__option-input"
                                        value={option.value}
                                        checked={value === option.value}
                                        onChange={handleChange}
                                    />
                                    <p className="uif-select__option-text">
                                        {option.text}
                                    </p>
                                </label>
                            ))
                        }
                    </Scrollbars>
                </div>
            </div>
        </div>
    )
}

export default InputSelect