import React from 'react'
import { useState } from 'react'

interface VkIconProps {
    className?: string
    fill?: string
    isHovered?: boolean
}

const VkIcon: React.FC<VkIconProps> = (props) => {

    const {
        className, fill, isHovered,
    } = props

    const [hoveredIcon, setHovered] = useState(false)

    const hovered = isHovered || hoveredIcon

    const fillStyle = hovered
        ? "url(#paint0_linear)"
        : fill ? fill : "#000"

    return (
        <svg
            viewBox="0 0 40 40"
            fill={fillStyle}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <path d="M35.7148 27.2854C35.6288 27.1414 35.0967 25.9853 32.5365 23.6091C29.8563 21.1209 30.2164 21.525 33.4446 17.2226C35.4108 14.6024 36.1968 13.0023 35.9508 12.3183C35.7168 11.6662 34.2707 11.8382 34.2707 11.8382L29.4623 11.8662C29.4623 11.8662 29.1063 11.8182 28.8403 11.9762C28.5823 12.1323 28.4162 12.4923 28.4162 12.4923C28.4162 12.4923 27.6542 14.5204 26.6381 16.2446C24.496 19.8828 23.6379 20.0748 23.2879 19.8488C22.4738 19.3228 22.6778 17.7327 22.6778 16.6046C22.6778 13.0783 23.2119 11.6082 21.6357 11.2282C21.1117 11.1022 20.7277 11.0182 19.3896 11.0042C17.6734 10.9862 16.2193 11.0102 15.3973 11.4122C14.8492 11.6802 14.4272 12.2783 14.6852 12.3123C15.0033 12.3543 15.7233 12.5063 16.1053 13.0263C16.5994 13.6964 16.5814 15.2045 16.5814 15.2045C16.5814 15.2045 16.8654 19.3548 15.9193 19.8708C15.2693 20.2249 14.3792 19.5028 12.4691 16.2006C11.491 14.5104 10.7509 12.6403 10.7509 12.6403C10.7509 12.6403 10.6089 12.2923 10.3549 12.1063C10.0469 11.8802 9.61486 11.8082 9.61486 11.8082L5.04252 11.8362C5.04252 11.8362 4.35647 11.8562 4.10445 12.1543C3.88044 12.4203 4.08645 12.9683 4.08645 12.9683C4.08645 12.9683 7.66672 21.3429 11.719 25.5653C15.4373 29.4355 19.6576 29.1815 19.6576 29.1815H21.5697C21.5697 29.1815 22.1478 29.1175 22.4418 28.7995C22.7138 28.5075 22.7038 27.9594 22.7038 27.9594C22.7038 27.9594 22.6658 25.3932 23.8579 25.0152C25.032 24.6432 26.5401 27.4954 28.1382 28.5935C29.3463 29.4235 30.2644 29.2415 30.2644 29.2415L34.5387 29.1815C34.5387 29.1815 36.7749 29.0435 35.7148 27.2854Z" />
            {
                hovered &&
                <defs>
                    <linearGradient id="paint0_linear" x1="34.5969" y1="20.1292" x2="5.84074" y2="20.1599" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9B4DB2" />
                        <stop offset="1" stopColor="#EA4779" />
                    </linearGradient>
                </defs>
            }
        </svg>
    )
}

export default VkIcon