import ImgFade from '@components/optimization/ImgFade/ImgFade'
import React from 'react'
import './HeadLights.scss'

interface HeadLightsProps {
    zIndex?: number
}

const HeadLights: React.FC<HeadLightsProps> = (props) => {

    const {
        zIndex = -1
    } = props

    return (
        <div
            className="uif-head-lights"
            style={{
                zIndex
            }}
        >
            <div className="uif-container uif-head-lights__container">
                <ImgFade
                    src="/static/img/lights-43.svg"
                    alt="."
                    className="uif-head-lights__lights"
                />
            </div>
        </div >
    )
}

export default HeadLights