import { filterTitles } from '@assets/data/courseCatalog'
import CheckboxesGroup from '@components/form/CheckboxesGroup/CheckboxesGroup'
import InputRadioText from '@components/form/InputRadioText/InputRadioText'
import UifButton from '@components/ui/buttons/UifButton/UifButton'
import { withBreakpoints } from 'breakpoints'
import React from 'react'
import { CourseCatalogFilterItem, CourseCatalogFilterResp } from 'types/apiTypes'
import { CatalogCoursesFilterFields, SetFieldValue } from 'types/formTypes'
import PointModal from '../PointModal/PointModal'
import './CourseCatalogFilterModal.scss'

type CourseCatalogFilterModalProps = {
    directionGroups: CourseCatalogFilterResp['filters']['group_directions']
    checkedRadio: string
    chosenCheckboxes: CatalogCoursesFilterFields
    filters: CourseCatalogFilterResp['filters'] | undefined
    setFieldValue: SetFieldValue<CatalogCoursesFilterFields>

    isShow: boolean
    clickX: number
    clickY: number
    onDeny: () => void
    onApply: () => void
}

const CourseCatalogFilterModal: React.FC<CourseCatalogFilterModalProps> = React.memo((props) => {

    const {
        isShow, clickX, clickY,
        directionGroups, checkedRadio, filters,
        chosenCheckboxes,
        setFieldValue, onDeny, onApply,
    } = props

    const getCheckboxData = (filterData: CourseCatalogFilterItem[]) => {
        return filterData.map(f => ({
            name: f.name,
            value: f.id + '',
        }))
    }

    return (
        <PointModal
            isShow={isShow}
            clickX={clickX}
            clickY={clickY}
            wrapClasses={['uif-filter-course-catalog']}
            zIndex={5000}
            closeMinWidth={768}
            onClose={onDeny}
        >
            <div
                className="uif-course-catalog-filter-modal"
                onClick={e => e.stopPropagation()}
            >
                <div className="uif-container">
                    <div className="uif-course-catalog-filter-modal__body">
                        <div className="uif-course-catalog-filter-modal__top-block">
                            <p className="uif-course-catalog-filter-modal__title">Фильтры</p>
                            <button
                                className="uif-course-catalog-filter-modal__close-btn"
                                onClick={onDeny}
                            >
                                <img src="static/img/ui/opened-burger.svg" alt="Меню" className="opened-img" />
                            </button>
                        </div>

                        <div className="uif-course-catalog-filter-modal__filters">
                            <div className="uif-course-catalog-filter-modal__filters-top">
                                <InputRadioText
                                    wrapClasses={['uif-course-catalog-filter-modal__label']}
                                    title="Все"
                                    value={''}
                                    checked={checkedRadio === ''}
                                    onChange={() => setFieldValue('group_direction', '')}
                                />
                                {
                                    directionGroups.map(d => (
                                        <InputRadioText
                                            wrapClasses={['uif-course-catalog-filter-modal__label']}
                                            key={d.id}
                                            title={d.name}
                                            value={d.id + ''}
                                            checked={checkedRadio.includes(d.id + '')}
                                            onChange={() => setFieldValue('group_direction', d.id + '')}
                                        />
                                    ))
                                }
                            </div>

                            {
                                filters &&
                                <div className="uif-course-catalog-filter-modal__filters-bottom">
                                    <CheckboxesGroup
                                        title={filterTitles.cities}
                                        checkboxes={getCheckboxData(filters.cities)}
                                        values={chosenCheckboxes.cities}
                                        wrapClasses={['courses-catalog-modal']}
                                        onChange={val => setFieldValue('cities', val)}
                                        forbiddenAnimate={true}
                                    />
                                    <CheckboxesGroup
                                        title={filterTitles.goals}
                                        checkboxes={getCheckboxData(filters.goals)}
                                        values={chosenCheckboxes.goals}
                                        wrapClasses={['courses-catalog-modal']}
                                        onChange={val => setFieldValue('goals', val)}
                                        forbiddenAnimate={true}
                                    />
                                    <CheckboxesGroup
                                        title={filterTitles.levels}
                                        checkboxes={getCheckboxData(filters.levels)}
                                        values={chosenCheckboxes.levels}
                                        wrapClasses={['courses-catalog-modal']}
                                        onChange={val => setFieldValue('levels', val)}
                                        forbiddenAnimate={true}
                                    />
                                    <CheckboxesGroup
                                        title={filterTitles.form_trainings}
                                        checkboxes={getCheckboxData(filters.form_trainings)}
                                        values={chosenCheckboxes.form_trainings}
                                        wrapClasses={['courses-catalog-modal']}
                                        onChange={val => setFieldValue('form_trainings', val)}
                                        forbiddenAnimate={true}
                                    />
                                    <CheckboxesGroup
                                        title={filterTitles.durations}
                                        checkboxes={getCheckboxData(filters.durations)}
                                        values={chosenCheckboxes.durations}
                                        wrapClasses={['courses-catalog-modal']}
                                        onChange={val => setFieldValue('durations', val)}
                                        forbiddenAnimate={true}
                                    />
                                    {/* <InputFloatText
                                        placeholder="Поиск"
                                        // value={values.finder}
                                        // onChange={(e) => setFieldValue('finder', e.target.value)}
                                        wrapClasses={['courses-catalog-modal']}
                                    /> */}
                                </div>
                            }
                        </div>
                        <div className="uif-course-catalog-filter-modal__controls">
                            <UifButton
                                styleType="dark"
                                className="uif-course-catalog-filter-modal__apply-btn"
                                onClick={onApply}
                            >
                                Применить
                            </UifButton>
                        </div>
                    </div>
                </div>
            </div>
        </PointModal>
    )
})

export default withBreakpoints<CourseCatalogFilterModalProps>(CourseCatalogFilterModal)