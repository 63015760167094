import { timetableCatalogApi } from '@api/requestFunctions';
import TimetableFilterModal from '@components/modals/TimetableFilterModal/TimetableFilterModal';
import { store } from '@store/index';
import { isEqualObjJSON } from '@utils/computed';
import { useFormik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation } from 'react-query';
import { RouteComponentProps, withRouter } from 'react-router';
import { TimetableCatalogFilterResp } from 'types/apiTypes';
import { TimetableFilterFields } from 'types/formTypes';
import TimetableCards from '../TimetableCards/TimetableCards';
import TimetableFilterBtn from '../TimetableFilterBtn/TimetableFilterBtn';
import TimetableFilterLine from '../TimetableFilterLine/TimetableFilterLine';

interface TimetableFilterControllerProps extends RouteComponentProps { }

const allowedQueryParams = ['city', 'direction']
const limitItems = 12

const TimetableFilterController: React.FC<TimetableFilterControllerProps> = observer((props) => {
    const {
        history,
        location
    } = props

    const filterData = store.timetableCatalogFilters.initial?.filters
    const courseCards = store.timetableCatalogFilters.courseCards

    const [maxPage, setMaxPage] = useState<number>(1)
    const [page, setPage] = useState<number>(1)

    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 })
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false)

    const initialCourseCatalogFilter = (): TimetableFilterFields => {
        const formValues: TimetableFilterFields = {
            city: '',
            direction: '',
        }

        const queries = location.search.replace('?', '').split('&')
        const entries = queries.map(q => q.split('=')) as [keyof TimetableFilterFields, string][]

        entries.forEach(([queryField, queryValue]) => {
            if (allowedQueryParams.includes(queryField)) {
                formValues[queryField] = queryValue
            }
        })
        return formValues
    }

    const filterFormik = useFormik<TimetableFilterFields>({
        initialValues: initialCourseCatalogFilter(),
        validate: () => { },
        onSubmit: () => { },
    })

    const [beforeOpenModalValues, setBeforeOpenModalValues] = useState(filterFormik.values)

    const mutationFilter = useMutation<TimetableCatalogFilterResp, Error, string>(
        'mutate-get-timetable-filters',
        timetableCatalogApi.getAllFilters,
        {
            onSuccess(data) {
                console.log('MUTATE resp', data)
                store.timetableCatalogFilters.updateInit(data)

                if (data.pagination.maxPage) {
                    store.timetableCatalogFilters.addCourses(data.schedule, +data.pagination.thisPage)
                } else {
                    store.timetableCatalogFilters.removeAllCourses()
                }
                setMaxPage(+data.pagination.maxPage)
            },
            onError(err) {
                console.log('MUTATE ERROR', err);
            }
        }
    )

    const updateUrlAndFetch = () => {
        const fields = Object.keys(filterFormik.values) as (keyof TimetableFilterFields)[]
        const fillFields = fields.filter(fieldName => filterFormik.values[fieldName])
        const queryAll = fillFields.map(fieldName => {
            const fieldValue = filterFormik.values[fieldName]
            return `${fieldName}=${fieldValue}`
        })
        const queryString = queryAll.filter(query => query).join('&')

        history.replace(`${location.pathname}?${queryString}`)
        setPage(1)
    }

    const handleInfiniteFetch = useCallback(() => {
        // console.log('FETCH', maxPage, page);
        if (maxPage > page) {
            return setPage(page + 1)
        }
    }, [maxPage, page])

    const handleDenyModal = useCallback((e?: React.MouseEvent) => {
        filterFormik.setValues({ ...beforeOpenModalValues })
        toggleFilterModal(e)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFilterModal])

    const handleApplyModal = (e?: React.MouseEvent) => {
        toggleFilterModal(e)
        updateUrlAndFetch()
    }

    const toggleFilterModal = (e?: React.MouseEvent) => {
        if (e) {
            const x = e.clientX
            const y = e.clientY
            setClickPosition({ x, y })
        }
        if (!showFilterModal) {
            setBeforeOpenModalValues(filterFormik.values)
            return setShowFilterModal(true)
        }
        setShowFilterModal(false)
    }

    useEffect(() => {
        if (window.innerWidth >= 768) updateUrlAndFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFormik.values])

    useEffect(() => {
        const query = location.search
            ? `${location.search}&page=${page}&limit=${limitItems}`
            : `?page=${page}&limit=${limitItems}`
        console.log('FETCH search', query)

        const fieldByQuery = initialCourseCatalogFilter()
        if (!isEqualObjJSON(fieldByQuery, filterFormik.values)) {
            filterFormik.setValues(fieldByQuery)
        }
        mutationFilter.mutate(query)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, page])

    return (
        <>
            <TimetableFilterLine
                values={filterFormik.values}
                filters={filterData}
                setFieldValue={filterFormik.setFieldValue}
                breakpoints={{
                    minWidth: 768
                }}
            />

            <TimetableFilterBtn
                onClick={toggleFilterModal}
                breakpoints={{
                    maxWidth: 767
                }}
            />

            <TimetableFilterModal
                values={filterFormik.values}
                filters={filterData}
                setFieldValue={filterFormik.setFieldValue}

                isShow={showFilterModal}
                clickX={clickPosition.x}
                clickY={clickPosition.y}
                onDeny={handleDenyModal}
                onApply={handleApplyModal}
                breakpoints={{
                    maxWidth: 767
                }}
            />

            <TimetableCards
                page={page}
                maxPage={maxPage}
                cityId={filterFormik.values.city}
                isInitializedFilter={!!filterData}
                isFetching={mutationFilter.isLoading}
                courseCards={courseCards}
                onFetch={handleInfiniteFetch}
            />
        </>
    )
})

export default withRouter(TimetableFilterController)