import React from 'react'
import { useState } from 'react'
import './ImgFade.scss'

interface ImgFadeProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    gradientPlaceholder?: boolean
}

const ImgFade = React.forwardRef<HTMLImageElement, ImgFadeProps>((props, ref) => {
    const {
        className,
        gradientPlaceholder,
        alt,
        onLoad,
        onError,
        ...imgProps
    } = props

    const [loaded, setLoaded] = useState(false)

    const showPlaceholder = gradientPlaceholder && !loaded

    const imgClasses = () => {
        const classes = ['']
        if (showPlaceholder) classes.push('uif-fade-img-hide')
        if (!loaded) classes.push('uif-fade-img-loading')
        if (loaded) classes.push('uif-fade-img-loaded')
        if (className) classes.push(className)
        return classes.join(' ')
    }

    const placeholderClasses = () => {
        const classes = ['uif-fade-img-gradient']
        if (className) classes.push(className)
        return classes.join(' ')
    }

    const handleLoadSuccess = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (onLoad) onLoad(e)
        setLoaded(true)
    }

    const handleLoadError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (onError) onError(e)
        setLoaded(true)
    }

    return (
        <>
            <img
                alt={alt}
                ref={ref}
                className={imgClasses()}
                onLoad={handleLoadSuccess}
                onError={handleLoadError}
                {...imgProps}
            />
            {
                showPlaceholder &&
                <span
                    className={placeholderClasses()}
                >
                </span>
            }
        </>
    )
})

export default ImgFade