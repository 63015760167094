import UifLinkBtn from '@components/ui/buttons/UifLinkBtn/UifLinkBtn'
import { store } from '@store/index'
import React from 'react'
import './PaymentOrderResultSuccess.scss'

interface PaymentOrderResultSuccessProps {
    message: string
}

const PaymentOrderResultSuccess: React.FC<PaymentOrderResultSuccessProps> = (props) => {

    const {
        message,
    } = props

    return (
        <section className="uif-payment-order-result-success">
            <div className="uif-container">
                <div className="uif-payment-order-result-success__content-wrapper">
                    <h2 className="uif-payment-order-result-success__title">
                        Заказ оплачен
                    </h2>
                    <p className="uif-payment-order-result-success__text">
                        {message}
                    </p>
                    <UifLinkBtn
                        styleType="dark"
                        href="/paymentOrder"
                        className="uif-payment-order-result-success__new-btn"
                        onClick={store.paymentOrder.clearStore}
                    >
                        Ввести новый заказ?
                    </UifLinkBtn>
                </div>
            </div>
        </section>
    )
}

export default PaymentOrderResultSuccess