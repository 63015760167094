import LinksBlock from '@components/LinksBlock/LinksBlock'
import UifLinkBtn from '@components/ui/buttons/UifLinkBtn/UifLinkBtn'
import { replaceEntersToSpan, сountWord } from '@utils/parsers'
import { clickWithScroll } from '@utils/styleUtils'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { CourseProp } from 'types/propsTypes'
import './MainServiceItem.scss'

type ServiceItemProps = {
    name: string
    description: string
    courses: CourseProp[]
    courseCount: number
    wrapClasses?: string[]
    goalId?: number
}

const MainServiceItem: React.FC<ServiceItemProps> = observer((props) => {
    const {
        name, courses, courseCount,
        goalId, description, wrapClasses
    } = props

    const links = courses.map(c => ({
        path: `course/${c.id}`,
        title: c.name,
    }))

    const linkPath = goalId
        ? `/coursesCatalog?goals[]=${goalId}`
        : '/coursesCatalog'

    const allCoursesText = () => {
        if (!courseCount) return 'Все курсы'
        return `Все ${courseCount + courses.length} ${сountWord(courseCount + courses.length, 'курс')}`
    }

    const itemClasses = () => {
        let classes = ['uif-main-services__item']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    return (
        <div className={itemClasses()}>
            <div className="uif-main-services__item-text">
                <p className="uif-main-services__item-title">
                    {
                        name.split('\n').map((word, i) => (
                            <span key={i} className="uif-main-services__item-title-word">
                                {word}
                            </span>
                        ))
                    }
                </p>
                <p className="uif-main-services__item-description">
                    {replaceEntersToSpan(description, 'uif-main-services__item-description-word')}
                </p>
            </div>
            <LinksBlock
                links={links}
            />
            <UifLinkBtn
                href={linkPath}
                styleType="dark"
                className="uif-main-services__item-btn-all"
                onClick={clickWithScroll}
            >
                {allCoursesText()}
            </UifLinkBtn>
        </div >
    )
})

export default MainServiceItem