import React from 'react'
import { useEffect, useState } from 'react'
import { InputSelectOption } from 'types/formTypes'
import InputSelect from '@components/form/InputSelect/InputSelect'
import { store } from '@store/index'
import { isNumber } from '@utils/computed'
import { observer } from 'mobx-react-lite'
import { monthsCountWord } from '@utils/parsers'

type CoursePriceInfoProps = {}

const CoursePriceInfo: React.FC<CoursePriceInfoProps> = observer((props) => {

    const [cityId, setCityId] = useState<number>(0)

    const course = store.coursePage.initial
    const city = course?.cities.find(c => c.id === cityId)
    const installmentMonths = course?.installment_months

    const coursePrice = city?.price instanceof Array
        ? null
        : city?.price?.price

    const fixPrice = city?.discount instanceof Array
        ? null
        : city?.discount?.fix_price

    const citiesWithPrice = () => course?.cities.filter(c => !(c.price instanceof Array)) || []

    const finalPrice = (): number => {
        let result = 0
        if (isNumber(fixPrice)) {
            result = Number(fixPrice)
        } else if (
            !(city?.discount instanceof Array) &&
            city?.discount?.type_discount === 'percent' &&
            isNumber(coursePrice)
        ) {
            const onePercent = Number(coursePrice) / 100
            const percents = city?.discount?.discount
            const newPrice = Number(coursePrice) - onePercent * Number(percents)
            result = newPrice < 0 ? 0 : newPrice
        } else {
            result = Number(coursePrice)
        }
        return Math.ceil(result)
    }

    const cityOptions: InputSelectOption[] = citiesWithPrice().map(c => ({
        value: c.id,
        text: c.name
    }))

    const discount = (withSpace = true) => {
        if (!isNumber(coursePrice) || city?.discount instanceof Array) return null

        const type = city?.discount?.type_discount
        if (type === 'percent') {
            return Number(city?.discount?.discount).toLocaleString('ru') + `${withSpace ? ' %' : '%'}`
        }
        if (type === 'fix_price') {
            return (Number(coursePrice) - Number(city?.discount?.fix_price)).toLocaleString('ru') + `${withSpace ? ' ₽' : '₽'}`
        }
        return null
    }

    const textBlockClasses = () => {
        let classes = ['uif-course-price__text-block']
        if (!discount()) classes.push('discount-none')
        return classes.join(' ')
    }

    const saleCircleClasses = () => {
        let classes = ['uif-course-price__sale-circle']
        if (!discount()) classes.push('discount-none')
        return classes.join(' ')
    }

    useEffect(() => {
        const cityWithPrice = citiesWithPrice()[0]
        if (cityWithPrice?.id) setCityId(cityWithPrice.id)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [course])

    if (!citiesWithPrice().length) return null

    return (
        <div className={textBlockClasses()}>
            {
                !fixPrice &&
                <div className={saleCircleClasses()}>
                    {
                        !(city?.discount instanceof Array) &&
                        !(city?.price instanceof Array) &&
                        <p className="uif-course-price__sale-circle-value">
                            -{discount(false)}
                        </p>
                    }
                </div>
            }
            <div className="uif-course-price__select">
                <InputSelect
                    placeholder="Город"
                    value={cityId}
                    wrapClasses={['uif-course-price__city-select']}
                    options={cityOptions}
                    onChange={e => setCityId(+e.target.value)}
                />
            </div>
            <div className="uif-course-price__total-block">
                <p className="uif-course-price__title">Стоимость обучения</p>
                <p className="uif-course-price__total-price">
                    {
                        isNumber(finalPrice()) &&
                        Number(finalPrice()).toLocaleString('ru') + ' ₽'
                    }
                </p>
                {
                    !(city?.discount instanceof Array) &&
                    !(city?.price instanceof Array) &&
                    <div className='uif-course-price__sale-block'>
                        {
                            isNumber(coursePrice) &&
                            <p className="uif-course-price__price-without-sale">
                                {
                                    Number(coursePrice).toLocaleString('ru')
                                } ₽ без скидки
                            </p>
                        }
                        <p className="uif-course-price__price-sale">
                            -
                            {
                                discount()
                            } скидка
                        </p>
                    </div>
                }
            </div>
            {
                isNumber(finalPrice()) && Boolean(installmentMonths) && installmentMonths &&
                <div className="uif-course-price__per-block">
                    <p className="uif-course-price__price-title">
                        {
                            Math.ceil(finalPrice() / installmentMonths)
                        } ₽/мес*
                    </p>
                    <p className="uif-course-price__price-description">
                        На {
                        `${installmentMonths} ${monthsCountWord(installmentMonths)}`
                        } без переплат
                    </p>
                    <p className="uif-course-price__price-condition">* Условия уточняйте у менеджера</p>
                </div>
            }
            <div className="uif-course-price__economy-block">
                <p className="uif-course-price__price-title" style={{marginRight: 20}}>Беспроцентная рассрочка на обучение</p>
                <p className="uif-course-price__price-description">
                    Рассрочка предоставляется ООО"ВсегдаДа" (ОГРН 5177746179705) и АО «Тинькофф Банк» (ОГРН 1027739642281)
                    <br/>
                    без первоначального взноса сроком на 6 месяцев, переплата составляет 0%, предоставление рассрочки устанавливается в индивидуальном порядке. Компания оставляет за собой право без обьяснения причин отказать в предоставлении рассрочки или предложить иные условия рассрочки.
                    <br/>
                    Возврат 13% от стоимости обучения.
                </p>
            </div>
        </div>
    )
})

export default CoursePriceInfo