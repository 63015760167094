import React, { useState } from 'react'
import './OnlineSlider.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import 'swiper/components/effect-flip/effect-flip.scss';
import { store } from '@store/index';
import UifButton from '@components/ui/buttons/UifButton/UifButton';
import LinksBlock from '@components/LinksBlock/LinksBlock'
import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';


type OnlineSliderProps = {
    shouldAnimate?: boolean
}

const OnlineSlider: React.FC<OnlineSliderProps> = observer((props) => {

    const [swiper, setSwiper] = useState<SwiperCore | undefined>()
    const [activeIndex, setActiveIndex] = useState<number>(0)

    const trainingStreams = store.mainPage.initial?.form_training.online || []

    const links = (stream: typeof trainingStreams[number]) => {
        return stream.courses.map(c => ({
            path: `/course/${c.id}`,
            title: c.name
        }))
    }

    const slideTo = (idx: number) => {
        if (swiper) {
            swiper.slideTo(idx)
            setActiveIndex(idx)
        }
    }

    const btnClasses = (btnIndex: number) => {
        let classes = ['online-slider__control-btn']
        if (activeIndex === btnIndex) classes.push('darken')
        return classes.join(' ')
    }

    useEffect(() => {
        if (trainingStreams.length) {
            // Slide to courses
            slideTo(1)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trainingStreams])

    return (
        <div className="online-slider">
            <div className="online-slider__controls">
                {
                    trainingStreams.map((stream, i) => (
                        <UifButton
                            styleType="outline"
                            key={stream.type_id}
                            className={btnClasses(i)}
                            onClick={() => slideTo(i)}
                        >
                            { stream.type_name}
                        </UifButton>
                    ))
                }
            </div>
            <Swiper
                slidesPerView={1}
                spaceBetween={40}
                onSlideChange={(s) => setActiveIndex(s.activeIndex)}
                onSwiper={setSwiper}
                autoHeight
            >
                {
                    trainingStreams.map(stream => (
                        <SwiperSlide key={stream.type_id}>
                            <LinksBlock
                                links={links(stream)}
                                placeholder="Нет курсов"
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </div>
    )
})

export default OnlineSlider