import { teacherPageApi } from '@api/requestFunctions'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { TeacherCoursesResp } from 'types/apiTypes'
import './TeacherCourses.scss'
import LinksBlockPaginate from '@components/LinksBlockPaginate/LinksBlockPaginate'

interface TeacherCoursesProps { }

const coursesLimit = 12

const TeacherCourses: React.FC<TeacherCoursesProps> = observer(() => {

    const courses = store.teacherPage.courses
    const isInitializedFilter = store.teacherPage.isInitFilter

    const params = useParams<{ id: string }>()

    const [maxPage, setMaxPage] = useState<number>(1)
    const [page, setPage] = useState<number>(1)

    const mutationFilter = useMutation<TeacherCoursesResp, Error, number>(
        'mutate-get-teacher-courses',
        async (pageCount: number) => teacherPageApi.courses(params.id, pageCount, coursesLimit),
        {
            onSuccess(data) {
                console.log('MUTATE resp', data)
                if (!isInitializedFilter) store.teacherPage.setInitializedFilter(true)
                if (data.pagination.maxPage) {
                    store.teacherPage.addCourses(data.courses, +data.pagination.thisPage)
                } else {
                    store.teacherPage.removeCourses()
                }
                setMaxPage(+data.pagination.maxPage)
            },
            onError(err) {
                console.log('MUTATE ERROR', err);
            }
        }
    )

    const handleInfiniteFetch = () => {
        if (maxPage > page) return setPage(page + 1)
    }

    const courseLinks = () => {
        return courses.map(c => {
            return {
                path: `/course/${c.id}`,
                title: c.name
            }
        })
    }

    useEffect(() => {
        mutationFilter.mutate(page)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])

    if (!courses.length) return null

    return (
        <div className="uif-teacher-courses">
            <div className="uif-container">
                <h2 className="uif-teacher-courses__title">курсы</h2>
                {/*<p className="uif-teacher-courses__description">
                    Преподаватель по программе ДПО «Инструктор тренажёрного зала, персональный тренер», «Фитнес-реабилитация»
                </p>*/}
                <LinksBlockPaginate
                    className="uif-teacher-courses__links"
                    page={page}
                    maxPage={maxPage}
                    links={courseLinks()}
                    isFetching={mutationFilter.isLoading}
                    isInitializedFilter={isInitializedFilter}
                    scrollThreshold="1850px"
                    onFetch={handleInfiniteFetch}
                />
            </div>
        </div>
    )
})

export default TeacherCourses