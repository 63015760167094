import React from 'react'
import './PaymentOrderPlaceholder.scss'

interface PaymentOrderPlaceholderProps {}

export const PaymentOrderPlaceholder: React.FC<PaymentOrderPlaceholderProps> = () => {

    return (
        <div className="uif-payment-order-placeholder"></div>
    )
}