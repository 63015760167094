import { lockBody, unlockBody } from '@utils/styleUtils'
import React, { useEffect } from 'react'
import FadeModal from '../FadeModal/FadeModal'
import './ImageModal.scss'

type ImageModalProps = {
    isShow: boolean
    imgSrc: string
    onClose?: () => void
}

const ImageModal: React.FC<ImageModalProps> = React.memo((props) => {

    const {
        isShow, imgSrc,
        onClose,
    } = props

    useEffect(() => {
        if (isShow) return lockBody()
        unlockBody()
    }, [isShow])

    return (
        <FadeModal
            showStyle="fade"
            isShow={isShow}
            onClose={onClose}
        >
            <div
                className="uif-image-modal-body"
                onClick={e => e.stopPropagation()}
                onMouseDown={e => e.stopPropagation()}
            >
                <div className="uif-image-modal-body__container">
                    <div className="uif-image-modal-body__img-wrapper">
                        <img
                            src={imgSrc}
                            alt="modal-pic"
                        />
                    </div>
                    <button
                        className="uif-image-modal-body__close-btn"
                        onClick={onClose}
                    >
                        <img
                            src="/static/img/ui/white-plus.svg"
                            alt="CLOSE"
                        />
                    </button>
                </div>
            </div>
        </FadeModal>
    )
})

export default ImageModal