import { store } from '@store/index'
import { replaceEntersToSpan } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './CoursePerspectives.scss'

type CoursePerspectivesProps = {}

const CoursePerspectives: React.FC<CoursePerspectivesProps> = observer(() => {

    const perspectives = store.coursePage.initial?.factors || []

    if (!perspectives?.length) return null

    return (
        <section className="uif-course-perspectives">
            <div className="uif-container uif-course-perspectives__container">

                <img src="/static/img/parallax-lights.png" alt="lights" className="uif-course-perspectives__lights" />

                <div className="uif-course-perspectives__title-block">
                    <p className="uif-course-perspectives__title">
                        Какие перспективы
                    </p>
                    <img
                        src="/static/img/infinite.svg"
                        alt="icon"
                        className="uif-course-perspectives__title-icon"
                    />
                </div>
                <div className="uif-course-perspectives__benefits">
                    {
                        perspectives.map((item, i) => (
                            <div className="uif-course-perspectives__benefit-item" key={i}>
                                <p className="uif-course-perspectives__benefit-count">{i + 1}</p>
                                <p className="uif-course-perspectives__benefit-description">
                                    {replaceEntersToSpan(item.name, 'uif-span-devider')}
                                </p>
                            </div>
                        ))
                    }
                </div>

            </div>
        </section>
    )
})

export default CoursePerspectives