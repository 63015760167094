import React, { useEffect, useState } from 'react'
import MainServices from './components/MainServices/MainServices'
import MainOffline from './components/MainOffline/MainOffline'
import MainOnline from './components/MainOnline/MainOnline'
import MainSpecialists from './components/MainSpecialists/MainSpecialists'
import Warranty from '@components/commonBlocks/Warranty/Warranty'
import MainCenter from './components/MainCenter/MainCenter'
import QuestionFormBlock from '@components/commonBlocks/QuestionFormBlock/QuestionFormBlock'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'
import { useQuery } from 'react-query'
import { mainPageApi } from '@api/requestFunctions'
import { MainPageInitialResp } from 'types/apiTypes'
import { store } from '@store/index'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import { observer } from 'mobx-react-lite'
import {MainVideo} from "@pages/MainPage/components/MainVideo/MainVideo";

interface MainPageProps { }

const MainPage: React.FC<MainPageProps> = observer(() => {

    const isLoadedData = !!store.mainPage.initial
    const [show, setShow] = useState<boolean>(false)

    useEffect(() => {
        const str = `
        <!-- Marquiz script start -->
<script>
(function(w, d, s, o){
  var j = d.createElement(s); j.async = true; j.src = '//script.marquiz.ru/v2.js';j.onload = function() {
    if (document.readyState !== 'loading') Marquiz.init(o);
    else document.addEventListener("DOMContentLoaded", function() {
      Marquiz.init(o);
    });
  };
  d.head.insertBefore(j, d.head.firstElementChild);
})(window, document, 'script', {
    host: '//quiz.marquiz.ru',
    region: 'eu',
    id: '64ca2fb7286519002527cae4',
    autoOpen: 3,
    autoOpenFreq: 'always',
    openOnExit: false,
    disableOnMobile: false
  }
);
</script>
<!-- Marquiz script end -->
        `;
        const head = document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.text = str.replace(/<[^>]*>?/gm, '');
        head.appendChild(script);

        return () => {
            head.removeChild(script);
        }
    }, [])

    useQuery<MainPageInitialResp, Error>(
        'main-page-init',
        mainPageApi.getAllInfo,
        {
            refetchOnWindowFocus: false,
            retry: 1,
            onSuccess: (data) => {
                console.log('main page init', data)
                store.mainPage.updateInit(data)
            },
            onError: (err) => console.log('main page ERROR init', err),
        }
    )

    useEffect(() => {
        if (isLoadedData) store.mainPage.statusAnimate(true)
        return () => {
            store.mainPage.statusAnimate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoadedData])

    useNavbarSettings(store, { defaultShowLogo: false, rowClasses: ['main-page__with-video'] })

    return (
        <>
            <MainVideo/>
            <MainCenter />
            <MainServices />
            <MainOffline />
            <MainOnline />

            <MainSpecialists />
            <Warranty />
            <QuestionFormBlock
                titlePage="Главная страница"
                wrapClasses={'main-page'}
                onSuccess={() => setShow(true)}
            />
            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={show}
                onClose={() => setShow(false)}
            />
        </>
    )
})

export default MainPage