import PaginateEndMessage from '@components/paginate/PaginateEndMessage/PaginateEndMessage'
import './TimetableCards.scss'
import PaginateLoader from '@components/paginate/PaginateLoader/PaginateLoader'
import PaginateMorePlaceholder from '@components/paginate/PaginateMorePlaceholder/PaginateMorePlaceholder'
import TimetableCourse from '../TimetableCourse/TimetableCourse'
import TimetableDay from '../TimetableDay/TimetableDay'
import { dbTimeToMoment } from '@utils/parsers'
import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { TimetableCatalogItem } from 'types/apiTypes'


type TimetableCardsProps = {
    page: number
    maxPage: number
    isInitializedFilter: boolean
    isFetching: boolean
    courseCards: TimetableCatalogItem[]
    cityId: string
    onFetch: () => void
}

const TimetableCards: React.FC<TimetableCardsProps> = (props) => {

    const {
        courseCards, page, maxPage,
        isInitializedFilter, isFetching, cityId,
        onFetch,
    } = props

    const hasMore = maxPage > page

    const sortedCoursesByDates = () => {
        const dates = {} as { [key: string]: TimetableCatalogItem[] }

        courseCards.forEach(item => {
            const parsedDate = dbTimeToMoment(item.date_from || '')?.format('DD MMMM, dddd')
            if (!parsedDate) return null

            if (!dates[parsedDate]) return dates[parsedDate] = [item]
            dates[parsedDate].push(item)
        })
        return Object.entries(dates)
    }

    const coursesByDates = sortedCoursesByDates()

    const cardsClasses = () => {
        const classes = ['uif-timetable-cards']
        if (isFetching) classes.push('loading')
        return classes.join(' ')
    }

    useEffect(() => {
        const isAvailableScroll = document.body.clientHeight > window.innerHeight
        if (!isAvailableScroll && hasMore) onFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseCards, page, maxPage])

    return (
        <InfiniteScroll
            dataLength={courseCards.length}
            next={onFetch}
            hasMore={hasMore}
            scrollThreshold="2400px"
            loader={<PaginateLoader text="Загрузка курсов..." />}
            endMessage={
                <PaginateEndMessage
                    isInitFilter={isInitializedFilter}
                    itemsLength={courseCards.length}
                />
            }
            style={{ overflow: 'hidden' }}
        >
            <div className={cardsClasses()}>
                {
                    coursesByDates.map(([dayTitle, items]) => (
                        <TimetableDay
                            date={dayTitle}
                            key={dayTitle}
                        >
                            {
                                items.map(c => (
                                    <TimetableCourse
                                        key={c.id}
                                        time={c.date_from}
                                        courseId={c.course?.id}
                                        cityId={cityId}
                                        name={c.course?.name || ''}
                                        tags={[
                                            `${c.city || ''}`,
                                            `${c.direction || ''}`,
                                            `${c.course?.duration || ''}`,
                                        ]}
                                        price={c.price}
                                        coach={c.coach}
                                    />
                                ))
                            }
                        </TimetableDay>
                    ))
                }
                <PaginateMorePlaceholder
                    show={hasMore && isInitializedFilter && !isFetching}
                />
                {/* {
                    !isInitializedFilter &&
                    <div className="uif-timetable-cards-placeholder"></div>
                } */}

                {/* <div className="uif-courses-card__loader">
                    <Spin indicator={<LoadingOutlined spin className="icon" />} />
                </div> */}
            </div>
        </InfiniteScroll>
    )
}

export default TimetableCards