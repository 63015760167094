import { navLinks } from '@assets/data/navLinks'
import { clickWithScroll } from '@utils/styleUtils'
import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'
import './FooterLayout.scss'
import VkIcon from '@components/ui/icons/VkIcon/VkIcon'
import { useQuery } from 'react-query'
import { FooterResp } from 'types/apiTypes'
import { footerApi } from '@api/requestFunctions'
import { observer } from 'mobx-react-lite'
import { store } from '@store/index'
import { telNumberToLink } from '@utils/parsers'
import { getNavLink, navLinksExclude } from '@utils/filters'
import {Pixel} from "@components/pixel/Pixel";
import TgIcon from "@components/ui/icons/TgIcon/TgIcon";
import WhIcon from "@components/ui/icons/WhIcon/WhIcon";

interface FooterLayoutProps { }

const FooterLayout: React.FC<FooterLayoutProps> = observer((props) => {
    const location = useLocation();
    const [viewPixel, setViewPixel] = useState(false);

    const { children } = props

    let tel = ''

    store.settings.initial?.settings.forEach(setting => {
        if (setting?.hasOwnProperty('tel_number')) tel = setting.tel_number + ''
    })

    const filteredNavs = navLinksExclude(navLinks, ['courses-link', 'timetable-link'])
    const coursesLink = getNavLink(navLinks, 'courses-link')
    const timetableLink = getNavLink(navLinks, 'timetable-link')

    const query = useQuery<FooterResp, Error>(
        'get-footer-info',
        footerApi.getInfo,
        {
            retry: 2,
            refetchOnWindowFocus: false,
            onError: err => console.error('Footer request error:', err)
        }
    )
    useEffect(() => {
        const paths = location.pathname.split('/');
        if(paths[1] && paths[1] === 'paymentOrder'){
            setViewPixel(false);
        }else{
            setViewPixel(true);
        }
    }, [location]);

    if (!query.data) return <>{viewPixel && <Pixel/>}{children}</>

    return (
        <>
            {viewPixel && <Pixel/>}
            {children}
            <footer className="uif-footer">
                <div className="uif-container">

                    <div className="uif-footer__top">
                        <div className="uif-footer__logo">
                            <img
                                src="/static/img/logo-white.svg"
                                alt="logo"
                            />
                        </div>
                        <h2 className="uif-footer__top-text">
                            UPWARD<br />
                            INSTITUTE OF FITNESS
                        </h2>
                    </div>

                    <div className="uif-footer__body">

                        <div className="uif-footer__body-links-block">
                            <ul className="uif-footer__body-links-nav">
                                {
                                    coursesLink &&
                                    <li
                                        className="uif-footer__body-links-nav-item"
                                    >
                                        <Link
                                            to={coursesLink.path}
                                            className="uif-footer__body-links-nav-link"
                                            onClick={clickWithScroll}
                                        >
                                            {coursesLink.name}
                                        </Link>
                                    </li>
                                }
                                {
                                    query.data.group_directions &&
                                    query.data.group_directions.map(dir => (
                                        <li
                                            className="uif-footer__body-links-nav-item"
                                            key={dir.id}
                                        >
                                            <Link
                                                to={`/coursesCatalog?group_direction=${dir.id}`}
                                                className="uif-footer__body-links-nav-link"
                                                onClick={clickWithScroll}
                                            >
                                                {dir.name}
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>

                            <ul className="uif-footer__body-links-nav">
                                {
                                    timetableLink &&
                                    <li
                                        className="uif-footer__body-links-nav-item"
                                    >
                                        <Link
                                            to={timetableLink.path}
                                            className="uif-footer__body-links-nav-link"
                                            onClick={clickWithScroll}
                                        >
                                            {timetableLink.name}
                                        </Link>
                                    </li>
                                }
                                {
                                    query.data.cities &&
                                    query.data.cities.map(city => (
                                        <li
                                            className="uif-footer__body-links-nav-item"
                                            key={city.id}
                                        >
                                            <Link
                                                to={`/timeTable?city=${city.id}`}
                                                className="uif-footer__body-links-nav-link"
                                                onClick={clickWithScroll}
                                            >
                                                {city.name}
                                            </Link>
                                        </li>
                                    ))
                                }
                            </ul>

                            <ul className="uif-footer__body-links-nav">
                                {
                                    filteredNavs.map(link => (
                                        <li
                                            className="uif-footer__body-links-nav-item bold"
                                            key={link.id}
                                        >
                                            {
                                                link.tagA
                                                    ? <a
                                                        href={link.path}
                                                        className="uif-footer__body-links-nav-link"
                                                        target={link.newTab ? "_blank" : undefined}
                                                        rel="noreferrer"
                                                    >
                                                        {link.name}
                                                    </a>
                                                    : <Link
                                                        to={link.path}
                                                        className="uif-footer__body-links-nav-link"
                                                        onClick={clickWithScroll}
                                                    >
                                                        {link.name}
                                                    </Link>
                                            }
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>

                        <div className="uif-footer__body-contacts-block">
                            <a
                                href={telNumberToLink(tel)}
                                className="uif-footer__tel"
                            >
                                {tel}
                            </a>

                            <a
                                href="mailto:sales@uif66.ru"
                                className="uif-footer__email"
                            >
                                sales@uif66.ru
                            </a>

                            <p className="uif-footer__work-info">
                                Екатеринбург, ул. Чернышевского, дом 7, БЦ «ДУБРОВИН», оф. 428<br />
                                Работаем ПН - ПТ с 11:00 до 16:00 по Екб(+2 мск)
                            </p>

                            <div className="uif-footer__social-block">
                                <a
                                    href="https://vk.com/upward.university"
                                    className="uif-footer__social-link"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <VkIcon
                                        className="uif-footer__social-link-icon"
                                        fill="#fff"
                                    />
                                </a>
                                <a
                                    href="https://t.me/upward_career"
                                    className="uif-footer__social-link"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <TgIcon
                                        className="uif-footer__social-link-icon"
                                        fill="#fff"
                                    />
                                </a>
                                <a
                                    href="https://wa.me/79993403300"
                                    className="uif-footer__social-link"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <WhIcon
                                        className="uif-footer__social-link-icon"
                                        fill="#fff"
                                    />
                                </a>
                            </div>

                        </div>

                    </div>

                    <div className="uif-footer__rights">
                        <p className="uif-footer__rights-text">
                            © 2018-2021 UPWARD INSTITUTE OF FITNESS. Все права защищены
                        </p>
                        <div className="uif-footer__rights-links-block">
                            <a
                                href="/static/files/politika_UPWARD.pdf"
                                className="uif-footer__rights-link"
                                target="_blank"
                            >
                                Политика конфиденциальности
                            </a>
                            <Link
                                to={"/institute"}
                                className="uif-footer__rights-link"
                                onClick={clickWithScroll}
                            >
                                Сведения об образовательной организации
                            </Link>
                        </div>
                    </div>

                </div>
            </footer>
        </>
    )
})

export default FooterLayout