import React from 'react'
import PaymentOrderResultSuccess from './components/PaymentOrderResultSuccess/PaymentOrderResultSuccess'
import PaymentOrderResultError from './components/PaymentOrderResultError/PaymentOrderResultError'
import { useQuery } from 'react-query'
import { PaymentOrderStatusResp } from 'types/apiTypes'
import { paymentOrderApi } from '@api/requestFunctions'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import { useState } from 'react'

interface PaymentOrderResultProps { }

const PaymentOrderResult: React.FC<PaymentOrderResultProps> = observer(() => {

    const orderId = store.paymentOrder.orderId || ''

    const [status, setStatus] = useState<'success' | 'error' | null>(null)
    const [message, setMessage] = useState<string>('')

    useQuery<PaymentOrderStatusResp, Error>({
        queryKey: 'payment-order-get-status',
        queryFn: async () => paymentOrderApi.getStatus(orderId),
        refetchOnWindowFocus: false,
        retry: 1,
        onSuccess(data) {
            setMessage('')
            if (!data.error) {
                if (data.orderNot) {
                    setStatus('error')
                    setMessage(data.actionMessage || 'Заказ не найден')
                } else if (data.payIsDone) {
                    setStatus('success')
                    setMessage(data.actionMessage || 'Спасибо, чек о покупке отправлен на почту')
                } else {
                    setStatus('error')
                    setMessage(data.actionMessage || 'Ошибка при оплате заказа')
                }
                return
            }
            setStatus('error')
            setMessage(data.actionMessage || 'Возникла ошибка')
        },
        onError(e) {
            console.log('Возникла ошибка', e);
            setStatus('error')
            setMessage('Возникла ошибка')
        },
    })

    if (status === null) return null

    return (
        <>
            {
                status === 'success' &&
                <PaymentOrderResultSuccess message={message} />
            }
            {
                status === 'error' &&
                <PaymentOrderResultError message={message} />
            }
        </>
    )
})

export default PaymentOrderResult