import React, { useEffect, useState } from 'react'
import './CoursesCatalogPage.scss'
import CourseCatalogHeader from './components/CourseCatalogHeader/CourseCatalogHeader'
import QuestionFormBlock from '@components/commonBlocks/QuestionFormBlock/QuestionFormBlock'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'
import { RouteComponentProps } from 'react-router'
import CourseCatalogFilterController from './components/CourseCatalogFilterController/CourseCatalogFilterController'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'

interface CoursesCatalogPageProps extends RouteComponentProps { }

const CoursesCatalogPage: React.FC<CoursesCatalogPageProps> = observer(() => {

    const [show, setShow] = useState<boolean>(false)

    useNavbarSettings(store, { rowClasses: ['courses-catalog'] })

    useEffect(() => {
        return () => {
            store.courseCatalogFilters.clearState();
        }
    }, [])

    return (
        <>
            <CourseCatalogHeader />
            <CourseCatalogFilterController />

            <QuestionFormBlock
                titlePage="Страница каталога курсов"
                wrapClasses={'courses-page'}
                onSuccess={() => setShow(true)}
            />
            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={show}
                onClose={() => setShow(false)}
            />
        </>
    )
})

export default CoursesCatalogPage