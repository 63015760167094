

function getInternetExplorerVersion() {
    let rv = -1;
    if (navigator.appName === 'Microsoft Internet Explorer') {
        let ua = navigator.userAgent;
        //eslint-disable-next-line
        let re = new RegExp("MSIE ([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat(RegExp.$1);
    }
    else if (navigator.appName === 'Netscape') {
        let ua = navigator.userAgent;
        //eslint-disable-next-line
        let re = new RegExp("Trident/.*rv:([0-9]{1,}[\.0-9]{0,})");
        if (re.exec(ua) != null)
            rv = parseFloat(RegExp.$1);
    }
    return rv;
}

export const isIEBrowser = () => getInternetExplorerVersion() !== -1
export const isSafariBrowser = () => (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0)