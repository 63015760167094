import LinksBlock from '@components/LinksBlock/LinksBlock'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './InstituteCourses.scss'

interface InstituteCoursesProps { }

const InstituteCourses: React.FC<InstituteCoursesProps> = observer(() => {

    const courses = store.institutePage.info?.courses || []

    const coursesLinks = courses.map(c => ({
        path: `/course/${c.id}`,
        title: c.name,
    }))

    return (
        <section className="uif-institute-courses">
            <div className="uif-container">
                <div className="uif-institute-courses__text-block">
                    <h1 className="uif-institute-courses__title">
                        <span>UPWARD </span>
                        INSTITUTE OF FITNESS
                    </h1>
                    <h4 className="uif-institute-courses__description">
                    Один из ведущих фитнес-институтов России. Проводим подготовку по самым востребованным профессиям и направлениям в фитнес-индустрии и диетологии
                    </h4>
                </div>

                <LinksBlock
                    size="sm"
                    className="uif-institute-courses__courses"
                    links={coursesLinks}
                />
            </div>
        </section>
    )
})

export default InstituteCourses