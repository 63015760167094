import React from 'react'
import './CourseCatalogHeader.scss'

type CourseCatalogHeaderProps = {}

const CourseCatalogHeader: React.FC<CourseCatalogHeaderProps> = () => {

    return (
        <header className="uif-courses-title">
            <div className="uif-container">
                <h2 className="uif-courses-title__text">
                    курсы
                </h2>
            </div>
        </header>
    )
}

export default CourseCatalogHeader