import React from 'react'
import './PaginateLoader.scss'

type PaginateLoaderProps = {
    text: string
}

const PaginateLoader: React.FC<PaginateLoaderProps> = (props) => {

    const {
        text
    } = props

    return (
        <p className="uif-paginate-loader">
            {text}
        </p>
    )
}

export default PaginateLoader