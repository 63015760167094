import { PaymentOrderCheckFormFields } from 'types/formTypes'

export const validatePaymentOrderCheck = (values: PaymentOrderCheckFormFields) => {
    const errors = {} as ValidateFields
    if (!values.ordId) errors.ordId = "Введите номер заказа"
    if (values.ordId) {
        const isNumber = !((/\D/g).test(values.ordId))
        if (!isNumber) errors.ordId = "Номер должен содержать только цифры"
    }
    return errors
}

export const initialPaymentOrderCheck: PaymentOrderCheckFormFields = {
    ordId: '',
}

export const clearErrorsPaymentOrderCheck = {
    ordId: '',
}

type ValidateFields = {
    ordId?: string
}