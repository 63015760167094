import { filterTitles } from '@assets/data/courseCatalog'
import CheckboxesGroup from '@components/form/CheckboxesGroup/CheckboxesGroup'
import { countArray } from '@utils/dataUtils'
import { withBreakpoints } from 'breakpoints'
import React from 'react'
import { CourseCatalogFilterItem, CourseCatalogFilterResp } from 'types/apiTypes'
import { CatalogCoursesFilterFields, SetFieldValue } from 'types/formTypes'
import './CourseCatalogFilter.scss'

type CourseCatalogFilterProps = {
    directionGroups: CourseCatalogFilterResp['filters']['group_directions']

    values: CatalogCoursesFilterFields
    filters: CourseCatalogFilterResp['filters'] | undefined
    setFieldValue: SetFieldValue<CatalogCoursesFilterFields>
}

const CourseCatalogFilter: React.FC<CourseCatalogFilterProps> = React.memo((props) => {

    const {
        values, filters,
        setFieldValue,
    } = props

    const getCheckboxData = (filterData: CourseCatalogFilterItem[]) => {
        return filterData.map(f => ({
            name: f.name,
            value: f.id + '',
        }))
    }

    return (
        <div className="uif-courses-filter">
            {
                !filters &&
                countArray(4).map((_, idx) => (
                    <div className="uif-courses-filter__group-placeholder" key={idx}>
                        <div className="uif-courses-filter__group-placeholder-title uif-gradient-loader"></div>
                        <ul className="uif-courses-filter__group-placeholder-checkboxes">
                            {
                                countArray(4).map((_, i) => (
                                    <li
                                        key={i}
                                        className="uif-gradient-loader uif-gradient-loader"
                                    ></li>
                                ))
                            }
                        </ul>
                    </div>
                ))
            }
            {
                filters &&
                <div className="uif-courses-filter__left-filters">
                    <CheckboxesGroup
                        title={filterTitles.cities}
                        checkboxes={getCheckboxData(filters.cities)}
                        values={values.cities}
                        wrapClasses={['courses-catalog']}
                        onChange={val => setFieldValue('cities', val)}
                    />
                    <CheckboxesGroup
                        title={filterTitles.goals}
                        checkboxes={getCheckboxData(filters.goals)}
                        values={values.goals}
                        wrapClasses={['courses-catalog']}
                        onChange={val => setFieldValue('goals', val)}
                    />
                    <CheckboxesGroup
                        title={filterTitles.levels}
                        checkboxes={getCheckboxData(filters.levels)}
                        values={values.levels}
                        wrapClasses={['courses-catalog']}
                        onChange={val => setFieldValue('levels', val)}
                    />
                    <CheckboxesGroup
                        title={filterTitles.form_trainings}
                        checkboxes={getCheckboxData(filters.form_trainings)}
                        values={values.form_trainings}
                        wrapClasses={['courses-catalog']}
                        onChange={val => setFieldValue('form_trainings', val)}
                    />
                    <CheckboxesGroup
                        title={filterTitles.durations}
                        checkboxes={getCheckboxData(filters.durations)}
                        values={values.durations}
                        wrapClasses={['courses-catalog']}
                        onChange={val => setFieldValue('durations', val)}
                    />


                    {/* <InputFloatText
                        placeholder="Поиск"
                        // value={values.finder}
                        // onChange={(e) => setFieldValue('finder', e.target.value)}
                        wrapClasses={['small-60']}
                    /> */}
                </div>
            }
        </div>
    )
})

export default withBreakpoints<CourseCatalogFilterProps>(CourseCatalogFilter)