import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import './TeacherBack.scss'

interface TeacherBackProps extends RouteComponentProps { }

const TeacherBack: React.FC<TeacherBackProps> = ({ history }) => {

    return (
        <div className="uif-teacher-back">
            <div className="uif-container">
                {
                    history.length > 1 &&
                    <button
                        className="uif-teacher-back__btn UIF-BTN"
                        onClick={history.goBack}
                    >
                        <img
                            src="/static/img/ui/arrow.svg"
                            alt=">"
                            className="uif-teacher-back__btn-arrow"
                        />
                        <span className="uif-teacher-back__btn-text">
                            Назад
                        </span>
                    </button>
                }
            </div>
        </div>
    )
}

export default withRouter(TeacherBack)