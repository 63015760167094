import { action, makeAutoObservable } from "mobx";
import { TeacherPreview, TeachersPageInitialResp } from "types/apiTypes";


class TeachersPageState {
    teachers: TeacherPreview[] = []

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInit: action.bound,
                clearState: action.bound,
            }
        )
    }

    updateInit = (newData: TeachersPageInitialResp | null) => {
        if (newData) {
            this.teachers = newData
        } else {
            this.teachers = []
        }
    }

    clearState = () => {
        this.teachers = []
    }
}

export const teachersPageState = new TeachersPageState()