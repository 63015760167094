import QuestionFormBlock from '@components/commonBlocks/QuestionFormBlock/QuestionFormBlock'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'
import { store } from '@store/index'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import TimetableFilterController from './components/TimetableFilterController/TimetableFilterController'
import TimetableTitle from './components/TimetableTitle/TimetableTitle'
import './TimetablePage.scss'
import HeadLights from '@components/commonBlocks/HeadLights/HeadLights'

type TimetablePageProps = {}

const TimetablePage: React.FC<TimetablePageProps> = observer(() => {

    const [show, setShow] = useState<boolean>(false)

    useNavbarSettings(store)

    useEffect(() => {
        return () => {
            store.timetableCatalogFilters.clearState()
        }
    }, [])

    return (
        <>
            <HeadLights/>
            <TimetableTitle />

            <TimetableFilterController />

            <QuestionFormBlock
                titlePage="Страница расписания курсов"
                wrapClasses={'timetable-page'}
                onSuccess={() => setShow(true)}
            />
            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={show}
                onClose={() => setShow(false)}
            />
        </>
    )
})

export default TimetablePage