import React from 'react'

type MainOfflineTitleProps = {
}

const MainOfflineTitle: React.FC<MainOfflineTitleProps> = (props) => {

    return (
        <div className="uif-main-offline__title">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 288.07 52.48"
            >
                <path d="M22.35 52.23a24.12 24.12 0 01-9.07-1.65 18.4 18.4 0 01-7-4.93 23 23 0 01-4.47-8.14A36.6 36.6 0 01.25 26.24 36.6 36.6 0 011.83 15 22.89 22.89 0 016.3 6.84a18.18 18.18 0 017-4.93A23.93 23.93 0 0122.35.25a24 24 0 019.08 1.66 18.18 18.18 0 017 4.93A22.72 22.72 0 0142.87 15a36.29 36.29 0 011.59 11.27 36.29 36.29 0 01-1.59 11.27 22.86 22.86 0 01-4.46 8.14 18.4 18.4 0 01-7 4.93 24.14 24.14 0 01-9.06 1.62zm0-9.72a9.62 9.62 0 007.74-3.24Q32.87 36 32.87 30v-7.5q0-6-2.78-9.29A9.62 9.62 0 0022.35 10a9.64 9.64 0 00-7.74 3.24q-2.76 3.24-2.77 9.29V30q0 6 2.77 9.28a9.64 9.64 0 007.74 3.23zM52.67 51.37V1.11H86v9.72H63.61v10.23h19.08v9.72H63.61v20.59zM93.85 51.37V1.11h33.33v9.72h-22.39v10.23h19.08v9.72h-19.08v20.59zM135 51.37V1.11h11v40.54h19.15v9.72zM170.31 51.37v-8.71h6.48V9.83h-6.48V1.11h23.91v8.72h-6.48v32.83h6.48v8.71zM217.76 27.61l-4.82-10.08h-.22v33.84h-10.3V1.11h12l13.75 23.76L233 35h.22V1.11h10.29v50.26h-12zM253.62 51.37V1.11h34.2v9.72h-23.26v10.23h19.94v9.72h-19.94v10.87h23.26v9.72z" />
            </svg>
        </div>

    )
}

export default MainOfflineTitle