import React from 'react'
import './CheckboxesGroup.scss'
import InputCheckbox from '@components/form/InputCheckbox/InputCheckbox'
import { CheckboxGroupData } from 'types/propsTypes'

type CheckboxesGroupProps = {
    title: string
    values: string[]
    checkboxes: CheckboxGroupData[]
    onChange: (val: string[]) => void
    wrapClasses?: string[]
    shouldAnimate?: boolean
    forbiddenAnimate?: boolean
}

const CheckboxesGroup: React.FC<CheckboxesGroupProps> = (props) => {
    const {
        checkboxes, title, wrapClasses,
        values,
        onChange,
    } = props

    const groupClasses = () => {
        let classes = ['uif-input-group']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    const handleChange = (val: string) => {
        if (values.includes(val)) {
            const withoutChecked = values.filter(v => v !== val) || []
            return onChange(withoutChecked)
        }
        const newValues = [...values]
        newValues.push(val)
        onChange(newValues)
    }

    return (
        <div className={groupClasses()}>
            <p className="uif-input-group__title">
                {title}
            </p>
            <ul>
                {
                    checkboxes.map((checkbox, i) => (
                        <li key={i}>
                            <InputCheckbox
                                title={checkbox.name}
                                value={checkbox.value}
                                checked={values.includes(checkbox.value)}
                                onChange={e => handleChange(e.target.value)}
                            />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default CheckboxesGroup