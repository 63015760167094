import React from 'react'
import './InstituteLicense.scss'
import ImageModal from '@components/modals/ImageModal/ImageModal'
import { useState } from 'react'
import ImgFade from '@components/optimization/ImgFade/ImgFade'

interface InstituteLicenseProps { }

const instituteLicenses = [
    {
        imgSrc: '/static/img/institute/licenses/license1.jpg',
        alt: 'license-1',
    },
    {
        imgSrc: '/static/img/institute/licenses/license2.jpg',
        alt: 'license-2',
    },
    {
        imgSrc: '/static/img/institute/licenses/license3.jpg',
        alt: 'license-3',
    },
]

const InstituteLicense: React.FC<InstituteLicenseProps> = () => {

    const [modalSrc, setModalSrc] = useState<string>('')
    const [showModal, setShowModal] = useState<boolean>(false)

    const handleClickImg = (src: string) => {
        setModalSrc(src)
        setShowModal(true)
    }

    return (
        <>
            <section className="uif-institute-license">
                <div className="uif-container">
                    <h2 className="uif-institute-license__title">
                        Лицензия
                    </h2>
                    <p className="uif-institute-license__description">
                        <b>Лицензия №19856 от 22 ноября 2018г.</b> дает право оказывать образовательные услуги по реализации образовательных программ по видам образования, по уровням образования, по профессиям, специальностям, направлениям подготовки (для профессионального образования), по поводам дополнительного образования, указанным в приложении к настоящей лицензии.
                    </p>
                    <div className="uif-institute-license__list">
                        {
                            instituteLicenses.map((l, idx) => (
                                <div
                                    className="uif-institute-license__item"
                                    key={idx}
                                >
                                    <ImgFade
                                        src={l.imgSrc}
                                        alt={l.alt}
                                        onClick={() => handleClickImg(l.imgSrc)}
                                    />
                                </div>
                            ))
                        }
                    </div>
                    <a
                        href="/static/files/aboutChangesRegister.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="uif-institute-license__doc-link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="<"
                            className="uif-institute-license__doc-link-icon"
                        />
                        О внесении изменений в реестр лицензий.pdf
                    </a>
                </div>
            </section>
            <ImageModal
                isShow={showModal}
                imgSrc={modalSrc}
                onClose={() => setShowModal(false)}
            />
        </>

    )
}

export default InstituteLicense