import React from 'react'
import { useState } from 'react'

interface VkIconProps {
    className?: string
    fill?: string
    isHovered?: boolean
}

const TgIcon: React.FC<VkIconProps> = (props) => {

    const {
        className, fill, isHovered,
    } = props

    const [hoveredIcon, setHovered] = useState(false)

    const hovered = isHovered || hoveredIcon

    const fillStyle = hovered
        ? "url(#paint0_linear)"
        : fill ? fill : "#000"

    return (
        <svg
            viewBox="0 0 1000 1000"
            fill={fillStyle}
            style={{width: '27px'}}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <path d="M-0.06 467.71c0,25.89 28.66,30.6 54.44,39.31 21.87,7.38 45.64,14.52 68.36,21.49l103.05 31.71c47.1,15.39 28.59,15.57 70.4,-9.14 12.47,-7.38 22.44,-14.03 34.95,-21.69l68.4 -42.94c43.77,-28.76 92.65,-56.81 137.12,-85.52 11.4,-7.36 22.71,-13.83 33.61,-21.09 11.49,-7.64 22.8,-14.17 34.75,-21.88 22.99,-14.84 45.2,-28.39 68.37,-42.97l68.64 -42.68c10.82,-6.48 82.13,-58.31 82.13,-31.16 0,5.33 -11.28,14.64 -15.16,18.05l-95.7 85.94c-4.18,3.69 -5.55,3.88 -9.77,7.81l-26.32 24.46c-4.22,3.9 -5.61,4.13 -9.78,7.8l-211.95 190.39c-3.09,2.87 -4.66,4.9 -7.75,7.88 -4.33,4.18 -5.41,4.25 -9.72,7.86l-36.03 32.32c-7.65,6 -11.37,8.81 -11.52,21.69l-15.52 220.8c25.01,0 34.58,-10.24 51.25,-24.92 16.87,-14.85 22.04,-23.81 43.87,-42.06 3.47,-2.9 5.68,-5.47 8.88,-8.7 6.32,-6.35 11.25,-11.23 17.58,-17.58l36.62 -33.69 179.69 130.86c8.64,6.49 16.91,12.15 25.96,18.96l24.88 18.09c8.06,5.03 21.33,9.77 33.93,11.06 25.53,2.62 40.68,-21.66 46.85,-41.49l31.31 -146.42c32.33,-145.26 61.8,-294.17 94.06,-439.15 3.46,-15.58 30.12,-135.68 30.12,-149.57 0,-28.67 -5.93,-55.14 -41.5,-58.15 -14.79,-1.26 -53.28,15.21 -67.9,21.02 -21.62,8.6 -42.61,16.09 -65.01,24.83 -22.17,8.65 -42.36,16.65 -64.7,25.15 -80.04,30.45 -179.97,66.93 -258.66,98.76l-323.39 123.88c-22.31,9.01 -42.8,15.89 -65.01,24.83 -30.26,12.16 -113.83,35.35 -113.83,65.85z"/>
            {
                hovered &&
                <defs>
                    <linearGradient id="paint0_linear" x1="34.5969" y1="20.1292" x2="5.84074" y2="20.1599" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9B4DB2" />
                        <stop offset="1" stopColor="#EA4779" />
                    </linearGradient>
                </defs>
            }
        </svg>
    )
}

export default TgIcon