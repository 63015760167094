import { LinkNavbar } from '@assets/data/navLinks'
import { CourseCatalogFilterItem, TimetableCatalogItem } from "types/apiTypes"
import { InputSelectOption } from "types/formTypes"

export const sortByDate = (fullDateFirst: string, fullDateSecond: string): number => {
    /// example: '20210405230000'
    const trimDateFirst = +fullDateFirst.slice(0, 8)
    const trimDateSecond = +fullDateSecond.slice(0, 8)
    const trimTimeFirst = +fullDateFirst.slice(8)
    const trimTimeSecond = +fullDateSecond.slice(8)

    const deltaDate = trimDateFirst - trimDateSecond
    const deltaTime = trimTimeFirst - trimTimeSecond
    if (deltaDate !== 0) return deltaDate
    return deltaTime
}

export const sortTimetableCoursesByDate = (items: TimetableCatalogItem[], mod = 1) => {
    return items.sort((a, b) => sortByDate(a.date_from, b.date_from) * mod)
}

export const timetableOptions = (rowContainer: CourseCatalogFilterItem[] | undefined): InputSelectOption[] => {
    const resetOption = { value: '', text: 'Все' }
    if (!rowContainer) return []
    const options = rowContainer.map(c => ({
        value: c.id + '',
        text: c.name
    }))
    const sorted = options.sort((a, b) => a.text.localeCompare(b.text))
    return [resetOption, ...sorted]
}

export const getNavLink = (links: LinkNavbar[], id: string) => {
    const link = links.find(l => l.id === id)
    return link ? link : null
}

export const navLinksExclude = (links: LinkNavbar[], ids: string[]) => {
    return links.filter(l => !ids.includes(l.id))
}