import { navLinks } from '@assets/data/navLinks'
import { clickWithScroll } from '@utils/styleUtils'
import React from 'react'
import { Link } from 'react-router-dom'
import PointModal from '../PointModal/PointModal'
import './NavbarModal.scss'

type NavbarModalProps = {
    isShow: boolean
    clickX: number
    clickY: number
    onClose: (e?: React.MouseEvent) => void
}

const linkWithoutSpecial = navLinks.filter(l => !l.special)

const NavbarModal: React.FC<NavbarModalProps> = (props) => {
    const {
        isShow, clickX, clickY,
        onClose,
    } = props

    const handleClickLink = () => {
        onClose()
        clickWithScroll()
    }

    return (
        <PointModal
            isShow={isShow}
            clickX={clickX}
            clickY={clickY}
            wrapClasses={['uif-navbar']}
            closeMinWidth={768}
            onClose={onClose}
        >
            <div className="uif-navbar-modal" onClick={e => e.stopPropagation()}>
                <ul className="uif-navbar-modal__links">
                    {
                        linkWithoutSpecial.map((link, i) => (
                            <li className="uif-navbar-modal__link-wrapper" key={i}>
                                {
                                    link.tagA
                                        ? <a
                                            href={link.path}
                                            className="uif-navbar-modal__link"
                                        >
                                            {link.name}
                                        </a>
                                        : <Link
                                            to={link.path}
                                            className="uif-navbar-modal__link"
                                            onClick={handleClickLink}
                                        >
                                            {link.name}
                                        </Link>
                                }
                            </li>
                        ))
                    }
                </ul>
                <div className="uif-navbar-modal__controls">
                    {/* <button
                        className="uif-navbar-modal__control uif-btn uif-btn-dark"
                        onClick={() => onClose()}
                    >
                        Записаться
                        </button> */}
                </div>
            </div>
        </PointModal>
    )
}

export default NavbarModal