import React from 'react'
import './MainCenter.scss'
import { Link } from 'react-router-dom'
import { apiBaseUrl } from '@api/urls'
import { store } from '@store/index'
import { clickWithScroll } from '@utils/styleUtils'
import { observer } from 'mobx-react-lite'
import { countArray } from '@utils/dataUtils'
import HeadLights from '@components/commonBlocks/HeadLights/HeadLights'
import ImgFade from '@components/optimization/ImgFade/ImgFade'
import {Logo} from "@pages/MainPage/components/MainCenter/components/logo/Logo";

type CenterProps = {}

const MainCenter: React.FC<CenterProps> = observer(() => {

    const initial = store.mainPage.initial
    const directions = store.mainPage.initial?.directions_group || []

    return (
        <section className="uif-main-center">
            <HeadLights zIndex={0} />
            <div className="uif-container uif-main-center__container">
                <div className="uif-main-center__company-row">
                    <div className="uif-main-center__logo">
                        <Logo/>
                    </div>
                    <div className="uif-main-center__text">
                        <div className="uif-main-center__name">
                            UPWARD INSTITUTE OF FITNESS
                        </div>
                        <p className="uif-main-center__description">Фитнес и диетология. Обучаем с 2016 года</p>
                        <div className="uif-main-center__controls">
                            {/* <button className="uif-main-center__control uif-btn uif-btn-dark">Записаться</button> */}
                        </div>
                    </div>

                </div>
                {/* <div className="uif-main-center__controls">
                    <button className="uif-main-center__control uif-btn uif-btn-dark">Записаться</button>
                </div> */}
                <div className="uif-main-center__categories">
                    <div className="uif-main-center__categories-bg-1"></div>
                    <div className="uif-main-center__categories-bg-2"></div>

                    {
                        initial
                            ? directions.map(dir => (
                                <Link
                                    to={`/coursesCatalog?group_direction=${dir.id}`}
                                    className="uif-main-center__category-card"
                                    key={dir.id}
                                    onClick={clickWithScroll}
                                >
                                    <div className="uif-main-center__category-card-img">
                                        <ImgFade
                                            src={`${apiBaseUrl}${dir.avatar}`}
                                            alt={`${dir.name}-pic`}
                                        />
                                    </div>
                                    <p className="uif-main-center__category-card-name">
                                        {dir.name}
                                    </p>
                                </Link>
                            ))
                            : countArray(4).map((_, i) => (
                                <div key={i} className="uif-main-center__category-card-placeholder"></div>
                            ))
                    }
                </div>
            </div>
        </section>
    )
})

export default MainCenter