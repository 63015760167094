import React from 'react'
import './PaymentOrderTitle.scss'

interface PaymentOrderTitleProps { }

const PaymentOrderTitle: React.FC<PaymentOrderTitleProps> = () => {

    return (
        <section className="uif-payment-order-title">
            <div className="uif-container">
                <h1 className="uif-payment-order-title__title">Оплата</h1>
                <p className="uif-payment-order-title__text">
                    Для оплаты занятий нужно
                </p>
            </div>
        </section>
    )
}

export default PaymentOrderTitle