import { useEffect } from "react"
import { store } from '../../store/index'

type Options = {
    defaultShowLogo?: boolean
    rowClasses?: string[]
}

const defaultSettings = {
    defaultShowLogo: true,
    rowClasses: [],
}

export const useNavbarSettings = (globalStore: typeof store, options?: Options) => {
    const settings = { ...defaultSettings, ...options }

    useEffect(() => {
        globalStore.navBar.setDefaultShowLogo(settings.defaultShowLogo)
        globalStore.navBar.setRowClasses(settings.rowClasses)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}