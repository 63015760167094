import React, { useState } from 'react'
import TeacherBack from './components/TeacherBack/TeacherBack'
import TeacherInfo from './components/TeacherInfo/TeacherInfo'
import TeacherCourses from './components/TeacherCourses/TeacherCourses'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import { store } from '@store/index'
import QuestionFormBlock from '@components/commonBlocks/QuestionFormBlock/QuestionFormBlock'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'
import { useQuery } from 'react-query'
import { TeacherResp } from 'types/apiTypes'
import { teacherPageApi } from '@api/requestFunctions'
import { RouteComponentProps, useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import FetchPlaceholder from '@components/commonBlocks/FetchPlaceholder/FetchPlaceholder'

interface TeacherPageProps extends RouteComponentProps { }

const TeacherPage: React.FC<TeacherPageProps> = observer(({ history }) => {

    const isInitFilter = store.teacherPage.isInitFilter
    const isLoadedInfo = !!store.teacherPage.info

    const params = useParams<{ id: string }>()
    const [show, setShow] = useState<boolean>(false)

    useQuery<TeacherResp, Error>(
        {
            queryKey: ['teacher-page-info', params.id],
            queryFn: async () => teacherPageApi.teacherInfo(params.id),
            refetchOnWindowFocus: false,
            retry: 1,
            onSuccess: (data) => {
                console.log('teacher page info', data)
                if (data === null) history.push('/teachers')
                store.teacherPage.updateInfo(data)
            },
            onError: (err) => {
                console.log('teacher page ERROR info', err)
            }
        }
    )

    useNavbarSettings(store)

    useEffect(() => {
        return () => {
            store.teacherPage.clearState()
        }
    }, [])

    if (!isLoadedInfo) return <FetchPlaceholder styles={{height: '120vh'}}/>

    return (
        <>
            <TeacherBack />
            <TeacherInfo />
            <TeacherCourses />
            {
                isInitFilter
                    ? <QuestionFormBlock
                        titlePage={`Страница преподавателя`}
                        wrapClasses={'teacher-page'}
                        onSuccess={() => setShow(true)}
                    />
                    : <FetchPlaceholder styles={{height: 400}}/>
            }
            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={show}
                onClose={() => setShow(false)}
            />
        </>
    )
})

export default TeacherPage