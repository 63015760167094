import React from 'react'
import { Link } from 'react-router-dom'
import './UifLinkBtn.scss'

interface UifLinkBtnProps extends React.HTMLAttributes<HTMLAnchorElement> {
    styleType: 'dark' | 'outline' | 'grey'
    href: string
    className?: string
    isTagA?: boolean
    disabled?: boolean
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

const UifLinkBtn = React.memo(React.forwardRef<HTMLAnchorElement, UifLinkBtnProps>((props, ref) => {

    const {
        className, children, styleType,
        href, isTagA, disabled,
        onClick,
        ...otherProps
    } = props

    const btnClasses = () => {
        const classes = ['uif-link-btn']
        if (className) classes.push(className)
        if (styleType === 'dark') classes.push('dark')
        if (styleType === 'outline') classes.push('outline')
        if (styleType === 'grey') classes.push('grey')
        if (disabled) classes.push('disabled')
        return classes.join(' ')
    }

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (disabled) {
            e.preventDefault()
            return null
        }
        if (onClick) onClick(e)
    }

    if (isTagA) return (
        <a
            href={href}
            className={btnClasses()}
            onClick={handleClick}
            {...otherProps}
        >
            <span
                className={`uif-link-btn__content`}
            >
                {children}
            </span>
        </a>
    )

    return (
        <Link
            to={href}
            className={btnClasses()}
            onClick={handleClick}
            ref={ref}
            {...otherProps}
        >
            <span
                className={`uif-link-btn__content`}
            >
                {children}
            </span>
        </Link>
    )
}))

export default UifLinkBtn