import React, { useState } from 'react'
import FeedbackForm from '@components/form/FeedbackForm/FeedbackForm'
import { feedbackValidate } from '@utils/formValidators'
import { dbTimeToMoment, placeCountWord } from '@utils/parsers'
import { useFormik } from 'formik'
import { FeedbackFormValues } from 'types/formTypes'
import { initialFeedbackValues } from '@data/forms'
import { useMutation } from 'react-query'
import { requestApi } from '@api/requestFunctions'
import { store } from '@store/index'
import { isNumber } from '@utils/computed'
import { observer } from 'mobx-react-lite'
import { OptionsFeedback } from 'types/apiTypes'
import { yandexMetricaApi, googleAnalyticsApi } from '../../../../../../otherOrigins/metricsApi'

type CoursePriceFeedbackProps = {
    onSuccess?: () => void
}

const CoursePriceFeedback: React.FC<CoursePriceFeedbackProps> = observer((props) => {
    const {
        onSuccess
    } = props

    const course = store.coursePage.initial

    const feedbackOptions: OptionsFeedback = {
        pageTitle: `Страница курса '${course?.name || ''}', блок цен`,
        nomenclatureСode: course?.code
    }

    const [formError, setFormError] = useState<string>('')

    const onSubmit = (values: FeedbackFormValues) => {
        feedbackMutation.mutate(values)
    }

    const feedbackForm = useFormik<FeedbackFormValues>({
        initialValues: initialFeedbackValues,
        validateOnChange: false,
        validate: feedbackValidate,
        onSubmit,
    })

    const feedbackMutation = useMutation<unknown, Error, FeedbackFormValues>(
        (formValues) => requestApi.feedbackSend(formValues, feedbackOptions),
        {
            mutationKey: 'feedbackMutation',
            onSuccess() {
                if (onSuccess) onSuccess()
                feedbackForm.setValues(initialFeedbackValues)
                yandexMetricaApi.goalLead()
                googleAnalyticsApi.goalLead()
            },
            onError() { setFormError('Возникла ошибка при отправке') },
            onMutate() { setFormError('') }
        }
    )

    const date_from = (course?.training_stream) instanceof Array
        ? null
        : course?.training_stream.date_from

    const date_start = date_from ? dbTimeToMoment(date_from) : null

    return (
        <div className="uif-course-price__feedback-block">
            {
                isNumber(course?.max_seats) &&
                <div className="uif-course-price__feedback-info">
                    <p className="uif-course-price__feedback-info-title">
                        {`${course?.max_seats} ${placeCountWord(course?.max_seats || 0)}`}
                    </p>
                    <p className="uif-course-price__feedback-info-description">Мест на курсе</p>
                </div>
            }
            {
                date_start &&
                <div className="uif-course-price__feedback-info start">
                    <p className="uif-course-price__feedback-info-title">
                        {
                            date_start?.format('D MMMM')
                        }
                    </p>
                    <p className="uif-course-price__feedback-info-description">Старт</p>
                </div>
            }

            <FeedbackForm
                wrapClasses={['uif-course-price__form']}
                isFetching={feedbackMutation.isLoading}
                errorMessage={formError}
                values={feedbackForm.values}
                errors={feedbackForm.errors}
                touched={feedbackForm.touched}
                handleChange={feedbackForm.handleChange}
                handleBlur={feedbackForm.handleBlur}
                onSubmit={feedbackForm.submitForm}
            />
        </div>
    )
})

export default CoursePriceFeedback