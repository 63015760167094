import { action, makeAutoObservable } from "mobx";
import { CoursePageInitialResp } from "types/apiTypes";


class CoursePageState {
    initial: CoursePageInitialResp | null = null
    prevCourse: CoursePageInitialResp | null = null

    readyAnimate: boolean = false

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInit: action.bound,
                statusAnimate: action.bound,
                clearInitial: action.bound,
            }
        )
    }

    updateInit = (newData: CoursePageInitialResp | null) => {
        this.initial = newData
    }

    updatePrevCourse = () => {
        this.prevCourse = this.initial
    }

    statusAnimate = (val: boolean) => {
        this.readyAnimate = val
    }

    clearInitial = () => {
        this.initial = null
    }
}

export const coursePageState = new CoursePageState()