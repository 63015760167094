import { action, makeAutoObservable } from "mobx";
import { TeacherCourse, TeacherResp } from "types/apiTypes";

class TeacherPageStore {
    info: TeacherResp | null = null
    courses: TeacherCourse[] = []
    isInitFilter: boolean = false

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInfo: action.bound,
                clearState: action.bound,
                addCourses: action.bound,
                removeCourses: action.bound,
                setInitializedFilter: action.bound,
            }
        )
    }

    updateInfo = (newData: TeacherResp | null) => {
        if (newData) {
            this.info = newData
        } else {
            this.info = null
        }
    }

    addCourses = (courses: TeacherCourse[], page: number) => {
        if (page === 1) return this.courses = courses

        const oldIds = this.courses.map(c => c.id)
        const newUniqCourses = courses.filter(c => !oldIds.includes(c.id))
        this.courses = [...this.courses, ...newUniqCourses]
    }

    removeCourses = () => {
        this.courses = []
    }

    setInitializedFilter = (val: boolean) => {
        this.isInitFilter = val
    }

    clearState = () => {
        this.info = null
        this.courses = []
        this.isInitFilter = false
    }
}

export const teacherPageState = new TeacherPageStore()