import { replaceEntersToSpan } from '@utils/parsers'
import { clickWithScroll } from '@utils/styleUtils'
import React from 'react'
import { Link } from 'react-router-dom'
import './LinksBlock.scss'

type LinksBlockProps = {
    links: Array<{
        path: string
        title: string
    }>
    size?: 'sm'
    placeholder?: string
    className?: string
}

const LinksBlock: React.FC<LinksBlockProps> = (props) => {
    const {
        links, placeholder, className,
        size
    } = props

    const listClasses = () => {
        let classes = 'uif-links-block'
        if (className) classes = `${className} ${classes}`
        if (size === 'sm') classes = `${classes} sm`
        return classes
    }

    return (
        <>
            <ul className={listClasses()}>
                {
                    links.map((link, i) => (
                        <li className="uif-links-block__item" key={i}>
                            <Link
                                to={link.path}
                                className="uif-links-block__item-link"
                                onClick={clickWithScroll}
                            >
                                <span className="uif-links-block__item-text">
                                    {replaceEntersToSpan(link.title, 'uif-span-devider')}
                                </span>
                                <div className="uif-links-block__item-arrow">
                                    <img
                                        className="default-arrow"
                                        src="/static/img/ui/arrow.svg"
                                        alt="arrow"
                                    >
                                    </img>
                                    <img
                                        className="hover-arrow"
                                        src="/static/img/ui/arrow-hover.svg"
                                        alt="."
                                    >
                                    </img>
                                </div>
                            </Link>
                        </li>
                    ))
                }
            </ul>
            {
                !links.length && placeholder &&
                <p className="uif-links-block__placeholder">
                    {placeholder}
                </p>
            }
        </>
    )
}

export default LinksBlock