import React from 'react'
import './PaymentOrderConfirmBanks.scss'

interface PaymentOrderConfirmBanksProps { }

const PaymentOrderConfirmBanks: React.FC<PaymentOrderConfirmBanksProps> = () => {

    return (
        <section className="uif-payment-order-confirm-banks">
            <div className="uif-container">
                <div className="uif-payment-order-confirm-banks__content-wrapper">
                    <div className="uif-payment-order-confirm-banks__images">
                        <img src="/static/img/banks/alfa.jpg" alt="alfa-bank" />
                        <img src="/static/img/banks/mastercard.jpg" alt="mastercard" />
                        <img src="/static/img/banks/world.jpg" alt="world" />
                        <img src="/static/img/banks/visa.jpg" alt="visa" />
                    </div>
                    <p className="uif-payment-order-confirm-banks__cards-text">
                        Оплата банковскими картами осуществляется через АО «АЛЬФА-БАНК». К оплате принимаются карты VISA, MasterCard, МИР
                    </p>
                </div>
                <a
                    href="/static/files/docOferty.pdf?v=5"
                    target="_blank"
                    rel="noreferrer"
                    className="uif-institute-license__doc-link"
                >
                    <img
                        src="/static/img/ui/redirect.svg"
                        alt="<"
                        className="uif-institute-license__doc-link-icon"
                    />
                    Договор публичной оферты
                </a><br/>
            </div>
        </section>
    )
}

export default PaymentOrderConfirmBanks