import UifButton from '@components/ui/buttons/UifButton/UifButton'
import Collapse from '@components/ui/Collapse/Collapse'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './CourseFAQ.scss'

type CourseFAQProps = {
    wrapClasses?: string[]
    onClickQuestion: () => void
}

const CourseFAQ: React.FC<CourseFAQProps> = observer((props) => {

    const {
        wrapClasses,
        onClickQuestion
    } = props

    const data = store.coursePage.initial?.q_and_a || []

    const faqClasses = () => {
        let classes = ['uif-course-faq']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    if (!data.length) return null

    return (
        <div className={faqClasses()}>
            <div className="uif-container">
                <div className="uif-course-faq__content">
                    <div className="uif-course-faq__left-block">
                        <p className="uif-course-faq__title">
                            Часто задаваемые вопросы
                        </p>
                        <UifButton
                            styleType="grey"
                            className="uif-course-faq__question-btn"
                            onClick={onClickQuestion}
                        >
                            Получить консультацию
                        </UifButton>
                    </div>
                    <div className="uif-course-faq__collapse-list">
                        {
                            data.map((question, i) => (
                                <Collapse
                                    key={i}
                                    title={question.question}
                                    collapseText={question.answer}
                                />
                            ))
                        }
                    </div>
                    <div className="uif-course-faq__sm-controls">
                        <UifButton
                            styleType="grey"
                            className="uif-course-faq__question-btn"
                            onClick={onClickQuestion}
                        >
                            Получить консультацию
                        </UifButton>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default CourseFAQ