export const lockBody = () => document.body.classList.add('lock')
export const unlockBody = () => document.body.classList.remove('lock')

export const clickWithScroll = () => {
    window.scrollTo({
        top: 0,
        // behavior: smooth ? 'smooth' : 'auto'
    })
}

export const smoothAnchor = (selector: string) => {
    document.querySelector(selector)?.scrollIntoView({
        behavior: 'smooth'
    })
}