import React from 'react'

type MainOnlineTitleProps = {
}

const MainOnlineTitle: React.FC<MainOnlineTitleProps> = (props) => {

    return (
        <div className="uif-main-online__title">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 257.39 52.98">
                <path d="M22.6 52.48a24.12 24.12 0 01-9.07-1.65 18.4 18.4 0 01-7-4.93 23 23 0 01-4.47-8.14A36.6 36.6 0 01.5 26.49a36.6 36.6 0 011.58-11.27 22.89 22.89 0 014.47-8.13 18.18 18.18 0 017-4.93A23.93 23.93 0 0122.6.5a24 24 0 019.08 1.66 18.18 18.18 0 017 4.93 22.72 22.72 0 014.46 8.13 36.29 36.29 0 011.59 11.27 36.29 36.29 0 01-1.59 11.27 22.86 22.86 0 01-4.46 8.14 18.4 18.4 0 01-7 4.93 24.14 24.14 0 01-9.08 1.65zm0-9.72a9.62 9.62 0 007.74-3.24q2.77-3.24 2.78-9.28v-7.49q0-6-2.78-9.29a9.62 9.62 0 00-7.74-3.24 9.64 9.64 0 00-7.74 3.24q-2.76 3.24-2.77 9.29v7.49q0 6 2.77 9.28a9.64 9.64 0 007.74 3.24zM68.25 27.86l-4.82-10.08h-.22v33.84H52.92V1.36h12l13.7 23.76 4.82 10.08h.22V1.36H94v50.26H82zM104.11 51.62V1.36h10.94V41.9h19.15v9.72zM139.39 51.62v-8.71h6.48V10.08h-6.48V1.36h23.9v8.72h-6.48v32.83h6.48v8.71zM186.83 27.86L182 17.78h-.22v33.84H171.5V1.36h11.95l13.75 23.76L202 35.2h.21V1.36h10.3v50.26h-11.92zM222.69 51.62V1.36h34.2v9.72h-23.26v10.23h20V31h-20v10.9h23.26v9.72z" />
            </svg>
        </div>
    )
}

export default MainOnlineTitle