import { action, makeAutoObservable } from "mobx";
import { TimetableCatalogItem, TimetableCatalogFilterResp } from "types/apiTypes";

import { configure } from 'mobx';
import { sortTimetableCoursesByDate } from "@utils/filters";
configure({ useProxies: 'never' });

class TimetableCatalogFilterStore {
    initial: TimetableCatalogFilterResp | null = null
    courseCards: TimetableCatalogItem[] = []

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInit: action.bound,
                addCourses: action.bound,
                removeAllCourses: action.bound,
                clearState: action.bound,
            }
        )
    }

    updateInit = (newData: TimetableCatalogFilterResp) => {
        this.initial = newData
    }

    addCourses = (courses: TimetableCatalogItem[], page: number) => {
        if (page === 1) return this.courseCards = courses

        const oldIds = this.courseCards.map(c => c.id)
        const newUniqCourses = courses.filter(c => !oldIds.includes(c.id))

        this.courseCards = sortTimetableCoursesByDate([...this.courseCards, ...newUniqCourses])
    }

    removeAllCourses = () => {
        this.courseCards = []
    }

    clearState = () => {
        this.initial = null
        this.courseCards = []
    }
}

export const timetableCatalogFilterStore = new TimetableCatalogFilterStore()