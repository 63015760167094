import React from 'react'
import { useState } from 'react'

interface VkIconProps {
    className?: string
    fill?: string
    isHovered?: boolean
}

const WhIcon: React.FC<VkIconProps> = (props) => {

    const {
        className, fill, isHovered,
    } = props

    const [hoveredIcon, setHovered] = useState(false)

    const hovered = isHovered || hoveredIcon

    const fillStyle = hovered
        ? "url(#paint0_linear)"
        : fill ? fill : "#000"

    return (
        <svg
            viewBox="0 0 1000 1000"
            fill={fillStyle}
            style={{shapeRendering: 'geometricPrecision', textRendering: 'geometricPrecision', fillRule: 'evenodd', clipRule: 'evenodd', width: '27px'}}
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <path d="M1.11 999.1c4.98,0.11 33.27,-7.48 39.19,-9.45 9.63,-3.2 31.87,-6.84 41.85,-9.61l60.48 -14.5c6.02,-1.43 13.18,-3.76 20.41,-5.38 27.69,-6.17 53.28,-12.79 81.84,-19.47 21.22,-4.97 11.02,-3.71 36.74,8.37 11.64,5.47 22.7,10.38 35.53,15.61 204.43,83.26 451.41,12.85 580.14,-161.6 64.68,-87.68 102.92,-187.2 102.69,-303.65 -0.2,-101.38 -29.97,-187.45 -71.18,-256.74 -59.56,-100.16 -161.49,-182.83 -274.02,-217.95 -140.85,-43.97 -280.82,-27.04 -401.76,41.4 -146.77,83.07 -238.02,229.37 -251.89,403.7 -7.31,91.85 22.02,204.18 60.04,267.16 5.38,8.9 -1.27,21.82 -3.57,31.85l-48.79 197.29c-1.53,6.37 -7.82,27.64 -7.7,32.97zm641.22 -262.95c43.1,0 68.02,-19.99 86.87,-48.88 20.27,-31.06 23.08,-69.31 -13.49,-87.61 -14.41,-7.22 -29.44,-14.67 -43.65,-21.75 -13.84,-6.89 -31.37,-17.28 -53.28,-12.94 -44.41,8.8 -31.37,65.75 -77.65,48.81 -62.66,-22.93 -110.86,-68.54 -143.75,-120.1 -23.39,-36.69 -22.71,-37.93 0.82,-64.83 5.9,-6.74 10.47,-12.01 14.29,-18.98 10.78,-19.69 3.09,-35.39 -4.19,-51.85 -6.7,-15.11 -13.92,-30.72 -20.72,-45.93 -8.16,-18.26 -11.14,-26.73 -28.34,-36.92 -12.88,-7.63 -39.82,-11.19 -55.28,-6.23 -26.32,8.44 -49.17,47.12 -56.51,75.57 -21.59,83.79 25.72,145.34 63.37,192.89 63.88,80.7 187.63,198.75 331.51,198.75z"/>
            {
                hovered &&
                <defs>
                    <linearGradient id="paint0_linear" x1="34.5969" y1="20.1292" x2="5.84074" y2="20.1599" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#9B4DB2" />
                        <stop offset="1" stopColor="#EA4779" />
                    </linearGradient>
                </defs>
            }
        </svg>
    )
}

export default WhIcon