import { makeAutoObservable, action } from 'mobx';
import { InstitutePageResp } from 'types/apiTypes';

class InstitutePageStore {
    info: InstitutePageResp | null = null

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInfo: action.bound
            }
        )
    }

    updateInfo = (data: InstitutePageResp | null) => {
        this.info = data
    }
}

export const institutePageState = new InstitutePageStore();