import React from 'react'
import { ReactNode } from 'react'
import './PaymentOrderSteps.scss'

interface PaymentOrderStepsProps {
    steps: ReactNode[]
}

const PaymentOrderSteps: React.FC<PaymentOrderStepsProps> = (props) => {

    const {
        steps
    } = props

    return (
        <section className="uif-payment-order-steps">
            <div className="uif-container">
                <ul className="uif-payment-order-steps__list">
                    {
                        steps.map((step, idx) => (
                            <li
                                className="uif-payment-order-steps__item"
                                key={idx}
                            >
                                <span className="uif-payment-order-steps__item-count">
                                    {idx + 1}
                                </span>
                                <p className="uif-payment-order-steps__item-text">
                                    {step}
                                </p>
                            </li>
                        ))
                    }
                </ul>
            </div>
        </section>
    )
}

export default PaymentOrderSteps