import React, { useState } from 'react'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import { store } from '@store/index'
import QuestionFormBlock from '@components/commonBlocks/QuestionFormBlock/QuestionFormBlock'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'
import TeachersTitle from './components/TeachersTitle/TeachersTitle'
import TeachersList from './components/TeachersList/TeachersList'
import TeachersLights from './components/TeachersLights/TeachersLights'
import { useQuery } from 'react-query'
import { teachersPageApi } from '@api/requestFunctions'
import { TeachersPageInitialResp } from 'types/apiTypes'
import { observer } from 'mobx-react-lite'
import FetchPlaceholder from '@components/commonBlocks/FetchPlaceholder/FetchPlaceholder'

interface TeachersPageProps { }

const TeachersPage: React.FC<TeachersPageProps> = observer(() => {

    const [show, setShow] = useState<boolean>(false)

    const queryInit = useQuery<TeachersPageInitialResp, Error>(
        'teachers-page-init',
        teachersPageApi.getAllInfo,
        {
            refetchOnWindowFocus: false,
            retry: 1,
            staleTime: 300 * 1000,
            onSuccess: (data) => {
                console.log('teachers page init', data)
                store.teachersPage.updateInit(data)
            },
            onError: (err) => console.log('teachers page ERROR init', err),
        }
    )

    useNavbarSettings(store)

    if (queryInit.isLoading) return <FetchPlaceholder styles={{height: '120vh'}}/>

    return (
        <>
            <TeachersLights />
            <TeachersTitle />
            <TeachersList />

            <QuestionFormBlock
                titlePage="Страница тренеров"
                wrapClasses={'teachers-page'}
                onSuccess={() => setShow(true)}
            />
            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={show}
                onClose={() => setShow(false)}
            />
        </>
    )
})

export default TeachersPage