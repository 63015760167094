import React from 'react'
import './UifButton.scss'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


interface UifButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    styleType: 'dark' | 'outline' | 'grey'
    className?: string
    loading?: boolean
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const UifButton = React.memo(React.forwardRef<HTMLButtonElement, UifButtonProps>((props, ref) => {

    const {
        className, children, styleType,
        disabled,
        loading = false,
        onClick,
        ...otherProps
    } = props

    const btnClasses = () => {
        const classes = ['uif-btn']
        if (className) classes.push(className)
        if (styleType === 'dark') classes.push('dark')
        if (styleType === 'outline') classes.push('outline')
        if (styleType === 'grey') classes.push('grey')
        return classes.join(' ')
    }

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (disabled) {
            e.preventDefault()
            return null
        }
        if (onClick) onClick(e)
    }

    return (
        <button
            className={btnClasses()}
            onClick={handleClick}
            disabled={disabled}
            ref={ref}
            {...otherProps}
        >
            <span
                className={`uif-btn__content`}
            >
                {children}
            </span>
            {
                loading &&
                <span className="uif-btn__loader">
                    <Spin indicator={<LoadingOutlined spin className="icon" />} />
                </span>
            }
        </button>
    )
}))

export default UifButton