import CountLinks from '@components/CountLinks/CountLinks'
import { store } from '@store/index'
import { сountWord, replaceEntersToSpan } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import React from 'react'
import MainOfflineTitle from './components/MainOfflineTitle/MainOfflineTitle'
import './MainOffline.scss'

type OfflineProps = {}

const MainOffline: React.FC<OfflineProps> = observer(() => {

    const siteSettings = store.settings.initial?.settings || []
    const cities = store.mainPage.initial?.form_training.offline.cities || []

    const citiesWithCourses = cities.filter(c => c.course_count)
    const offlineFormTrainingId = siteSettings.find(s => s.hasOwnProperty('offline_id'))?.offline_id || ''

    const description = siteSettings
        .find(s => s.hasOwnProperty('offline_text'))
        ?.offline_text + '' || ''

    return (
        <section className="uif-main-offline">
            <div className="uif-container">
                <div className="uif-main-offline__text">
                    <MainOfflineTitle />
                    <span className="uif-main-offline__placeholder">offline</span>
                    <p className="uif-main-offline__description">
                        {replaceEntersToSpan(description, 'uif-span-devider')}
                    </p>
                </div>
                <CountLinks
                    className="uif-main-offline__links"
                    links={citiesWithCourses.map(c => ({
                        link: `/coursesCatalog?cities[]=${c.id}&form_trainings[]=${offlineFormTrainingId}`,
                        title: c.name,
                        key: c.id,
                        countText: `${c.course_count} ${сountWord(c.course_count, 'курс')}`,
                    }))}
                />
            </div>
        </section>
    )
})

export default MainOffline