import { action, makeAutoObservable } from "mobx";
import { PaymentOrderGetNumberResp } from 'types/apiTypes';

class PaymentOrderStore {
    show: boolean = false
    orderNumber: string | null = null
    orderId: string | null = null
    confirmInfo: PaymentOrderGetNumberResp | null = null
    foundOrder: boolean | null = null

    constructor() {
        makeAutoObservable(
            this,
            {
                setOrderNumber: action.bound,
                setOrderId: action.bound,
                setConfirmInfo: action.bound,
                setShow: action.bound,
                clearStore: action.bound,
            }
        )
    }

    setShow = (val: boolean) => {
        this.show = val
    }

    setConfirmInfo = (data: PaymentOrderGetNumberResp | null) => {
        this.confirmInfo = data

        if (data?.order?.serviceId) {
            this.foundOrder = true
        } else {
            this.foundOrder = false
        }
    }

    setOrderNumber = (ordId: string | null) => {
        this.orderNumber = ordId
    }

    setOrderId = (orderId: string | null) => {
        this.orderId = orderId
    }

    clearStore = () => {
        this.show = false
        this.orderNumber = null
        this.orderId = null
        this.confirmInfo = null
        this.foundOrder = null
    }
}

export const paymentOrderState = new PaymentOrderStore()