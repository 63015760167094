import React from 'react'
import './InstituteLegal.scss'
import { replaceEntersToSpan } from '@utils/parsers'

interface InstituteLegalProps { }

const InstituteLegal: React.FC<InstituteLegalProps> = () => {

    const legalInfo = [
        {
            title: 'Наименование предприятия',
            descr: 'Общество с ограниченной ответственностью «АПВАРД.ИНСТИТУТ ФИТНЕСА»',
        },
        {
            title: 'Директор',
            descr: 'Васильева Мария Михайловна',
        },
        {
            title: 'Юридический адрес',
            descr: '620014, г. Екатеринбург, ул. Вайнера, дом 10, пом.520',
        },
        {
            title: 'ИНН/КПП',
            descr: '6671040901/667101001',
        },
        {
            title: 'Краткое наименование',
            descr: 'ООО «АПВАРД.ИНСТИТУТ ФИТНЕСА»',
        },
        {
            title: 'ОГРН',
            descr: '1169658047096',
        },
        {
            title: 'Фактический адрес',
            descr: 'БЦ «Дубровин», ул. Чернышевского, 7, оф.428',
        },
    ]

    const bankInfo = [
        {
            title: 'Банковские реквизиты',
            descr: `ФИЛИАЛ «ЕКАТЕРИНБУРГСКИЙ» АО «АЛЬФА-БАНК» 
            г. Екатеринбург
            БИК 046577964
            ИНН 6671040901
            КПП 667101001
            БИК 046577964
            КС 30101810100000000964
            РС 40702810938030011947`,
        },
        {
            descr: `UPWARD INSTITUTE OF FITNESS принимает оплату только на юридические реквизиты компании.
            Возможна online-оплата по спец. ссылке на ваш сотовый телефон после предварительной записи/звонка менеджером.`,
        },
    ]

    return (
        <section className="uif-institute-legal">
            <div className="uif-container">
                <h2 className="uif-institute-legal__title">
                    Юридическая<br />
                    информация
                </h2>
                <div>
                    <ul className="uif-institute-legal__list">
                        {
                            legalInfo.map((l, idx) => (
                                <li className="uif-institute-legal__item" key={idx}>
                                    <p className="uif-institute-legal__item-title">
                                        {l.title}
                                    </p>
                                    <p className="uif-institute-legal__item-text">
                                        {replaceEntersToSpan(l.descr, 'uif-span-devider')}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                    <ul className="uif-institute-legal__list">
                        {
                            bankInfo.map((l, idx) => (
                                <li className="uif-institute-legal__item" key={idx}>
                                    {
                                        l.title
                                            ? <p className="uif-institute-legal__item-title">
                                                {l.title}
                                            </p>
                                            : <div className="uif-institute-legal__item-title-placeholder"></div>
                                    }
                                    <p className="uif-institute-legal__item-text">
                                        {replaceEntersToSpan(l.descr, 'uif-span-devider')}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div style={{marginTop: 12}}>
                    <a
                        href="/static/files/docOferty.pdf?v=5"
                        target="_blank"
                        rel="noreferrer"
                        className="uif-institute-license__doc-link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="<"
                            className="uif-institute-license__doc-link-icon"
                        />
                        Договор публичной оферты
                    </a><br/>
                    <a
                        href="/static/files/DPO.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="uif-institute-license__doc-link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="<"
                            className="uif-institute-license__doc-link-icon"
                        />
                        Положение о выдаче документов ДПО
                    </a><br/>
                    <a
                        href="/static/files/priem.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="uif-institute-license__doc-link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="<"
                            className="uif-institute-license__doc-link-icon"
                        />
                        Положение о порядке приёма, перевода, отчисления обучающихся
                    </a><br/>
                    <a
                        href="/static/files/APK.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="uif-institute-license__doc-link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="<"
                            className="uif-institute-license__doc-link-icon"
                        />
                        Положение об АПК
                    </a><br/>
                    <a
                        href="/static/files/attestacia.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="uif-institute-license__doc-link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="<"
                            className="uif-institute-license__doc-link-icon"
                        />
                        Положение об итоговой аттестаций
                    </a><br/>
                    <a
                        href="/static/files/dop.pdf"
                        target="_blank"
                        rel="noreferrer"
                        className="uif-institute-license__doc-link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="<"
                            className="uif-institute-license__doc-link-icon"
                        />
                        Положение по программе дополнительного образования
                    </a><br/>
                </div>
            </div>
        </section>
    )
}

export default InstituteLegal