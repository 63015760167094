import InputSelect from '@components/form/InputSelect/InputSelect'
import React from 'react'
import { TimetableCatalogFilterResp } from 'types/apiTypes'
import { SetFieldValue, TimetableFilterFields } from 'types/formTypes'
import './TimetableFilterLine.scss'
import { withBreakpoints } from 'breakpoints'
import { timetableOptions } from '@utils/filters'

type TimetableFilterLineProps = {
    values: TimetableFilterFields
    filters: TimetableCatalogFilterResp['filters'] | undefined
    setFieldValue: SetFieldValue<TimetableFilterFields>
}

const TimetableFilterLine: React.FC<TimetableFilterLineProps> = (props) => {

    const {
        values, filters,
        setFieldValue,
    } = props

    const handleChangeSelect = (fieldName: keyof TimetableFilterFields, value: string) => {
        if (value === '') return setFieldValue(fieldName, '')
        setFieldValue(fieldName, value)
    }

    // const [categoryId, setCategoryId] = useState<number>(0)

    return (
        <>
            <section className="uif-timetable-filter-line">
                <div className="uif-container">
                    <div className="uif-timetable-filter-line__body">
                        <div className="uif-timetable-filter-line__left-block">
                            <InputSelect
                                placeholder="Город"
                                value={values.city}
                                wrapClasses={['filter-line-select']}
                                options={timetableOptions(filters?.cities)}
                                listAlign="center"
                                onChange={e => handleChangeSelect('city', e.target.value)}
                            />
                            <InputSelect
                                placeholder="Направление"
                                value={values.direction}
                                wrapClasses={['filter-line-select']}
                                options={timetableOptions(filters?.directions)}
                                listAlign="center"
                                onChange={e => handleChangeSelect('direction', e.target.value)}
                            />
                        </div>

                        {/* <div className="uif-timetable-filter-line__right-block">
                            {
                                timeTableLineRightFilters.map((category, i) => (
                                    <InputRadioText
                                        wrapClasses={['uif-timetable-filter-line__text']}
                                        key={i}
                                        title={category.title}
                                        value={category.value}
                                        checked={category.value === categoryId}
                                        onChange={e => setCategoryId(+e.target.value)}
                                    />
                                ))
                            }
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default withBreakpoints<TimetableFilterLineProps>(TimetableFilterLine)