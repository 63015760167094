import ImgFade from '@components/optimization/ImgFade/ImgFade'
import React from 'react'
import './InstituteLights.scss'

interface InstituteLightsProps { }

const InstituteLights: React.FC<InstituteLightsProps> = () => {

    return (
        <div className="uif-institute-lights">
            <div className="uif-container uif-institute-lights__container">
                <div className="uif-institute-lights__wrapper">
                    <ImgFade
                        src="/static/img/parallax-lights.png"
                        alt="."
                        className="uif-institute-lights__img"
                    />
                </div>
            </div>
        </div>
    )
}

export default InstituteLights