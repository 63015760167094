import { lockBody, unlockBody } from '@utils/styleUtils'
import React, { useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import './PointModal.scss'

type PointModalProps = {
    isShow: boolean
    clickX: number
    clickY: number
    wrapClasses: string[]
    zIndex?: number
    closeMinWidth?: number
    onClose: (e?: React.MouseEvent) => void
}

const PointModal: React.FC<PointModalProps> = (props) => {
    const {
        children, isShow, clickX,
        clickY, wrapClasses, zIndex,
        closeMinWidth,
        onClose,
    } = props

    const modalClasses = () => {
        let classes = ['uif-point-modal']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    const handleResize = () => {
        if (closeMinWidth &&
            window.innerWidth >= closeMinWidth &&
            isShow
        ) {
            unlockBody()
            onClose()
        }
    }

    useEffect(() => {
        if (isShow) return lockBody()
        unlockBody()
    }, [isShow])

    useEffect(() => {
        if (closeMinWidth) {
            window.addEventListener('resize', handleResize)
            return () => {
                window.removeEventListener('resize', handleResize)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isShow])

    return (
        <CSSTransition
            in={isShow}
            timeout={{
                enter: 500,
                exit: 550
            }}
            classNames={{
                enterActive: 'show',
                enterDone: 'show show-done',
            }}
            unmountOnExit
        >
            <div
                className={modalClasses()}
                style={{ zIndex: zIndex }}
                onClick={onClose}
            >
                <div
                    className="uif-point-modal__bg"
                    style={{ top: clickY, left: clickX }}
                ></div>
                {
                    children
                }
            </div>
        </CSSTransition>
    )
}

export default PointModal