import React from 'react'
import PaymentOrderTitle from './components//PaymentOrderTitle/PaymentOrderTitle'
import PaymentOrderSteps from './components/PaymentOrderSteps/PaymentOrderSteps'
import PaymentOrderCheckForm from './components/PaymentOrderCheckForm/PaymentOrderCheckForm'

interface PaymentOrderCheckProps { }

const PaymentOrderCheck: React.FC<PaymentOrderCheckProps> = () => {

    return (
        <>
            <PaymentOrderTitle />
            <PaymentOrderSteps
                steps={[
                    'Отправить заявку на участие в описании курса/семинара с помощью кнопки «Записаться»',
                    'Дождаться звонка менеджера',
                    'Ввести в поле «Номер заказа» код из СМС',
                ]}
            />
            <PaymentOrderCheckForm />
        </>
    )
}

export default PaymentOrderCheck