class YandexMetricApi {
    _check_ym() {
        return window.ym || null
    }

    goalLead() {
        if (!this._check_ym()) {
            console.warn("Goal hasn't been send, ym is not defined")
            return null
        }
        window.ym(83251690, 'reachGoal', 'lead')
    }
}

class GoogleAnalyticsApi {
    _check_gtag() {
        return window.gtag || null
    }

    goalLead() {
        if (!this._check_gtag()) {
            console.warn("Goal hasn't been send, gtag is not defined")
            return null
        }
        window.gtag('event', 'send',{event_category: 'form'});
    }
}

export const yandexMetricaApi = new YandexMetricApi()
export const googleAnalyticsApi = new GoogleAnalyticsApi()