import ImgFade from '@components/optimization/ImgFade/ImgFade'
import React from 'react'
import './InstitutePartners.scss'

interface InstitutePartnersProps { }

const partners = [
    {
        imgSrc: '/static/img/institute/partners/bf.jpg',
        alt: 'Bright fit. Сеть фитнес-клубов',
    },
    {
        imgSrc: '/static/img/institute/partners/gfr.jpg',
        alt: 'Екатеринбург. GLOBAL FITNESS RUSSIA',
    },
    {
        imgSrc: '/static/img/institute/partners/fe.jpg',
        alt: 'FITNESS EXPERT',
    },
    {
        imgSrc: '/static/img/institute/partners/rgppu.jpg',
        alt: 'РГППУ. Российский государственный професиионально-педагогический университет',
    },
]

const InstitutePartners: React.FC<InstitutePartnersProps> = () => {

    return (
        <section className="uif-institute-partners">
            <div className="uif-container">
                <h2 className="uif-institute-partners__title">
                    Партнеры
                </h2>
                <div className="uif-institute-partners__link-block">
                    <p className="uif-institute-partners__link-text">
                        Партнерская программа
                    </p>
                    <a
                        href="#"
                        className="uif-institute-partners__link"
                    >
                        <img
                            src="/static/img/ui/redirect.svg"
                            alt="-->"
                            className="uif-institute-partners__link-icon"
                        />
                    </a>
                </div>
                <div className="uif-institute-partners__list">
                    {
                        partners.map((p, idx) => (
                            <div
                                className="uif-institute-partners__item"
                                key={idx}
                            >
                                <ImgFade
                                    src={p.imgSrc}
                                    alt={p.alt}
                                />
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default InstitutePartners