import React, { useState } from 'react'
import { useCallback } from 'react'
import { useEffect } from 'react'
import './MainSpecialistsCounter.scss'

type MainSpecialistsCounterProps = {
    shouldAnimate?: boolean
}

const baseStyles = {
    fontFamily: "'IBM Plex Sans', Arial, Helvetica, sans-serif",
    fontWeight: 500,
    letterSpacing: '-0.04em',
    textTransform: 'uppercase',
    lineHeight: '100%',

} as React.CSSProperties

const breakpointsStyles = {
    xxl: {
        ...baseStyles,
        fontSize: 200,
    },
    xl: {
        ...baseStyles,
        fontSize: 120,
    },
    sm: {
        ...baseStyles,
        fontSize: 80,
    },
} as { [key: string]: React.CSSProperties }

// const commonAnimateProps = {
//     config: { mass: 1, tension: 210, friction: 20 },
//     animationType: "calm"
// } as const

const MainSpecialistsCounter: React.FC<MainSpecialistsCounterProps> = (props) => {

    const [currentPoint, setCurrentPoint] = useState<keyof typeof breakpointsStyles>('xxl')

    // const countValue = 12644

    const handleResize = useCallback(() => {
        if (window.innerWidth >= 1440 && currentPoint !== 'xxl') {
            return setCurrentPoint('xxl')
        }
        if (window.innerWidth >= 576 && window.innerWidth <= 1439 && currentPoint !== 'xl') {
            return setCurrentPoint('xl')
        }
        if (window.innerWidth <= 575 && currentPoint !== 'sm') {
            return setCurrentPoint('sm')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPoint])

    useEffect(() => {
        handleResize()
        const handler = () => handleResize()
        window.addEventListener('resize', handler)
        return () => {
            window.removeEventListener('resize', handler)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handleResize])

    return (
        <>
            <p className="uif-main-spec__count">5 127</p>
{/* 
            <div className="uif-main-spec-count">
                {
                    currentPoint === 'xxl' &&
                    <AnimatedNumber
                        fontStyle={breakpointsStyles.xxl}
                        animateToNumber={countValue}
                        {...commonAnimateProps}
                    />
                }
                {
                    currentPoint === 'xl' &&
                    <AnimatedNumber
                        fontStyle={breakpointsStyles.xl}
                        animateToNumber={countValue}
                        {...commonAnimateProps}
                    />
                }
                {
                    currentPoint === 'sm' &&
                    <AnimatedNumber
                        fontStyle={breakpointsStyles.sm}
                        animateToNumber={countValue}
                        {...commonAnimateProps}
                    />
                }
            </div> */}
        </>
    )
}

export default MainSpecialistsCounter
// {/* <p className="uif-main-spec__count">12 644</p> */ }