import { replaceEntersToSpan } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useRef } from 'react'
import { useEffect } from 'react'
import { CoursePageInitialResp } from 'types/apiTypes'
import './CourseProgramItems.scss'

type CourseProgramItemsProps = {
    streamData: CoursePageInitialResp['training_stream']
    collapsed: boolean | null
    itemsLength: number
    onChangeCollapse: (val: boolean) => void
    onShowAllBtn: (val: boolean) => void
}

const transition = `all 600ms ease`
const maxHeight = 400

const closedStyles = {
    height: maxHeight,
    transition,
}

const autoStyles = {
    height: 'auto',
    transition,
}

const CourseProgramItems: React.FC<CourseProgramItemsProps> = observer((props) => {
    const {
        streamData, collapsed, itemsLength,
        onChangeCollapse, onShowAllBtn,
    } = props

    const [collapseListStyle, setCollapseListStyle] = useState<React.CSSProperties>(autoStyles)

    const containerRef = useRef<HTMLDivElement>(null)

    const splitTitle = (countHours: number, idx: number) => {
        return [`Блок ${idx + 1}`, ' | ', `${countHours} ак.ч`]
    }

    const itemsListClasses = () => {
        const classes = ['uif-course-program-items']
        if (collapsed) classes.push('collapsed')
        return classes.join(' ')
    }

    useEffect(() => {
        const container = containerRef.current
        if (container) {
            const fullHeightOfList = container.clientHeight

            if (fullHeightOfList <= maxHeight) return setCollapseListStyle(autoStyles)
            if (collapsed === null && fullHeightOfList > maxHeight && itemsLength) {
                // close list if height of list more than maxHeight (only first render, when list fetched)
                onShowAllBtn(true)
                onChangeCollapse(true)
                return setCollapseListStyle(closedStyles)
            }

            if (collapsed !== null) {
                setCollapseListStyle(prev => ({
                    ...prev,
                    height: collapsed ? closedStyles.height : fullHeightOfList,
                }))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [streamData, containerRef, collapsed, itemsLength])

    if (streamData instanceof Array || !streamData) return null

    return (
        <div
            className={itemsListClasses()}
            style={collapseListStyle}
        >
            <div
                className="uif-course-program-items__container"
                ref={containerRef}
            >
                {
                    streamData?.programs?.map((program, idx) => (
                        <div className="uif-course-program-items__item" key={idx}>
                            <div className="uif-course-program-items__item-title">
                                {
                                    splitTitle(program.count_hours, idx)?.map((word, i) => (
                                        <span key={i}>{word}</span>
                                    ))
                                }
                            </div>
                            <div className="uif-course-program-items__item-text-block">
                                <p className="uif-course-program-items__item-name">
                                    {program.title}
                                </p>
                                <p className="uif-course-program-items__item-description-block">
                                    {
                                        replaceEntersToSpan(program.text, 'uif-course-program-items__item-description-word')
                                    }
                                </p>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
})

export default CourseProgramItems