import React, { FocusEvent } from 'react'
import './FeedbackForm.scss'
import InputFloatLabel from '@components/form/InputFloatLabel/InputFloatLabel'
import PhoneInput from '@components/form/PhoneInput/PhoneInput'
import { FormikErrors, FormikTouched } from 'formik'
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { FeedbackFormValues } from 'types/formTypes'
import UifButton from '@components/ui/buttons/UifButton/UifButton'
import InputFloatText from "@components/form/InputText/InputFloatText";

type FeedbackFormProps = {
    isFetching: boolean
    errorMessage: string
    values: FeedbackFormValues
    errors: FormikErrors<FeedbackFormValues>
    touched: FormikTouched<FeedbackFormValues>
    handleChange: React.ChangeEventHandler
    handleBlur: (e?: FocusEvent<HTMLInputElement> | FocusEvent<HTMLTextAreaElement> | undefined) => void
    onSubmit: () => void
    wrapClasses?: string[]
}

const FeedbackForm = React.forwardRef<HTMLFormElement, FeedbackFormProps>((props, ref) => {

    const {
        wrapClasses, isFetching, values,
        errorMessage, errors, touched,
        handleChange, handleBlur, onSubmit,
    } = props

    const formClasses = () => {
        let classes = ['uif-feedback-form']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    return (
        <form
            ref={ref}
            className={formClasses()}
            onSubmit={e => e.preventDefault()}
        >
            <InputFloatLabel
                placeholder="Имя"
                name="name"
                value={values.name}
                error={touched.name ? errors.name : ''}
                disabled={isFetching}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <PhoneInput
                name="phone"
                error={touched.phone ? errors.phone : ''}
                value={values.phone}
                disabled={isFetching}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <InputFloatLabel
                placeholder="Email"
                name="email"
                value={values.email}
                error={touched.email ? errors.email : ''}
                disabled={isFetching}
                onChange={handleChange}
                onBlur={handleBlur}
            />
            <InputFloatText placeholder="Комментарий"
                            name="text"
                            value={values.text}
                            error={touched.text ? errors.text : ''}
                            disabled={isFetching}
                            onChange={handleChange}
                            onBlur={handleBlur}/>

            <div className="uif-feedback-form__footer">
                <UifButton
                    styleType="dark"
                    className="uif-feedback-form__submit uif-btn uif-btn-dark active"
                    disabled={isFetching}
                    onClick={onSubmit}
                >
                    {
                        isFetching
                            ? 'Отправка'
                            : 'Отправить'
                    }
                    {
                        isFetching &&
                        <Spin indicator={<LoadingOutlined spin className="icon" />} />
                    }
                </UifButton>
                <p className="uif-feedback-form__agreement">
                    Отправляя заявку, я соглашаюсь на обработку&nbsp;
                    <a
                        href="/static/files/politika_obrabotki_pd.pdf"
                        target="_blank"
                        className="uif-feedback-form__agreement-link"
                    >персональных данных
                        <span></span>
                    </a>
                </p>
                {
                    errorMessage &&
                    <p className="uif-feedback-form__fetch-status error">
                        {errorMessage}
                    </p>
                }
            </div>
        </form>
    )
})

export default FeedbackForm