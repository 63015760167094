import UifButton from '@components/ui/buttons/UifButton/UifButton'
import InputFloatLabel from '@components/form/InputFloatLabel/InputFloatLabel'
import React from 'react'
import './PaymentOrderCheckForm.scss'
import { useFormik } from 'formik'
import { PaymentOrderCheckFormFields } from 'types/formTypes'
import { initialPaymentOrderCheck, validatePaymentOrderCheck } from './formData/paymentOrderCheckFormData'
import { useState } from 'react'
import { observer } from 'mobx-react-lite'
import { store } from '@store/index'
import { PaymentOrderGetNumberResp } from 'types/apiTypes'
import { useMutation } from 'react-query'
import { paymentOrderApi } from '@api/requestFunctions'
import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { updateSearchParam } from '@utils/parsers'


interface PaymentOrderCheckFormProps extends RouteComponentProps { }

const PaymentOrderCheckForm: React.FC<PaymentOrderCheckFormProps> = observer(({ history }) => {

    const ordNumber = store.paymentOrder.orderNumber || ''
    const foundOrder = store.paymentOrder.foundOrder

    const [formError, setFormError] = useState<string>('')

    const handleSubmit = (values: PaymentOrderCheckFormFields) => {
        setFormError('')
        confirmMutate.mutate(values.ordId)
    }

    const {
        values, errors, touched,
        setFieldValue, submitForm,
    } = useFormik<PaymentOrderCheckFormFields>({
        initialValues: {
            ...initialPaymentOrderCheck,
            ordId: ordNumber,
        },
        validate: validatePaymentOrderCheck,
        onSubmit: handleSubmit,
    })

    const handleChangeOrderNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormError('')
        setFieldValue('ordId', e.target.value)
    }

    const confirmMutate = useMutation<PaymentOrderGetNumberResp, Error, string>({
        mutationKey: 'payment-order-get-order-number',
        mutationFn: async (ordId) => paymentOrderApi.confirmInfo(ordId),
        onSuccess(data) {
            const serviceId = data.order.serviceId
            if (serviceId) {
                const search = history.location.search
                history.replace(updateSearchParam(search, 'ord', serviceId))
                store.paymentOrder.setOrderNumber(serviceId)
                store.paymentOrder.setConfirmInfo(data)
                return
            }
            // order not found
            setFormError('Заказ не найден, проверь номер заказа из СМС')
        },
        onError(e) {
            console.log(`Возникла ошибка при запросе заказа: ${e}`)
            setFormError(`Возникла ошибка при запросе заказа`)
        }
    })

    const isFetching = confirmMutate.isLoading

    useEffect(() => {
        if (foundOrder === false) setFormError('Заказ не найден, проверь номер заказа из СМС')
    }, [foundOrder])

    return (
        <div className="uif-payment-order-check-form">
            <div className="uif-container" onSubmit={e => e.preventDefault()}>
                <form className="uif-payment-order-check-form__form">
                    <div className="uif-payment-order-check-form__inputs">
                        <InputFloatLabel
                            value={values.ordId}
                            error={touched.ordId ? errors.ordId : ''}
                            placeholder="Номер заказа"
                            wrapClasses={['uif-payment-order-check-form__input-order']}
                            onChange={handleChangeOrderNumber}
                        />
                    </div>
                    <div className="uif-payment-order-check-form__controls">
                        <UifButton
                            styleType="dark"
                            className="uif-payment-order-check-form__submit"
                            loading={isFetching}
                            onClick={isFetching ? undefined : submitForm}
                        >
                            {
                                isFetching
                                    ? 'Проверка'
                                    : 'Проверить'
                            }
                        </UifButton>
                    </div>
                    {
                        formError &&
                        <p className="uif-payment-order-check-form__error">
                            {formError}
                        </p>
                    }
                </form>
            </div>
        </div>
    )
})

export default withRouter(PaymentOrderCheckForm)