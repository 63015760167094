import React from 'react'
import './TeachersTitle.scss'

interface TeachersTitleProps { }

const TeachersTitle: React.FC<TeachersTitleProps> = () => {

    return (
        <div className="uif-teachers-title">
            <div className="uif-container">
                <h1 className="uif-teachers-title__title">
                    Преподаватели<br/>
                    и партнеры
                </h1>
            </div>
        </div>
    )
}

export default TeachersTitle