import React, { useEffect, useState } from 'react'
import FadeModal from '../FadeModal/FadeModal'
import './QuestionModal.scss'
import { lockBody, unlockBody } from '../../../utils/styleUtils'
import FeedbackForm from '@components/form/FeedbackForm/FeedbackForm'
import { FeedbackFormValues } from 'types/formTypes'
import { useFormik } from 'formik'
import { initialFeedbackValues } from '@assets/data/forms'
import { feedbackValidate } from '@utils/formValidators'
import { useMutation } from 'react-query'
import { requestApi } from '@api/requestFunctions'
import { OptionsFeedback } from 'types/apiTypes'
import { yandexMetricaApi, googleAnalyticsApi } from '../../../otherOrigins/metricsApi'

type QuestionModalProps = {
    isShow: boolean
    wrapClasses?: string[]
    title?: string
    feedbackOptions?: OptionsFeedback
    description?: string
    onClose?: () => void
    onSuccess?: () => void
}

const QuestionModal: React.FC<QuestionModalProps> = React.memo((props) => {

    const {
        isShow, feedbackOptions, onSuccess,
        onClose,
    } = props

    const [formError, setFormError] = useState<string>('')

    const onSubmit = (values: FeedbackFormValues) => {
        feedbackMutation.mutate(values)
    }

    const feedbackForm = useFormik<FeedbackFormValues>({
        initialValues: initialFeedbackValues,
        validateOnChange: false,
        validate: feedbackValidate,
        onSubmit,
    })

    const feedbackMutation = useMutation<unknown, Error, FeedbackFormValues>(
        (formValues) => requestApi.feedbackSend(formValues, feedbackOptions),
        {
            mutationKey: 'feedbackMutation',
            onSuccess() {
                if (onSuccess) onSuccess()
                feedbackForm.setValues(initialFeedbackValues)
                yandexMetricaApi.goalLead()
                googleAnalyticsApi.goalLead()
            },
            onError() { setFormError('Возникла ошибка при отправке') },
            onMutate() { setFormError('') }
        }
    )

    useEffect(() => {
        if (isShow) return lockBody()
        unlockBody()
    }, [isShow])

    return (
        <FadeModal
            isShow={isShow}
            onClose={onClose}
        >
            <div
                className="uif-question-modal-body"
                onClick={e => e.stopPropagation()}
                onMouseDown={e => e.stopPropagation()}
                onMouseUp={e => e.stopPropagation()}
            >
                <FeedbackForm
                    isFetching={feedbackMutation.isLoading}
                    errorMessage={formError}
                    values={feedbackForm.values}
                    errors={feedbackForm.errors}
                    touched={feedbackForm.touched}
                    handleChange={feedbackForm.handleChange}
                    handleBlur={feedbackForm.handleBlur}
                    onSubmit={feedbackForm.submitForm}
                />
            </div>
        </FadeModal>
    )
})

export default QuestionModal