import React from 'react'
import './CommentSlider.scss'
import SwiperCore, { Navigation, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import CommentSlide from './CommentSlide/CommentSlide';
import { store } from '@store/index';

type CommentSliderProps = {
}

SwiperCore.use([Navigation, Scrollbar, A11y]);

const CommentSlider: React.FC<CommentSliderProps> = (props) => {

    const reviews = store.coursePage.initial?.course_reviews || []

    return (
        <div className="uif-comments-slider">
            <Swiper
                breakpoints={{
                    0: {
                        spaceBetween: 15,
                    },
                    768: {
                        spaceBetween: 40,
                    },
                }}
                slidesPerView="auto"
                navigation={{
                    nextEl: '#course-comment-next',
                    prevEl: '#course-comment-prev'
                }}
                scrollbar={{
                    el: '#course-comment-scrollbar',
                    draggable: true
                }}
                freeMode={true}
                className="uif-comments-slider__slider"
            >
                {
                    reviews.map((review, i) => (
                        <SwiperSlide key={i}>
                            <CommentSlide
                                item={review}
                            />
                        </SwiperSlide>
                    ))
                }
            </Swiper>

            <div className="uif-comments-slider__controls">
                <button
                    id="course-comment-prev"
                    className="uif-comments-slider__prev uif-btn"
                >
                    <img
                        src="/static/img/ui/arrow.svg"
                        alt="<-"
                        className="default-arrow"
                    />
                    <img
                        src="/static/img/ui/arrow-hover.svg"
                        alt="<-"
                        className="hover-arrow"
                    />
                </button>
                <button
                    id="course-comment-next"
                    className="uif-comments-slider__next uif-btn"
                >
                    <img
                        src="/static/img/ui/arrow.svg"
                        alt="->"
                        className="default-arrow"
                    />
                    <img
                        src="/static/img/ui/arrow-hover.svg"
                        alt="->"
                        className="hover-arrow"
                    />
                </button>
            </div>

            <div
                id="course-comment-scrollbar"
                className="uif-comments-slider__scrollbar"
            ></div>
        </div>
    )
}

export default CommentSlider