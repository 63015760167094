import React from 'react'
import './InstituteOnline.scss'
import UifLinkBtn from '@components/ui/buttons/UifLinkBtn/UifLinkBtn'
import { clickWithScroll } from '@utils/styleUtils'

interface InstituteOnlineProps { }

const InstituteOnline: React.FC<InstituteOnlineProps> = () => {

    return (
        <section className="uif-institute-online">
            <div className="uif-container">
                <h2 className="uif-institute-online__title">
                    online
                    <span>обучение</span>
                </h2>
                <p className="uif-institute-online__description">
                    Теоретические занятия UPWARD проводятся на платформе дистанционного обучения Teachbase.<br/>
                    Живой диалог с преподавателем позволяет полностью погрузиться в тему online-лекции и вынести максимум пользы.<br/>
                    Обучение доступно в записи 1 год с момента проведения занятия. Вы можете посмотреть лекцию в удобное для вас время
                </p>
                <UifLinkBtn
                    href="/timeTable"
                    styleType="dark"
                    className="uif-institute-online__all-btn"
                    onClick={clickWithScroll}
                >
                    Расписание
                </UifLinkBtn>
            </div>
        </section>
    )
}

export default InstituteOnline