import { setCookie } from '@utils/cookie'
import { entriesQueriesToString, queryStringToEntries } from '@utils/parsers'
import { UTM_COOKIE } from '@config/constants'
import { History, Location } from 'history'
import { useEffect } from 'react'
import { getUtm } from '@utils/dataUtils'

const utmAge = 432000

export const useUtm = (history: History, location: Location) => {

    useEffect(() => {
        const entries = queryStringToEntries(location.search)

        const utmParams = entries.filter(entry => entry[0].startsWith('utm_'))
        //const otherParams = entries.filter(entry => !entry[0].startsWith('utm_'))

        //const otherQueryString = entriesQueriesToString({ params: otherParams })
        const UtmString = entriesQueriesToString({ params: utmParams, withQuestion: false })

        //const replaceUrl = otherQueryString ? location.pathname + otherQueryString : location.pathname

        setTimeout(() => {
            // to macrotasks queue
            //history.replace(replaceUrl)
        }, 0)

        if (UtmString) {
            // if has current utm
            setCookie({
                name: UTM_COOKIE,
                value: UtmString,
                maxAge: utmAge,
            })
        } else if (!getUtm()) {
            // if current utm is empty && utm cookie is empty
            setCookie({
                name: UTM_COOKIE,
                value: '',
                maxAge: utmAge,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}