import React from 'react'
import './TeachersList.scss'
import TeacherItem from '../TeacherItem/TeacherItem'
import { observer } from 'mobx-react-lite'
import { store } from '@store/index'

interface TeachersListProps { }

const TeachersList: React.FC<TeachersListProps> = observer(() => {

    const teachers = store.teachersPage.teachers

    if (!teachers.length) return null

    return (
        <section className="uif-teachers-list">
            <div className="uif-container">
                <div className="uif-teachers-list__items">
                    {
                        teachers.map(t => (
                            <TeacherItem
                                firstName={t.first_name}
                                secondName={t.last_name}
                                quality={t.quality}
                                imgSrc={t.avatar}
                                id={t.id}
                                key={t.id}
                            />
                        ))
                    }
                </div>
            </div>
        </section>
    )
})

export default TeachersList