import React from 'react'
import './InstituteCities.scss'
import CountLinks from '@components/CountLinks/CountLinks'
import { сountWord } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import { store } from '@store/index'

interface InstituteCitiesProps { }

const InstituteCities: React.FC<InstituteCitiesProps> = observer(() => {

    const siteSettings = store.settings.initial?.settings || []
    const offlineFormTrainingId = siteSettings.find(s => s.hasOwnProperty('offline_id'))?.offline_id || ''

    const cities = store.institutePage.info?.cities || []

    const citiesLinks = cities
        .filter(c => c.course_count)
        .map(c => ({
            link: `/coursesCatalog?cities[]=${c.id}&form_trainings[]=${offlineFormTrainingId}`,
            title: c.name,
            key: c.id,
            countText: `${c.course_count} ${сountWord(c.course_count, 'курс')}`,
        }))
    return (
        <section className="uif-institute-cities">
            <div className="uif-container">
                <h2 className="uif-institute-cities__title">
                    ГОРОДА ПРИСУТСТВИЯ
                </h2>
                <p className="uif-institute-cities__description">
                    Очные занятия проходят в лучших фитнес-клубах городов присутствия UPWARD. <br />
                    Современное оборудование залов и грамотный подход преподавателей помогают освоить темы в полном объеме
                </p>
                <CountLinks
                    links={citiesLinks}
                />
            </div>
        </section>
    )
})

export default InstituteCities