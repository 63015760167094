import React from 'react'
import CoursePriceFeedback from './components/CoursePriceFeedback/CoursePriceFeedback'
import CoursePriceInfo from './components/CoursePriceInfo/CoursePriceInfo'
import './CoursePrice.scss'

type CoursePriceProps = {
    onSuccess?: () => void
}

const CoursePrice: React.FC<CoursePriceProps> = (props) => {
    const {
        onSuccess
    } = props

    return (
        <div className="uif-course-price">
            <div className="uif-container">
                <div className="uif-course-price__content">
                    <CoursePriceInfo />
                    <CoursePriceFeedback
                        onSuccess={onSuccess}
                    />
                </div>
            </div>
        </div>
    )
}

export default CoursePrice