import { getUtm } from '@utils/dataUtils';
import { parsePhone } from "@utils/parsers";
import axios from "axios";
import { CourseCatalogFilterResp, CoursePageInitialResp, FooterResp, InstitutePageResp, MainPageInitialResp, OptionsFeedback, PaymentOrderCreateResp, PaymentOrderGetNumberResp, PaymentOrderGood, SettingsResp, TeacherCoursesResp, TeacherResp, TeachersPageInitialResp, TimetableCatalogFilterResp } from "types/apiTypes";
import { FeedbackFormValues } from "types/formTypes";
import { courseCatalogUrl, coursePageUrl, footerUrl, institutePageUrl, mainPageUrl, paymentOrderCreateUrl, paymentOrderNumberUrl, paymentOrderStatusUrl, questionUrl, settingsUrl, teacherCoursesUrl, teachersUrl, teacherUrl, timetableCatalogUrl } from "./urls";

const defaultOptionsFeedback: OptionsFeedback = {
    pageTitle: '',
    nomenclatureСode: undefined
}

export const requestApi = {
    async feedbackSend(values: FeedbackFormValues, options = defaultOptionsFeedback) {
        const code = options.nomenclatureСode
        const requestData = {
            ...values,
            phone: parsePhone(values.phone),
            title: options.pageTitle,
            utm: getUtm(),
        } as {[key: string]: string | number}

        if (code !== undefined) requestData.code = code
        console.log('send', requestData);
        const { data } = await axios.post(questionUrl, requestData)
        // const data = {}
        return data
    }
}

class MainPageApi {
    async getAllInfo() {
        const res = await axios.get(mainPageUrl)
        return res.data as MainPageInitialResp
    }
}

class CoursePageApi {
    async getAllInfo(courseId: number) {
        const res = await axios.get(`${coursePageUrl}/${courseId}`)
        return res.data as CoursePageInitialResp
    }
}

class SettingsApi {
    async getAllSettings() {
        const {data} = await axios.get(settingsUrl)
        return data as SettingsResp
    }
}

class FooterApi {
    async getInfo() {
        const {data} = await axios.get(footerUrl)
        return data as FooterResp
    }
}

class CourseCatalogApi {
    async getAllFilters(query: string) {
        const {data} = await axios.get(`${courseCatalogUrl}${query}`)
        return data as CourseCatalogFilterResp
    }
}

class TimetableCatalogApi {
    async getAllFilters(query: string) {
        const {data} = await axios.get(`${timetableCatalogUrl}${query}`)
        return data as TimetableCatalogFilterResp
    }
}

class TeachersPageApi {
    async getAllInfo() {
        const res = await axios.get(teachersUrl)
        return res.data as TeachersPageInitialResp
    }
}

class TeacherPageApi {
    async teacherInfo(id: string) {
        const res = await axios.get(`${teacherUrl}/${+id}`)
        return res.data as TeacherResp
    }
    async courses(id: string, page: number, limit: number) {
        const res = await axios.get(`${teacherCoursesUrl}/${+id}?page=${page}&limit=${limit}`)
        return res.data as TeacherCoursesResp
    }
}

class PaymentOrderApi {
    async confirmInfo(orderId: string) {
        const res = await axios.post(paymentOrderNumberUrl, {orderId: +orderId})
        return res.data as PaymentOrderGetNumberResp
    }
    async createPay(orderId: string, goods: PaymentOrderGood[]) {
        const res = await axios.post(paymentOrderCreateUrl, {
            orderId: +orderId,
            goods
        })
        return res.data as PaymentOrderCreateResp
    }
    async getStatus(orderId: string) {
        const res = await axios.post(paymentOrderStatusUrl, {orderId})
        return res.data as PaymentOrderCreateResp
    }
}

class InstuttePageApi {
    async getInfo() {
        const res = await axios.get<InstitutePageResp>(institutePageUrl, )
        return res.data
    }
}

export const mainPageApi = new MainPageApi()
export const coursePageApi = new CoursePageApi()
export const settingsApi = new SettingsApi()
export const footerApi = new FooterApi()
export const courseCatalogApi = new CourseCatalogApi()
export const timetableCatalogApi = new TimetableCatalogApi()
export const teachersPageApi = new TeachersPageApi()
export const teacherPageApi = new TeacherPageApi()
export const paymentOrderApi = new PaymentOrderApi()
export const institutePageApi = new InstuttePageApi()