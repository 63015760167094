import { apiBaseUrl } from '@api/urls'
import ImgFade from '@components/optimization/ImgFade/ImgFade'
import UifButton from '@components/ui/buttons/UifButton/UifButton'
import { store } from '@store/index'
import { dbTimeToMoment, replaceEntersToSpan } from '@utils/parsers'
import { smoothAnchor } from '@utils/styleUtils'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { CourseTrainingStream } from 'types/apiTypes'
import './CourseCenter.scss'

type CenterProps = {
    trainingStream: CourseTrainingStream | []
}

const CourseCenter: React.FC<CenterProps> = observer((props) => {
    const {
        trainingStream
    } = props

    const course = store.coursePage.initial
    const hasProgram = !(trainingStream instanceof Array) && trainingStream && trainingStream.programs.length

    const date_from = (course?.training_stream) instanceof Array
        ? null
        : course?.training_stream.date_from

    const formTraining = (course?.form_training) instanceof Array
        ? null
        : course?.form_training

    const date_start = date_from ? dbTimeToMoment(date_from) : null

    const tagText = {
        tagFirst: formTraining?.name || '',
        tagSec: [
            date_start?.format('D MMMM') || '',
            course?.amount_of_time
        ].filter(word => word).join(' / ')
    }

    return (
        <section className="uif-course-center">
            <div className="uif-container">
                <div className="uif-course-center__body">

                    <div className="uif-course-center__info-block">
                        <div className="uif-course-center__text-block">

                            <div className="uif-course-center__tags-line">
                                {
                                    tagText.tagFirst &&
                                    <p className="uif-course-center__tag">
                                        {tagText.tagFirst}
                                    </p>
                                }
                                {
                                    tagText.tagSec &&
                                    <p className="uif-course-center__tag">
                                        {tagText.tagSec}
                                    </p>
                                }
                            </div>

                            <p className="uif-course-center__title">
                                {replaceEntersToSpan(course?.name || '', 'uif-span-devider')}
                            </p>

                            <p className="uif-course-center__description">
                                {replaceEntersToSpan(course?.description || '', 'uif-span-devider')}
                            </p>
                        </div>

                        <div className="uif-course-center__controls">
                            {/* <button className="uif-course-center__control uif-btn uif-btn-dark active">
                                Записаться
                            </button> */}
                            {
                                hasProgram &&
                                <UifButton
                                    styleType="outline"
                                    className="uif-course-center__control"
                                    onClick={() => smoothAnchor('#training-program')}
                                >
                                    Смотреть программу
                                </UifButton>
                            }
                        </div>
                    </div>

                    <div className="uif-course-center__img-block">
                        <div className="uif-course-center__img-wrapper">
                            <ImgFade
                                src={`${apiBaseUrl}${course?.preview}`}
                                alt="course-preview"
                            />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
})

export default CourseCenter