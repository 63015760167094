import { action, makeAutoObservable } from "mobx"


class NavbarStore {
    defaultShowLogo: boolean = true
    rowClasses: string[] = []

    constructor() {
        makeAutoObservable(
            this,
            {
                setDefaultShowLogo: action.bound,
            }
        )
    }

    setDefaultShowLogo = (val: boolean) => {
        this.defaultShowLogo = val
    }

    setRowClasses = (classes: string[]) => {
        this.rowClasses = [...classes]
    }
}

export const navbarStore = new NavbarStore()