import { PaymentOrderConfirmFormFields } from 'types/formTypes'

export const validatePaymentOrderConfirm = (values: PaymentOrderConfirmFormFields) => {
    const errors = {} as ValidateFields
    if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Некорректный email адрес';
    }
    if (!values.agreement) errors.agreement = "Необходимо принять условия оплаты"
    return errors
}

export const initialPaymentOrderConfirm: PaymentOrderConfirmFormFields = {
    email: '',
    agreement: true,
}

export const clearErrorsPaymentOrderConfirm = {
    email: '',
    agreement: '',
}

type ValidateFields = {
    email?: string
    agreement?: string
}