import React from 'react';
import './MainVideo.scss';

export function MainVideo(){

    return(<div className="main-video">
        <div className="main-video-container">
            {/* @ts-ignore */}
            <video autoPlay loop muted playsinline src="/static/files/video.mp4"/>
        </div>
    </div>)
}