import React from 'react'
import './TimetableTitle.scss'
// import InputSwitch from '@components/form/InputSwitch/InputSwitch'

type TimetableTitleProps = {

}

const TimetableTitle: React.FC<TimetableTitleProps> = () => {

    return (
        <section className="uif-timetable-title">
            <div className="uif-container uif-timetable-title__container">
                <div className="uif-timetable-title__body">
                    <h2
                        className="uif-timetable-title__title"
                    >
                        расписание
                    </h2>
                    {/* <p className="uif-timetable-title__description">
                        Время указано 
                        <span className="uif-timetable-title__description-devider"/>
                        по Екатеринбургу (МСК+2ч)
                    </p>
                    <div className="uif-timetable-title__my-time">
                        <InputSwitch
                            wrapClasses={['uif-timetable-title__switch']}
                            onChange={() => console.log('my rasp')}
                        />
                        <p className="uif-timetable-title__my-time-text">Мое расписание</p>
                    </div> */}
                </div>
            </div>
        </section>
    )
}

export default TimetableTitle