import React from 'react'
import './TeachersLights.scss'

interface TeachersLightsProps {}

const TeachersLights: React.FC<TeachersLightsProps> = () => {

    return (
        <div className="uif-teachers-lights">
            <div className="uif-container uif-teachers-lights__container">
                <div className="uif-teachers-lights__wrapper">
                    <img
                        src="/static/img/parallax-lights.png"
                        alt="."
                        className="uif-teachers-lights__img"
                    />
                </div>
            </div>
        </div>
    )
}

export default TeachersLights