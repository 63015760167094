import React from 'react'
import './CountLinks.scss'
import { Link } from 'react-router-dom'
import { clickWithScroll } from '@utils/styleUtils'

interface CountLinksProps {
    links: {
        title: string
        countText: string
        link: string
        key?: string | number
    }[]
    className?: string
}

const CountLinks: React.FC<CountLinksProps> = ({links, className}) => {

    const listClassNames = className ? `uif-count-links ${className}` : "uif-count-links"

    return (
        <ul className={listClassNames}>
            {
                links.map((c, idx) => (
                    <li className="uif-count-links__link-wrapper" key={c.key || idx}>
                        <Link
                            to={c.link}
                            className="uif-count-links__link"
                            onClick={clickWithScroll}
                        >
                            <span className="uif-count-links__link-name">
                                {c.title}
                            </span>
                            <span className="uif-count-links__link-count">
                                {c.countText}
                            </span>
                        </Link>
                    </li>
                ))
            }
        </ul>
    )
}


export default CountLinks




