import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import './InstitutePage.scss'
import InstituteLights from './components/InstituteLights/InstituteLights'
import InstituteCourses from './components/InstituteCourses/InstituteCourses'
import InstituteResultCount from './components/InstituteResultCount/InstituteResultCount'
import InstituteCities from './components/InstituteCities/InstituteCities'
import InstituteOnline from './components/InstituteOnline/InstituteOnline'
import InstituteDocuments from './components/InstituteDocuments/InstituteDocuments'
import InstitutePartners from './components/InstitutePartners/InstitutePartners'
import InstituteLicense from './components/InstituteLicense/InstituteLicense'
import InstituteLegal from './components/InstituteLegal/InstituteLegal'
import Warranty from '@components/commonBlocks/Warranty/Warranty'
import { institutePageApi } from '@api/requestFunctions'
import { InstitutePageResp } from 'types/apiTypes'
import { store } from '@store/index'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import QuestionFormBlock from '@components/commonBlocks/QuestionFormBlock/QuestionFormBlock'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'

interface InstitutePageProps { }

const InstitutePage: React.FC<InstitutePageProps> = observer(() => {

    const [show, setShow] = useState<boolean>(false)

    useQuery<InstitutePageResp, Error, InstitutePageResp>({
        queryKey: 'about-page-init',
        queryFn: institutePageApi.getInfo,
        retry: 1,
        refetchOnWindowFocus: false,
        onSuccess(data) {
            store.institutePage.updateInfo(data)
        },
        onError(e: Error) {
            console.log('Institute page info error', e)
        }
    })

    useNavbarSettings(store)

    return (
        <>
            <InstituteLights />
            <InstituteCourses />
            <InstituteResultCount />
            <InstituteCities />
            <InstituteOnline />
            <InstituteDocuments />
            <Warranty
                classNames="institute-warranty"
                text={`Лучшие выпускники получают предложение о трудоустройстве 
                от фитнес-клубов партнеров 
                UPWARD INSTITUTE OF FITNESS`}
                withEnters
            />
            <InstitutePartners />
            <InstituteLicense />
            <InstituteLegal />

            <QuestionFormBlock
                titlePage="Страница 'Об институте'"
                wrapClasses={'institute-page'}
                onSuccess={() => setShow(true)}
            />
            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={show}
                onClose={() => setShow(false)}
            />
        </>
    )
})

export default InstitutePage