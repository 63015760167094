import { apiBaseUrl } from '@api/urls'
import ImgFade from '@components/optimization/ImgFade/ImgFade'
import { store } from '@store/index'
import { replaceEntersToSpan } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './TeacherInfo.scss'

interface TeacherInfoProps { }

const TeacherInfo: React.FC<TeacherInfoProps> = observer(() => {

    const teacher = store.teacherPage.info

    if (!teacher) return null

    return (
        <section className="uif-teacher-info">
            <div className="uif-container uif-teacher-info__container">
                <div className="uif-teacher-info__img-block">
                    <ImgFade
                        src={apiBaseUrl + teacher.avatar}
                        alt="Преподаватель"
                        className="uif-teacher-info__img"
                    />
                </div>

                <div className="uif-teacher-info__text-block">
                    <div className="uif-teacher-info__preview-text-block">
                        <h2 className="uif-teacher-info__name">
                            {teacher.first_name} <br /> {teacher.last_name}
                        </h2>
                        <p className="uif-teacher-info__quality">
                            {teacher.quality}
                        </p>
                    </div>

                    <div className="uif-teacher-info__story-text-block">
                        {
                            teacher.experience &&
                            <div className="uif-teacher-info__story-item">
                                <h5 className="uif-teacher-info__story-title">
                                    Опыт работы
                                </h5>
                                {
                                    replaceEntersToSpan(teacher.experience, 'uif-span-devider uif-teacher-info__story-text big-devide')
                                }
                            </div>
                        }
                        {
                            teacher.education &&
                            <div className="uif-teacher-info__story-item">
                                <h5 className="uif-teacher-info__story-title">
                                    Образование
                                </h5>
                                {
                                    replaceEntersToSpan(teacher.education, 'uif-span-devider uif-teacher-info__story-text education-devide')
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
})

export default TeacherInfo