import { FeedbackFormErrors, FeedbackFormValues } from "types/formTypes";
import { parsePhone } from "./parsers";

export const feedbackValidate = (values: FeedbackFormValues) => {
    const errors: FeedbackFormErrors = {}
    if (!values.name) errors.name = 'Укажите имя'
    if (parsePhone(values.phone).length < 11) errors.phone = 'Введите номер телефона'
    if (!values.email) {
        errors.email = 'Введите Email';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Некорректный email адрес';
    }
    return errors
}