import React from 'react'
import './MainOnline.scss'
import 'swiper/components/effect-flip/effect-flip.scss';
import OnlineSlider from '@components/sliders/OnlineSlider/OnlineSlider'
import { clickWithScroll } from '@utils/styleUtils';
import MainOnlineTitle from './components/MainOnlineTitle/MainOnlineTitle';
import UifLinkBtn from '@components/ui/buttons/UifLinkBtn/UifLinkBtn';

type OnlineProps = {}

const MainOnline: React.FC<OnlineProps> = () => {

    return (
        <section className="uif-main-online">
            <div className="uif-container">
                <MainOnlineTitle />
                <span className="uif-main-online__placeholder">online</span>
                <OnlineSlider />
                <UifLinkBtn
                    styleType="dark"
                    href="/timeTable"
                    className="uif-main-online__rasp-btn"
                    onClick={clickWithScroll}
                >
                    Расписание
                </UifLinkBtn>
            </div>
        </section>
    )
}

export default MainOnline