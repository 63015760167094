export type LinkNavbar = {
    name: string
    path: string
    special?: boolean
    tagA?: boolean
    newTab?: boolean
    id: string
}

export const navLinks = [
    {
        name: 'Курсы',
        path: '/coursesCatalog',
        id: 'courses-link',
    },
    {
        name: 'Расписание',
        path: '/timeTable',
        id: 'timetable-link',
    },
    {
        name: 'Об институте',
        path: '/institute',
        id: 'aboutInst-link',
    },
    {
        name: 'Преподаватели',
        path: '/teachers',
        id: 'teachers-link',
    },
    {
        name: 'Контакты',
        path: '/contacts',
        id: 'contacts-link',
    },
    {
        name: 'Стать партнером',
        path: 'https://partners.upwardfi.ru',
        tagA: true,
        newTab: true,
        id: 'partners-link',
    },
    {
        name: 'Оплата',
        path: '/paymentOrder',
        id: 'payment-link',
    },
    // {
    //     name: 'Записаться',
    //     path: '#',
    //     special: true,
    // }
] as LinkNavbar[]