type SetCookieParams = {
    name: string
    value?: string
    maxAge?: number,
    sessionCookie?: boolean
}

export function getCookie(name: string): string | null {
    let result: string | null = null
    const cookie = document.cookie
    const cookieProp = cookie.split('; ')
    cookieProp.forEach(cookiePair => {
        const equalIdx = cookiePair.indexOf('=')
        const key = cookiePair.slice(0, equalIdx)
        const value = cookiePair.slice(equalIdx + 1)
        if (key === name) {
            result = value
        }
    })
    return result
}

export function setCookie({
    name,
    value = '',
    maxAge = 86398,
    sessionCookie = false,
}: SetCookieParams): void {
    if (sessionCookie) {
        document.cookie = `${name}=${value}; path=/`
    } else {
        document.cookie = `${name}=${value}; max-age=${maxAge}; path=/`
    }
}

export function deleteCookie(name: string): void {
    document.cookie = `${name}=; path=/; expires=0; max-age=0`
}