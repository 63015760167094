import React from 'react'
import PaymentOrderCheck from './components/PaymentOrderCheck/PaymentOrderCheck'
import PaymentOrderResult from './components/PaymentOrderResult/PaymentOrderResult'
import PaymentOrderConfirm from './components/PaymentOrderConfirm/PaymentOrderConfirm'
import { RouteComponentProps } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from '@store/index'
import { useEffect } from 'react'
import { PaymentOrderPlaceholder } from './components/PaymentOrderPlaceholder/PaymentOrderPlaceholder'


interface PaymentOrderPageProps extends RouteComponentProps { }

type Queries = {
    ord: null | string
    orderId: null | string
}

const PaymentOrderPage: React.FC<PaymentOrderPageProps> = observer((props) => {

    const { history } = props

    const storePayment = store.paymentOrder

    const orderNumber = storePayment.orderNumber
    const orderId = storePayment.orderId
    const foundOrder = storePayment.foundOrder
    const show = storePayment.show

    useEffect(() => {
        const queries: Queries = {
            ord: null,
            orderId: null,
        }
        const search = history.location.search.slice(1)
        search.split('&').forEach(pair => {
            const query = pair.split('=')
            const name = query[0] as keyof Queries
            const value = query[1]
            if (queries.hasOwnProperty(name) && value) {
                queries[name] = value
            }
        })
        storePayment.setOrderNumber(queries.ord || null)
        storePayment.setOrderId(queries.orderId || null)
        storePayment.setShow(true)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history.location.search])


    if (!show) return <PaymentOrderPlaceholder />

    if (orderId) return <PaymentOrderResult />

    if (orderNumber && foundOrder !== false) return <PaymentOrderConfirm />

    return <PaymentOrderCheck />
})

export default PaymentOrderPage