import React, { InputHTMLAttributes } from 'react'
import './InputRadioText.scss'

interface InputRadioTextProps extends InputHTMLAttributes<HTMLInputElement> {
    title: string
    wrapClasses?: string[]
}

const InputRadioText: React.FC<InputRadioTextProps> = (props) => {

    const {
        wrapClasses, title,
        ...inputProps
    } = props

    const labelClasses = () => {
        let classes = ['uif-input-radio-text']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    return (
        <label className={labelClasses()}>
            <input
                type="radio"
                name="courses-catalog-category"
                className="uif-input-radio-text__input"
                {...inputProps}
            />
            <span className="uif-input-radio-text__text">
                {title}
            </span>
        </label>
    )
}

export default InputRadioText