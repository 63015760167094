import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import CourseCatalogCards from '../CourseCatalogCards/CourseCatalogCards'
import CourseCatalogFilter from '../CourseCatalogFilter/CourseCatalogFilter'
import CoursesCatalogCategories from '../CoursesCatalogCategories/CoursesCatalogCategories'
import { useFormik } from 'formik'
import { CatalogCoursesFilterFields } from 'types/formTypes'
import { useMutation } from 'react-query'
import { courseCatalogApi } from '@api/requestFunctions'
import { CourseCatalogFilterResp } from 'types/apiTypes'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import CourseCatalogFilterModal from '@components/modals/CourseCatalogFilterModal/CourseCatalogFilterModal'
import UifButton from '@components/ui/buttons/UifButton/UifButton'
import { isEqualObjJSON } from '@utils/computed'


interface CourseCatalogFilterControllerProps extends RouteComponentProps {}

const allowedQueryParams = ['cities', 'goals', 'levels', 'group_direction', 'form_trainings', 'durations']
const limitItems = 12

const CourseCatalogFilterController: React.FC<CourseCatalogFilterControllerProps> = observer((props) => {

    const {
        history,
        location
    } = props

    const filterData = store.courseCatalogFilters.initial?.filters
    const courses = store.courseCatalogFilters.courses

    const [maxPage, setMaxPage] = useState<number>(1)
    const [page, setPage] = useState<number>(1)

    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 })
    const [showFilterModal, setShowFilterModal] = useState<boolean>(false)

    const initialCourseCatalogFilter = (): CatalogCoursesFilterFields => {
        const formValues: CatalogCoursesFilterFields = {
            cities: [],
            goals: [],
            levels: [],
            form_trainings: [],
            durations: [],
            group_direction: '',
        }

        const queries = location.search.replace('?', '').split('&')
        const entries = queries.map(q => q.split('='))

        entries.forEach(([queryField, queryValue]) => {
            const fieldName = queryField.replace('[]', '') as keyof CatalogCoursesFilterFields;
            if (allowedQueryParams.includes(fieldName)) {
                if (queryValue === '') return null

                if (Array.isArray(formValues[fieldName])) {
                    formValues[fieldName] = (formValues[fieldName]
                    ? formValues[fieldName].concat(queryValue)
                    : [queryValue]) as any
                }
                if (typeof formValues[fieldName] === 'string') {
                    formValues[fieldName] = queryValue as any
                }
            }
        })
        return formValues
    }

    const filterFormik = useFormik<CatalogCoursesFilterFields>({
        initialValues: initialCourseCatalogFilter(),
        validate: () => { },
        onSubmit: () => { },
    })

    const [beforeOpenModalValues, setBeforeOpenModalValues] = useState(filterFormik.values)

    const mutationFilter = useMutation<CourseCatalogFilterResp, Error, string>(
        'mutate-get-course-catalog-filters',
        courseCatalogApi.getAllFilters,
        {
            onSuccess(data) {
                console.log('MUTATE resp', data)
                store.courseCatalogFilters.updateInit(data)

                if (data.pagination.maxPage) {
                    store.courseCatalogFilters.addCourses(data.courses, +data.pagination.thisPage)
                } else {
                    store.courseCatalogFilters.removeAllCourses()
                }
                setMaxPage(+data.pagination.maxPage)
            },
            onError(err) {
                console.log('MUTATE ERROR', err);
            }
        }
    )

    const updateUrlAndFetch = () => {
        const fields = Object.keys(filterFormik.values) as (keyof CatalogCoursesFilterFields)[]
        const queryAll = fields
            .filter(fieldName => {
                const value = filterFormik.values[fieldName]
                if (Array.isArray(value)) return value.length
                return value
            })
            .map(fieldName => {
            const fieldValue = filterFormik.values[fieldName]
            if (fieldValue instanceof Array) {
                return fieldValue.map(val => `${fieldName}[]=${val}`).join('&')
            }
            return `${fieldName}=${fieldValue}`
        })
        const queryString = queryAll.filter(query => query).join('&')

        history.replace(`${location.pathname}?${queryString}`)
        setPage(1)
    }

    const handleDenyModal = useCallback((e?: React.MouseEvent) => {
        filterFormik.setValues({ ...beforeOpenModalValues })
        toggleFilterModal(e)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFilterModal])

    const handleApplyModal = (e?: React.MouseEvent) => {
        toggleFilterModal(e)
        updateUrlAndFetch()
    }

    const toggleFilterModal = (e?: React.MouseEvent) => {
        if (e) {
            const x = e.clientX
            const y = e.clientY
            setClickPosition({ x, y })
        }
        if (!showFilterModal) {
            setBeforeOpenModalValues(filterFormik.values)
            return setShowFilterModal(true)
        }
        setShowFilterModal(false)
    }

    const handleInfiniteFetch = useCallback(() => {
        if (maxPage > page) return setPage(page + 1)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxPage, page])

    useEffect(() => {
        if (window.innerWidth >= 768) updateUrlAndFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterFormik.values])

    useEffect(() => {
        const query = location.search
            ? `${location.search}&page=${page}&limit=${limitItems}`
            : `?page=${page}&limit=${limitItems}`
        console.log('FETCH search', query)
        const fieldByQuery = initialCourseCatalogFilter()
        if (!isEqualObjJSON(fieldByQuery, filterFormik.values)) {
            filterFormik.setValues(fieldByQuery)
        }
        mutationFilter.mutate(query)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search, page])

    return (
        <>
            <CoursesCatalogCategories
                directionGroups={filterData?.group_directions || []}
                checked={filterFormik.values.group_direction}
                setFieldValue={filterFormik.setFieldValue}
                breakpoints={{
                    minWidth: 768
                }}
            />

            <section className="uif-courses-catalog">
                <div className="uif-container">
                    <UifButton
                        styleType="outline"
                        className="uif-courses-catalog__filter-btn"
                        onClick={toggleFilterModal}
                    >
                        Фильтры
                    </UifButton>
                    <div className="uif-courses-catalog__content">
                        <CourseCatalogFilter
                            directionGroups={filterData?.group_directions || []}

                            values={filterFormik.values}
                            filters={filterData}
                            setFieldValue={filterFormik.setFieldValue}
                            breakpoints={{
                                minWidth: 768
                            }}
                        />
                        <CourseCatalogCards
                            page={page}
                            maxPage={maxPage}
                            courses={courses}
                            isFetching={mutationFilter.isLoading}
                            isInitializedFilter={!!filterData}
                            onFetch={handleInfiniteFetch}
                        />
                    </div>
                </div>
            </section>

            <CourseCatalogFilterModal
                directionGroups={filterData?.group_directions || []}
                checkedRadio={filterFormik.values.group_direction}
                setFieldValue={filterFormik.setFieldValue}
                filters={filterData}
                chosenCheckboxes={filterFormik.values}

                isShow={showFilterModal}
                clickX={clickPosition.x}
                clickY={clickPosition.y}
                onDeny={handleDenyModal}
                onApply={handleApplyModal}
                breakpoints={{
                    maxWidth: 767
                }}
            />
        </>
    )
})

export default withRouter(CourseCatalogFilterController)