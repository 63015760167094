import React from 'react'
import PaymentOrderConfirmHead from './components/PaymentOrderConfirmHead/PaymentOrderConfirmHead'
import PaymentOrderConfirmGoods from './components/PaymentOrderConfirmGoods/PaymentOrderConfirmGoods'
import PaymentOrderConfirmForm from './components/PaymentOrderConfirmForm/PaymentOrderConfirmForm'
import PaymentOrderConfirmBanks from './components/PaymentOrderConfirmBanks/PaymentOrderConfirmBanks'
import { useQuery } from 'react-query'
import { paymentOrderApi } from '@api/requestFunctions'
import { PaymentOrderGetNumberResp } from 'types/apiTypes'
import { observer } from 'mobx-react-lite'
import { store } from '@store/index'
import { PaymentOrderPlaceholder } from '../PaymentOrderPlaceholder/PaymentOrderPlaceholder'

interface PaymentOrderConfirmProps {}

const PaymentOrderConfirm: React.FC<PaymentOrderConfirmProps> = observer(() => {

    const orderNumber = store.paymentOrder.orderNumber
    const confirmInfo = store.paymentOrder.confirmInfo

    useQuery<PaymentOrderGetNumberResp, Error>({
        queryKey: 'payment-order-get-order-number',
        queryFn: async () => paymentOrderApi.confirmInfo(orderNumber || ''),
        refetchOnWindowFocus: false,
        retry: 1,
        onSuccess(data) {
            if (!data.error) {
                store.paymentOrder.setConfirmInfo(data)
            }
            console.log('Success', data);
        },
        onError(e) {
            console.log('Error', e);
        },
    })

    if (!orderNumber || !confirmInfo) return <PaymentOrderPlaceholder />

    return (
        <>
            <PaymentOrderConfirmHead />
            <PaymentOrderConfirmGoods />
            <PaymentOrderConfirmForm />
            <PaymentOrderConfirmBanks />
        </>
    )
})

export default PaymentOrderConfirm