import React from 'react'
import './PaginateMorePlaceholder.scss'

type PaginateMorePlaceholderProps = {
    show: boolean
}

const PaginateMorePlaceholder: React.FC<PaginateMorePlaceholderProps> = (props) => {

    const {
        show
    } = props

    if (!show) return null

    return (
        <p className="uif-paginate-more-placeholder">➔</p>
    )
}

export default PaginateMorePlaceholder