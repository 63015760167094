import React from 'react'
import './ContactsData.scss'

interface ContactsDataProps { }

const ContactsData: React.FC<ContactsDataProps> = () => {

    return (
        <section className="uif-contacts-data">
            <div className="uif-container">
                <div className="uif-contacts-data__layout">

                    <div className="uif-contacts-data__info-block">

                        <div className="uif-contacts-data__contact-item">
                            <h4 className="uif-contacts-data__contact-title">
                                Адрес
                            </h4>
                            <p className="uif-contacts-data__contact-info">
                                620014, г. Екатеринбург, ул. Чернышевского, дом 7, Бизнес центр Дубровин, оф.428
                            </p>
                        </div>

                        <div className="uif-contacts-data__contact-item">
                            <h4 className="uif-contacts-data__contact-title">
                                Режим работы
                            </h4>
                            <p className="uif-contacts-data__contact-info">
                                Приемные часы для Обучающихся: <br />
                                ПН - ПТ с 11:00 до 16:00 по Екб(+2 мск)
                            </p>
                        </div>

                        <div className="uif-contacts-data__contact-item">
                            <h4 className="uif-contacts-data__contact-title">
                                Телефон
                            </h4>
                            <p className="uif-contacts-data__contact-info">
                                +7 (343) 311-03-01
                            </p>
                        </div>

                        <div className="uif-contacts-data__contact-item">
                            <h4 className="uif-contacts-data__contact-title">
                                E-mail
                            </h4>
                            <p className="uif-contacts-data__contact-info">
                                sales@uif66.ru
                            </p>
                        </div>

                        <div className="uif-contacts-data__social-block">
                            <a
                                href="https://vk.com/upward.university"
                                className="uif-contacts-data__social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    src="/static/img/vk-icon.svg"
                                    alt="VK"
                                    className="uif-contacts-data__social-icon"
                                />
                            </a>
                            <a
                                href="https://t.me/upward_career"
                                className="uif-contacts-data__social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    src="/static/img/telegram.svg"
                                    alt="Telegram"
                                    className="uif-contacts-data__social-icon"
                                    style={{width: 27, height: 27}}
                                />
                            </a>
                            <a
                                href="https://wa.me/79993403300"
                                className="uif-contacts-data__social-link"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <img
                                    src="/static/img/wa.svg"
                                    alt="Whatsapp"
                                    className="uif-contacts-data__social-icon"
                                    style={{width: 27, height: 27, marginLeft: 10}}
                                />
                            </a>
                        </div>
                    </div>

                    <div className="uif-contacts-data__map-block">
                        <div className="uif-contacts-data__map">
                            <iframe
                                src="https://yandex.ru/map-widget/v1/?um=constructor%3Adbd7f44eee1e59c1def1f6c42d1bcdaf2ab819ec91baccd6cee3296410f2fcdc&amp;source=constructor" width="100%"
                                height="620"
                                frameBorder="0"
                                className="uif-contacts-data__map-frame"
                                title="map"
                            >
                            </iframe>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}


export default ContactsData