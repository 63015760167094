import { replaceEntersToSpan } from '@utils/parsers'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import './Collapse.scss'

type CollapseProps = {
    title: string
    collapseText: string
    animationDuration?: number
    wrapClasses?: string[]
}

const Collapse: React.FC<CollapseProps> = React.memo((props) => {
    const {
        title, collapseText, animationDuration = 300,
        wrapClasses,
    } = props

    const closedStyles = useMemo(() => (
        {
            height: 0,
            opacity: 0,
            transition: `all ${animationDuration}ms ease`
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [])

    const [isShow, setShow] = useState(false)
    const [collapseBodyStyle, setCollapseBodyStyle] = useState<React.CSSProperties>(closedStyles)
    const collapseTextRef = useRef<HTMLParagraphElement>(null)

    const collapseClasses = () => {
        let classes = ['uif-collapse']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        if (isShow) classes.push('show')
        return classes.join(' ')
    }

    const toggleCollapse = () => {
        if (isShow) return hideCollapse()
        showCollapse()
    }

    const showCollapse = () => {
        setShow(true)
    }

    const hideCollapse = () => {
        setShow(false)
        setCollapseBodyStyle(closedStyles)
    }

    useEffect(() => {
        if (isShow && collapseTextRef.current) {
            const heightCollapsedText = collapseTextRef.current.clientHeight
            setCollapseBodyStyle(prev => ({
                ...prev,
                height: heightCollapsedText,
                opacity: 1
            }))
        }
    }, [isShow])

    return (
        <div className={collapseClasses()}>
            <div className="uif-collapse__head" onClick={toggleCollapse}>
                <p className="uif-collapse__title">
                    {replaceEntersToSpan(title, 'uif-span-devider')}
                </p>
                <button className="uif-collapse__arrow-btn" onClick={toggleCollapse}>
                    <img
                        src="/static/img/ui/cross.svg"
                        alt="->"
                        className="uif-collapse__arrow"
                    />
                    <img
                        src="/static/img/ui/cross-hover.svg"
                        alt="->"
                        className="uif-collapse__hover-arrow"
                    />
                </button>
            </div>
            <div
                className="uif-collapse__collapse-body"
                style={collapseBodyStyle}
            >
                <p
                    ref={collapseTextRef}
                    className="uif-collapse__collapse-text"
                >
                    {replaceEntersToSpan(collapseText, 'uif-span-devider')}
                </p>
            </div>
        </div>
    )
})

export default Collapse