import { apiBaseUrl } from '@api/urls'
import { store } from '@store/index'
import { replaceEntersToSpan, salarySpace } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './CourseResults.scss'

type CourseResultsProps = {}

const CourseResults: React.FC<CourseResultsProps> = observer(() => {

    const course = store.coursePage.initial
    const hasSkills = !!course?.skills?.length

    const topInfoClasses = () => {
        let classes = ['uif-course-results__top-info']
        if (!hasSkills) classes.push('border-none')
        return classes.join(' ')
    }

    return (
        <div className="uif-course-results">
            <div className="uif-container uif-course-results__container">
                <p className="uif-course-results__title">
                    <span className="uif-course-results__title-word">
                        Итоги
                    </span>
                    <span className="uif-course-results__title-word">
                        обучения
                    </span>
                </p>
                {
                    course?.training_result &&
                    <p className="uif-course-results__description">
                        {replaceEntersToSpan(course.training_result, 'uif-span-devider')}
                    </p>
                }
                <img src="/static/img/parallax-lights.png" alt="." className="uif-course-results__lights" />
                <div className="uif-course-results__body">
                    <div className="uif-course-results__course-info-block">
                        <div
                            className={topInfoClasses()}
                        >
                            <div className="uif-course-results__teacher-block">
                                <div className="uif-course-results__teacher-img">
                                    <img src="/static/img/logo.svg" alt="logo" />
                                </div>
                                <div className="uif-course-results__teacher-text-block">
                                    <div className="uif-course-results__teacher-title">
                                        {
                                            replaceEntersToSpan(course?.name || '', 'uif-span-devider')
                                        }
                                    </div>
                                    {
                                        course?.position &&
                                        <div className="uif-course-results__teacher-grade">
                                            {course.position}
                                        </div>
                                    }
                                </div>
                            </div>
                            {
                                !(course?.salary instanceof Array) && course?.salary &&
                                <div className="uif-course-results__salary-block">
                                    <p className="uif-course-results__salary">
                                        {`${salarySpace(course?.salary?.salary || '')} ₽`}
                                    </p>
                                    <p className="uif-course-results__salary-description">Зарплата от</p>
                                </div>
                            }
                        </div>

                        {
                            hasSkills &&
                            <div className="uif-course-results__skills-block">
                                <div className="uif-course-results__skills-title">Навыки</div>
                                <div className="uif-course-results__skills-list">
                                    {
                                        course?.skills.map((skill, i) => (
                                            <p className="uif-course-results__skill-item" key={i}>
                                                {replaceEntersToSpan(skill.name, 'uif-span-devider')}
                                            </p>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>

                    <div className="uif-course-results__certificate-block">
                        <div className="uif-course-results__certificate-text-block">
                            {
                                course?.document_name &&
                                <p className="uif-course-results__certificate-title">
                                    {course?.document_name}
                                </p>
                            }
                            {
                                course?.document_description &&
                                <p className="uif-course-results__certificate-description">
                                    {replaceEntersToSpan(course?.document_description || '', 'uif-span-devider')}
                                </p>
                            }
                        </div>
                        <div className="uif-course-results__certificate-img">
                            <img
                                src={apiBaseUrl + course?.document_preview}
                                alt="certificate"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default CourseResults