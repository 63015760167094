import { LoadingOutlined } from '@ant-design/icons'
import { apiBaseUrl } from '@api/urls'
import ImgFade from '@components/optimization/ImgFade/ImgFade'
import { dbTimeToMoment } from '@utils/parsers'
import { clickWithScroll } from '@utils/styleUtils'
import { Spin } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import './CourseCatalogCard.scss'

type CourseCatalogCardProps = {
    title: string
    courseId: number
    bgImage: string
    date?: string
    formTraining?: string
}

const CourseCatalogCard: React.FC<CourseCatalogCardProps> = React.memo((props) => {
    const {
        title, date, bgImage,
        formTraining, courseId
    } = props

    const date_start = dbTimeToMoment(date || '')?.format('D MMMM')

    return (
        <div className="uif-courses-card">
            {
                bgImage &&
                <ImgFade
                    src={`${apiBaseUrl}${bgImage}`}
                    className="uif-courses-card__bg-img"
                    alt="."
                />
            }
            <Link
                to={`/course/${courseId}`}
                className="uif-courses-card__content"
                onClick={clickWithScroll}
            >
                <p className="uif-courses-card__name">
                    {title}
                </p>
                {
                    date &&
                    <p className="uif-courses-card__date">
                        {date_start}
                    </p>
                }
                {
                    formTraining &&
                    <p className="uif-courses-card__keywords">
                        {formTraining}
                    </p>
                }
            </Link>
            <div className="uif-courses-card__loader">
                <Spin indicator={<LoadingOutlined spin className="icon" />} />
            </div>
        </div>
    )
})

export default CourseCatalogCard