import { action, makeAutoObservable } from "mobx"
import { MainPageInitialResp } from "types/apiTypes"

export class MainPageState {
    initial: MainPageInitialResp | null = null
    readyAnimate: boolean = false

    constructor() {
        makeAutoObservable(
            this,
            {
                updateInit: action.bound,
                statusAnimate: action.bound,
            }
        )
    }

    statusAnimate = (val: boolean) => {
        this.readyAnimate = val
    }

    updateInit = (newData: MainPageInitialResp) => {
        this.initial = newData
    }
}

export const mainPageState = new MainPageState()