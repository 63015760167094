import React, { useRef, useState } from 'react'
import './InputFloatLabel.scss'

interface InputFloatLabelProps extends React.InputHTMLAttributes<HTMLInputElement> {
    wrapClasses?: string[]
    error?: string
    isError?: boolean
    onFocus?: (e?: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (e?: React.FocusEvent<HTMLInputElement>) => void
}

const InputFloatLabel: React.FC<InputFloatLabelProps> = (props) => {
    const {
        placeholder, error, wrapClasses,
        isError,
        onFocus, onBlur,
        ...otherProps
    } = props

    const [focused, setFocused] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const inputClasses = () => {
        let classes = ['uif-input']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        if (error || isError) classes.push('error')
        if (focused || inputRef.current?.value || otherProps.value) classes.push('floating')

        return classes.join(' ')
    }

    const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
        if (onFocus) onFocus(e)
        setFocused(true)
    }

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) onBlur(e)
        setFocused(false)
    }

    return (
        <div className={inputClasses()}>
            <div className="uif-input__input-container">
                <input
                    ref={inputRef}
                    className="uif-input__input"
                    {...otherProps}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
                {
                    placeholder &&
                    <span className="uif-input__placeholder">
                        {placeholder}
                    </span>
                }
            </div>
            {
                error &&
                <p className="uif-input__error-message">
                    {error}
                </p>
            }
        </div>
    )
}

export default InputFloatLabel