import React from 'react'
import './PaymentOrderConfirmGoods.scss'
import { store } from '@store/index'
import { PaymentOrderGood } from 'types/apiTypes'

interface PaymentOrderConfirmGoodsProps { }

const PaymentOrderConfirmGoods: React.FC<PaymentOrderConfirmGoodsProps> = (props) => {

    const goods = store.paymentOrder.confirmInfo?.order.goods

    const goodPrice = (good: PaymentOrderGood) => {
        return parseFloat((good.count * good.unitPrice).toFixed(2))
    }

    const totalPrice = () => {
        if (!goods) return ''
        let total = 0
        goods.forEach(g => {
            total += goodPrice(g)
        })
        return total
    }

    return (
        <div className="uif-payment-order-confirm-goods">
            <div className="uif-container">
                <div className="uif-payment-order-confirm-goods__content-wrapper">
                    <h2 className="uif-payment-order-confirm-goods__title">
                        Твой заказ
                    </h2>
                    {
                        goods &&
                        <ul>
                            {
                                goods.map(g => (
                                    <li
                                        className="uif-payment-order-confirm-goods__item"
                                        key={g.goodId}
                                    >
                                        <p className="uif-payment-order-confirm-goods__item-name">
                                            {g.name}
                                        </p>
                                        <p className="uif-payment-order-confirm-goods__item-price">
                                        {goodPrice(g).toLocaleString('ru')} ₽
                                        </p>
                                    </li>
                                ))
                            }
                            <li className="uif-payment-order-confirm-goods__total-item">
                                <p className="uif-payment-order-confirm-goods__total-text">
                                    Итого
                                </p>
                                <p className="uif-payment-order-confirm-goods__total-price">
                                    {totalPrice().toLocaleString('ru')} ₽
                                </p>
                            </li>
                        </ul>
                    }
                </div>
            </div>
        </div>
    )
}

export default PaymentOrderConfirmGoods