import React from 'react'
import './InstituteResultCount.scss'

interface InstituteResultCountProps { }

const InstituteResultCount: React.FC<InstituteResultCountProps> = () => {

    return (
        <section className="uif-instutute-res-count">
            <div className="uif-container">
                <ul className="uif-instutute-res-count__list">

                    <li className="uif-instutute-res-count__item">
                        <p className="uif-instutute-res-count__value">
                            5 127
                        </p>
                        <p className="uif-instutute-res-count__name">
                            Студентов
                        </p>
                    </li>

                    <li className="uif-instutute-res-count__item">
                        <p className="uif-instutute-res-count__value">
                            1000+
                        </p>
                        <p className="uif-instutute-res-count__name">
                            Проведенных курсов и семинаров
                        </p>
                    </li>

                </ul>
            </div>
        </section>
    )
}

export default InstituteResultCount