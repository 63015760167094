import React from 'react'
import './CourseComments.scss'
import CommentSlider from '@components/sliders/CommentSlider/CommentSlider';
import { store } from '@store/index';
import { observer } from 'mobx-react-lite';

type CourseCommentsProps = {}

const CourseComments: React.FC<CourseCommentsProps> = observer(() => {

    const reviews = store.coursePage.initial?.course_reviews || []

    if (!reviews.length) return null

    return (
        <div className="uif-course-comments">
            <div className="uif-container">
                <div className="uif-course-comments__body">
                    <p className="uif-course-comments__title">
                        что говорят <span className="uif-course-comments__about-word">о нас</span>
                    </p>
                    <CommentSlider />
                </div>
            </div>
        </div>
    )
})

export default CourseComments