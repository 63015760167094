import React, { useState } from 'react'
import HeadLights from '@components/commonBlocks/HeadLights/HeadLights'
import ContactsTitle from './components/ContactsTitle/ContactsTitle'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import QuestionFormBlock from '@components/commonBlocks/QuestionFormBlock/QuestionFormBlock'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'
import ContactsData from './components/ContactsData/ContactsData'

interface ContactsPageProps {}

const ContactsPage: React.FC<ContactsPageProps> = observer(() => {

    const [show, setShow] = useState<boolean>(false)
    useNavbarSettings(store)

    return (
        <>
            <HeadLights />
            <ContactsTitle />

            <ContactsData />

            <QuestionFormBlock
                titlePage="Страница контактов"
                wrapClasses={'contacts-page'}
                onSuccess={() => setShow(true)}
            />
            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={show}
                onClose={() => setShow(false)}
            />
        </>
    )
})

export default ContactsPage