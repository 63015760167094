import UifButton from '@components/ui/buttons/UifButton/UifButton'
import { isNumber } from '@utils/computed'
import { сountWord, academicCountWord } from '@utils/parsers'
import React, { useState } from 'react'
import './CourseProgram.scss'
import CourseProgramItems from './components/CourseProgramItems/CourseProgramItems'
import { CourseTrainingStream } from 'types/apiTypes'
import UifLinkBtn from "@components/ui/buttons/UifLinkBtn/UifLinkBtn";
import { jsPDF } from "jspdf";

type CourseProgramProps = {
    trainingStream: CourseTrainingStream | []
}

const CourseProgram: React.FC<CourseProgramProps> = (props) => {
    const {
        trainingStream: data
    } = props

    const [collapseList, setCollapseList] = useState<boolean | null>(null)
    const [showCollapseBtn, setShowCollapseBtn] = useState<boolean>(false)

    const summaryHours = () => {
        if (data instanceof Array) return null

        return data?.programs?.reduce((acc, program) => {
            return acc + program.count_hours
        }, 0)
    }

    const handleCollapse = (val: boolean) => {
        setCollapseList(val)
    }

    const handleDownload = () => {
        let doc = new jsPDF({
            orientation: "l"
        });
        doc.addFont("../PTSans.ttf", "PTSans", "normal");
        //doc.addFont('https://use.fontawesome.com/releases/v5.3.1/webfonts/fa-regular-400.ttf', 'FontAwesome', 'normal');
        console.log(doc.getFontList());
        doc.setFont('PTSans');
        doc.setFontSize(12)
        //let source = window.document.querySelector<HTMLElement>('.uif-course-program-items__container');
        /*doc.html(source || '', {
                callback: function(doc){
                    doc.output("dataurlnewwindow");
                },
                x: 15,
                y: 15,
                width: 1920
            }
        )*/

        // @ts-ignore
        let dataRow = data?.programs.map((val : any, idx: number) => {
            return {
                'block': `Блок ${idx + 1} | ${val.count_hours} ак.ч`,
                'description' : val.title+'/n'+val.text
            };
        });
        console.log(dataRow);
        doc.table(15, 15, dataRow, ['block', 'description'],{});
        doc.output("dataurlnewwindow");
    }

    if (data instanceof Array || !data) return null

    return (
        <section className="uif-course-program">
            <div className="uif-container">
                <div
                    className="uif-course-program__title-block"
                    id="training-program"
                >
                    <p className="uif-course-program__title">
                        <span className="uif-course-program__title-word">программа</span>
                        <span className="uif-course-program__title-word">обучения</span>
                    </p>
                    {
                        isNumber(summaryHours()) &&
                        <div className="uif-course-program__summary-time">
                            <p className="uif-course-program__summary-time-value">
                                {summaryHours()}
                            </p>
                            <p className="uif-course-program__summary-time-description">
                                {
                                    `${academicCountWord(summaryHours() || 0)} 
                                ${сountWord(summaryHours() || 0, 'час')}`
                                }
                            </p>
                        </div>
                    }
                </div>
                <CourseProgramItems
                    streamData={data}
                    collapsed={collapseList}
                    itemsLength={data?.programs?.length || 0}
                    onChangeCollapse={handleCollapse}
                    onShowAllBtn={(val: boolean) => setShowCollapseBtn(val)}
                />
                <div className="uif-course-program__controls-wrapper">
                    <div className="uif-course-program__controls">
                        {
                            showCollapseBtn &&
                            <UifButton
                                styleType="dark"
                                className="uif-course-program__all-btn"
                                onClick={() => handleCollapse(!collapseList)}
                            >
                                {
                                    collapseList
                                        ? 'Вся программа'
                                        : 'Свернуть программу'
                                }
                            </UifButton>
                        }
                        {/*<UifLinkBtn
                            styleType="outline"
                            href="#"
                            className="uif-course-program__pdf-btn"
                            isTagA
                            onClick={handleDownload}
                        >
                            Получить PDF
                        </UifLinkBtn>*/}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CourseProgram