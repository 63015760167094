import RadioGroup from '@components/form/RadioGroup/RadioGroup'
import UifButton from '@components/ui/buttons/UifButton/UifButton'
import { timetableOptions } from '@utils/filters'
import { withBreakpoints } from 'breakpoints'
import React from 'react'
import { TimetableCatalogFilterResp } from 'types/apiTypes'
import { SetFieldValue, TimetableFilterFields } from 'types/formTypes'
import PointModal from '../PointModal/PointModal'
import './TimetableFilterModal.scss'

type TimetableFilterModalProps = {
    values: TimetableFilterFields
    filters: TimetableCatalogFilterResp['filters'] | undefined
    setFieldValue: SetFieldValue<TimetableFilterFields>

    isShow: boolean
    clickX: number
    clickY: number
    onDeny: () => void
    onApply: () => void
}

const TimetableFilterModal: React.FC<TimetableFilterModalProps> = (props) => {

    const {
        isShow, clickX, clickY,
        filters, values,
        setFieldValue, onDeny, onApply,
    } = props

    const handleChangeSelect = (fieldName: keyof TimetableFilterFields, value: string) => {
        if (value === '') return setFieldValue(fieldName, '')
        setFieldValue(fieldName, value)
    }

    return (
        <PointModal
            isShow={isShow}
            clickX={clickX}
            clickY={clickY}
            wrapClasses={['uif-filter-timetable']}
            zIndex={5000}
            closeMinWidth={768}
            onClose={onDeny}
        >
            <div
                className="uif-timetable-filter-modal"
                onClick={e => e.stopPropagation()}
            >
                <div className="uif-container">
                    <div className="uif-timetable-filter-modal__body">
                        <div className="uif-timetable-filter-modal__top-block">
                            <p className="uif-timetable-filter-modal__title">Фильтры</p>
                            <button
                                className="uif-timetable-filter-modal__close-btn"
                                onClick={onDeny}
                            >
                                <img src="static/img/ui/opened-burger.svg" alt="Меню" className="opened-img" />
                            </button>
                        </div>

                        <div className="uif-timetable-filter-modal__filters">
                            <RadioGroup
                                title="Город"
                                radios={timetableOptions(filters?.cities)}
                                checkedValue={values.city}
                                groupName="city-timetable-modal"
                                wrapClasses={['timetable-filter-radios']}
                                onChange={e => handleChangeSelect('city', e.target.value)}
                            />
                            <RadioGroup
                                title="Направление"
                                radios={timetableOptions(filters?.directions)}
                                checkedValue={values.direction}
                                groupName="direction-timetable-modal"
                                wrapClasses={['timetable-filter-radios']}
                                onChange={e => handleChangeSelect('direction', e.target.value)}
                            />
                            {/* <CheckboxesGroup
                                title={timeTableModalFilters.actions.titleGroup}
                                checkboxes={timeTableModalFilters.actions.checkboxes}
                                wrapClasses={['timetable-filter-checkbox']}
                            /> */}
                        </div>

                        <div className="uif-timetable-filter-modal__controls">
                            <UifButton
                                styleType="dark"
                                className="uif-course-catalog-filter-modal__apply-btn uif-btn uif-btn-dark active"
                                onClick={onApply}
                            >
                                Применить
                            </UifButton>
                        </div>
                    </div>
                </div>
            </div>
        </PointModal>
    )
}

export default withBreakpoints<TimetableFilterModalProps>(TimetableFilterModal)