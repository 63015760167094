import moment from "moment"

type EntriesQueriesToStringParams = {
    params?: string[][]
    withQuestion?: boolean
}

const getTimeOffset = () => {
    const rowMinutesOffset = (new Date()).getTimezoneOffset() * -1
    const hoursOffset = Math.trunc(rowMinutesOffset / 60)
    const minutesOffset = rowMinutesOffset - (hoursOffset * 60)
    return {
        hoursOffset,
        minutesOffset
    }
}

export const parsePhone = (value: string) => {
    const forbidden = ['+', ' ', ')', '(', '_', '-']

    return value
        .split('')
        .filter(word => !forbidden.includes(word))
        .join('')
}

export const replaceEntersToSpan = (value: string, className?: string) => {
    return value.split('\n').map((word, i) => (
        <span key={i} className={className}>
            {word}
        </span>
    ))
}

export const сountWord = (val: number, baseWord: string) => {
    const lastTwoNumbers = +(val + '').slice(-2)
    const lastNumber = +(val + '').slice(-1)

    if (lastTwoNumbers >= 5 && lastTwoNumbers <= 20) return `${baseWord}ов`
    if (lastNumber >= 2 && lastNumber <= 4) return `${baseWord}а`
    if (lastNumber >= 5 && lastNumber <= 9) return `${baseWord}ов`
    if (lastNumber === 0) return `${baseWord}ов`

    return baseWord
}

export const placeCountWord = (val: number) => {
    const lastTwoNumbers = +(val + '').slice(-2)
    const lastNumber = +(val + '').slice(-1)
    let baseWord = 'мест'

    if (lastTwoNumbers >= 5 && lastTwoNumbers <= 20) return baseWord
    if (lastNumber >= 2 && lastNumber <= 4) return `${baseWord}а`
    if (lastNumber === 1) return `${baseWord}о`
    if (lastNumber === 0) return baseWord

    return baseWord
}

export const academicCountWord = (val: number) => {
    const lastNumber = +(val + '').slice(-1)
    let baseWord = 'академическ'

    if (lastNumber === 1) return `${baseWord}ий`
    return `${baseWord}их`
}

export const monthsCountWord = (val: number) => {
    const lastNumber = +(val + '').slice(-1)
    const lastTwoNumber = +(val + '').slice(-2)

    let baseWord = 'месяц'

    if (
        lastNumber === 0 ||
        (lastTwoNumber >= 10 && lastTwoNumber <= 20) ||
        (lastNumber >= 5 && lastNumber <= 9)
    ) return `${baseWord}ев`

    if (lastNumber >= 2 && lastNumber <= 4) return `${baseWord}а`

    return baseWord
}


export const salarySpace = (val: number | string) => Number.parseInt(val + '').toLocaleString('ru')

export const dbTimeToMoment = (val: string) => {
    const date = moment()
    if (val.length !== 14) {
        console.warn('Invalid db time, must be 14 characters')
        return null
    }
    const { hoursOffset, minutesOffset } = getTimeOffset()

    date.set('year', +val.slice(0, 4));
    date.set('months', +val.slice(4, 6) - 1);
    date.set('date', +val.slice(6, 8));

    date.set('hours', +val.slice(8, 10) + hoursOffset);
    date.set('minutes', +val.slice(10, 12) + minutesOffset);
    date.set('seconds', +val.slice(12, 14));
    return date
}

export const telNumberToLink = (val: string) => {
    const matches = val.match(/[+]|[0-9]/igm) || []
    const tel = matches.join('')
    return `tel:${tel ? tel : val}`
}

export const getSearchParams = (search: string) => {
    const searchParams = {} as { [key: string]: string }
    const pairs = search.slice(1).split('&')

    pairs.forEach(pair => {
        const param = pair.split('=')
        const key = param[0]
        const value = param[1]
        if (key && value) {
            searchParams[key] = value
        }
    })
    return searchParams
}

export const updateSearchParam = (search: string, paramName: string, value: string) => {
    const pairs = search.slice(1).split('&')
    const params = {} as { [key: string]: string }
    pairs.forEach(pair => {
        if (!pair) return null;
        let queryParam = pair.split('=')
        const name = queryParam[0]
        const oldValue = queryParam[1]
        params[name] = oldValue
    })
    params[paramName] = value

    const newPairs = Object.entries(params).map(([name, value]) => `${name}=${value}`)
    return '?' + newPairs.join('&')
}

export const entriesQueriesToString = ({
    params = [],
    withQuestion= true,
}: EntriesQueriesToStringParams) => {
    let queryString = params
        .map(entry => entry.join('='))
        .join('&')
    if (withQuestion) queryString = '?' + queryString
    return queryString
}

export const queryStringToEntries = (search: string) => {
    const queries = search.replace('?', '').split('&')
    const entries = queries.map(q => q.split('='))
    return entries
}