import { coursePageApi } from '@api/requestFunctions'
import FeedbackModal from '@components/modals/FeedbackModal/FeedbackModal'
import QuestionModal from '@components/modals/QuestionModal/QuestionModal'
import Warranty from '@components/commonBlocks/Warranty/Warranty'
import { store } from '@store/index'
import { useNavbarSettings } from '@utils/hooks/useNavbarSettings'
import { getSearchParams } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router'
import { RouteComponentProps } from 'react-router-dom'
import { CoursePageInitialResp, CourseTrainingStream } from 'types/apiTypes'
import CourseCenter from './components/CourseCenter/CourseCenter'
import CourseComments from './components/CourseComments/CourseComments'
import CourseFAQ from './components/CourseFAQ/CourseFAQ'
import CoursePerspectives from './components/CoursePerspectives/CoursePerspectives'
import CoursePrice from './components/CoursePrice/CoursePrice'
import CourseProgram from './components/CourseProgram/CourseProgram'
import CourseResults from './components/CourseResults/CourseResults'
import CourseSalary from './components/CourseSalary/CourseSalary'
import CourseTeachers from './components/CourseTeachers/CourseTeachers'

type CoursePageParams = { courseId: string }

interface CoursePageProps extends RouteComponentProps<CoursePageParams> { }

const CoursePage: React.FC<CoursePageProps> = observer((props) => {

    const {
        history
    } = props

    const initialCityIdFromUrl = +getSearchParams(history.location.search)?.city_id || null
    const courseState = store.coursePage

    const course = courseState.initial
    const storeCourseId = course?.id || 0
    const courseName = course?.name || ''
    const courseCode = course?.code

    const [showFeedbackModal, setShowFeedbackModal] = useState<boolean>(false)
    const [showQuestionModal, setShowQuestionModal] = useState<boolean>(false)
    const [trainingStreamByCity, setTrainingStreamByCity] = useState<CourseTrainingStream | []>([])

    const { courseId } = useParams<CoursePageParams>()

    useQuery<CoursePageInitialResp, Error>(
        `course-page-init-${courseId}`,
        coursePageApi.getAllInfo.bind(this, +courseId),
        {
            refetchOnWindowFocus: false,
            retry: 1,
            retryDelay: 1500,
            onSuccess: (data) => {
                console.log(`course page init, id:${courseId}`, data)
                courseState.updateInit(data)
            },
            onError: (err) => {
                console.log(`course page ERROR init, id:${courseId}`, err)
                if (err.message.includes('400')) history.push('/')
            },
        }
    )

    const handleSuccessQuestion = () => {
        setShowQuestionModal(false)
        setShowFeedbackModal(true)
    }

    useEffect(() => {
        if (storeCourseId !== +courseId) {
            courseState.updateInit(null)
            courseState.statusAnimate(false)
        } else {
            courseState.statusAnimate(true)
        }
        return () => {
            courseState.statusAnimate(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storeCourseId])

    useEffect(() => {
        /// get trainingStream by searchParams with trainingPrograms!
        let trainingStream: CourseTrainingStream | [] = []
        if (course) {
            const cityFromSearchParams = course.cities.find(c => c.id === initialCityIdFromUrl)
            const stream = cityFromSearchParams?.training_stream

            if (cityFromSearchParams && !(stream instanceof Array) && stream?.programs?.length) {
                trainingStream = cityFromSearchParams.training_stream
            } else {
                const cityWithStream = course.cities.find(c => {
                    const stream = c.training_stream
                    if (!(stream instanceof Array) && stream?.programs?.length) return true
                    return false
                })
                if (cityWithStream) {
                    trainingStream = cityWithStream.training_stream
                }
            }
        }
        setTrainingStreamByCity(trainingStream)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialCityIdFromUrl, course])

    useEffect(() => {
        const prevCourse = courseState.prevCourse
        if (prevCourse && prevCourse.id === +courseId) {
            courseState.updateInit(prevCourse)
        }
        return () => {
            courseState.updatePrevCourse()
            courseState.clearInitial()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useNavbarSettings(store)

    return (
        <>
            <CourseCenter
                trainingStream={trainingStreamByCity}
            />
            <CoursePerspectives />
            <CourseSalary />
            <CourseProgram
                trainingStream={trainingStreamByCity}
            />
            <CourseTeachers />

            <CourseComments />
            <CourseResults />

            {
                !!course?.is_internship &&
                <Warranty />
            }

            <CoursePrice onSuccess={() => setShowFeedbackModal(true)} />
            <CourseFAQ
                wrapClasses={['course-page']}
                onClickQuestion={() => setShowQuestionModal(true)}
            />

            <FeedbackModal
                title="Заявка отправлена"
                description="Наши менеджеры скоро свяжутся с Вами. Ожидайте звонка с номера телефона: 8 (343) 311 03 01"
                isShow={showFeedbackModal}
                onClose={() => setShowFeedbackModal(false)}
            />

            <QuestionModal
                feedbackOptions={{
                    pageTitle: `Страница курса '${courseName}', блок 'Вопросы и ответы'`,
                    nomenclatureСode: courseCode
                }}
                isShow={showQuestionModal}
                onClose={() => setShowQuestionModal(false)}
                onSuccess={handleSuccessQuestion}
            />
        </>
    )
})

export default CoursePage