import { lockBody, unlockBody } from '@utils/styleUtils'
import React, { useEffect } from 'react'
import FadeModal from '../FadeModal/FadeModal'
import './FeedbackModal.scss'
import UifButton from '@components/ui/buttons/UifButton/UifButton'

type FeedbackModalProps = {
    isShow: boolean
    wrapClasses?: string[]
    title?: string
    description?: string
    onClose?: () => void
}

const FeedbackModal: React.FC<FeedbackModalProps> = React.memo((props) => {

    const {
        isShow, description,
        title, onClose,
    } = props

    useEffect(() => {
        if (isShow) return lockBody()
        unlockBody()
    }, [isShow])

    return (
        <FadeModal
            isShow={isShow}
            onClose={onClose}
        >
            <div className="uif-feedback-body" onClick={e => e.stopPropagation()}>
                {
                    title &&
                    <p className="uif-feedback-body__title">
                        {title}
                    </p>
                }
                {
                    description &&
                    <p className="uif-feedback-body__description">
                        {description}
                    </p>
                }
                <div className="uif-feedback-body__controls">
                    <UifButton
                        styleType="dark"
                        className="uif-feedback-body__control uif-btn uif-btn-dark"
                        onClick={onClose}
                    >
                        Хорошо
                    </UifButton>
                </div>
            </div>
        </FadeModal>
    )
})

export default FeedbackModal