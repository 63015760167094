import { apiBaseUrl } from '@api/urls'
import { store } from '@store/index'
import { dbTimeToMoment, replaceEntersToSpan } from '@utils/parsers'
import React from 'react'
import { CoursePageInitialResp } from 'types/apiTypes'
import './CommentSlide.scss'

type CommentSlideProps = {
    item: CoursePageInitialResp['course_reviews'][number]
}

const CommentSlide: React.FC<CommentSlideProps> = (props) => {

    const { item } = props

    const course = store.coursePage.initial

    return (
        <div className="uif-comments-slide">
            <div className="uif-comments-slide__content">
                <div className="uif-comments-slide__top">
                    <div className="uif-comments-slide__trainer-img">
                        <img
                            src={apiBaseUrl + item.avatar}
                            alt="Атаева Алсу"
                        />
                    </div>
                    <div className="uif-comments-slide__info-text">
                        <p className="uif-comments-slide__name">
                            {`${item.first_name_review} ${item.last_name_review}`}
                        </p>
                        <p className="uif-comments-slide__course">
                            {course?.name}
                        </p>
                        {
                            item.date &&
                            <p className="uif-comments-slide__date">
                                {dbTimeToMoment(item.date)?.format('D MMMM YYYY')}
                            </p>
                        }
                    </div>
                </div>
                <div className="uif-comments-slide__body">
                    <p className="uif-comments-slide__comment">
                        {replaceEntersToSpan(item.text, 'uif-span-devider')}
                    </p>
                </div>
            </div>
            <div className="uif-comments-slide__links-block">
                {/* <a href="vk.com" className="uif-comments-slide__link">
                    <img src="/static/img/vk-icon.svg" alt="VK" className="uif-comments-slide__link-icon" />
                    <span className="uif-comments-slide__link-text">Задать вопрос</span>
                </a> */}
            </div>
        </div>
    )
}

export default CommentSlide