import React from 'react'
import './RadioGroup.scss'
import InputRadio from '../InputRadio/InputRadio'
import { InputSelectOption } from 'types/formTypes'

type RadioGroupProps = {
    title: string
    checkedValue: string | number
    radios: InputSelectOption[]
    groupName: string
    wrapClasses?: string[]
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const RadioGroup: React.FC<RadioGroupProps> = (props) => {
    const {
        radios, title, wrapClasses,
        groupName, checkedValue,
        onChange,
    } = props

    const groupClasses = () => {
        let classes = ['uif-input-group']
        if (wrapClasses) classes = [...classes, ...wrapClasses]
        return classes.join(' ')
    }

    return (
        <div className={groupClasses()}>
            <p className="uif-input-group__title">
                {title}
            </p>
            <ul>
                {
                    radios.map((checkbox, i) => (
                        <li key={i}>
                            <InputRadio
                                title={checkbox.text}
                                value={checkbox.value}
                                checked={checkedValue === checkbox.value}
                                name={groupName}
                                onChange={onChange}
                            />
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export default RadioGroup