import { apiBaseUrl } from '@api/urls'
import ImgFade from '@components/optimization/ImgFade/ImgFade'
import { isNumber } from '@utils/computed'
import { dbTimeToMoment } from '@utils/parsers'
import { clickWithScroll } from '@utils/styleUtils'
import React from 'react'
import { Link } from 'react-router-dom'
import { TimetableCatalogItem } from 'types/apiTypes'
import './TimetableCourse.scss'

type CoachType = {
    id: number
    first_name: string
    last_name: string
    avatar_id: string
    quality: string
}

type TimetableCourseProps = {
    time: string
    name: string
    tags: string[]
    price: TimetableCatalogItem['price']
    coach: CoachType | []
    cityId: string
    courseId?: number
}

const TimetableCourse: React.FC<TimetableCourseProps> = (props) => {

    const {
        time, name, tags,
        price, coach, courseId,
        cityId,
    } = props

    const date_start = dbTimeToMoment(time || '')?.format('LT')

    const courseLink = () => {
        let link = '#'
        if (courseId) {
            link = `course/${courseId}`
            if (cityId) link += `?city_id=${cityId}`
        }
        return link
    }

    return (
        <div className="uif-timetable-course">
            <Link
                to={courseLink()}
                className="uif-timetable-course__date-block"
                onClick={clickWithScroll}
            >
                <p className="uif-timetable-course__time">
                    {date_start}
                </p>
            </Link>
            <Link
                to={courseLink()}
                className="uif-timetable-course__info-block"
                onClick={clickWithScroll}
            >
                <p className="uif-timetable-course__name">
                    {name}
                </p>
                <div className="uif-timetable-course__tags-block">
                    {
                        tags.filter(t => t).map((tag, i) => (
                            <p
                                className="uif-timetable-course__tag"
                                key={i}
                            >
                                {tag}
                            </p>
                        ))
                    }
                </div>
                { /**
                    !(price instanceof Array) && isNumber(price.price) &&
                    <p className="uif-timetable-course__price">
                        {price.price.toLocaleString('ru')} ₽
                    </p>
                */}
            </Link>
            {
                !(coach instanceof Array) &&
                <div className="uif-timetable-course__coaches-block">
                    <Link
                        to={`/teacher/${coach.id}`}
                        className="uif-timetable-course__coach"
                        onClick={clickWithScroll}
                    >
                        <ImgFade
                            className="uif-timetable-course__coach-img"
                            src={apiBaseUrl + coach.avatar_id}
                            alt="coach"
                            gradientPlaceholder
                        />
                        <div className="uif-timetable-course__coach-info-block">
                            <p className="uif-timetable-course__coach-name">
                                {`${coach.first_name} ${coach.last_name}`}
                            </p>
                            <p className="uif-timetable-course__coach-description">
                                {coach.quality}
                            </p>
                        </div>
                    </Link>
                </div>
            }
            <img
                src="/static/img/timetable-hover-bg.png"
                alt="bg"
                className="uif-timetable-course__hover-bg"
            />
        </div>
    )
}

export default TimetableCourse