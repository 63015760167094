import { store } from '@store/index'
import { isNumber } from '@utils/computed'
import { salarySpace } from '@utils/parsers'
import { observer } from 'mobx-react-lite'
import React from 'react'
import './CourseSalary.scss'


type CourseSalaryProps = {}

const CourseSalary: React.FC<CourseSalaryProps> = observer(() => {

    const data = store.coursePage.initial?.salary

    if (data instanceof Array || !data) return null

    return (
        <section className="uif-course-salary">
            <div className="uif-container">
                <div className="uif-course-salary__content">
                    <div className='uif-course-salary__text-block'>
                        {
                            isNumber(data?.salary) &&
                            <p className="uif-course-salary__price">
                                {
                                    `${salarySpace(data?.salary || '')}`
                                }&nbsp;₽
                            </p>
                        }
                        <p className="uif-course-salary__place">
                            {data?.text}
                        </p>
                        <p className="uif-course-salary__description">По средним данным вакансий HH.ru</p>
                    </div>
                    <p
                        className="uif-course-salary__icon"
                    >
                        hh
                    </p>
                </div>
            </div>
        </section>
    )
})

export default CourseSalary