import { courseCatalogFilterStore } from "./courseCatalogFilterStore";
import { coursePageState } from "./coursePageStore";
import { mainPageState } from "./mainPageStore";
import { settingsStore } from "./settingsStore";
import { timetableCatalogFilterStore } from "./timetableCatalogFilterStore";
import { navbarStore } from './navbarStore'
import { teachersPageState } from './teachersPageStore';
import { teacherPageState } from './teacherPageStore';
import { paymentOrderState } from './paymentOrderStore';
import { institutePageState } from './institutePageStore';

export const store = {
    mainPage: mainPageState,
    coursePage: coursePageState,
    settings: settingsStore,
    courseCatalogFilters: courseCatalogFilterStore,
    timetableCatalogFilters: timetableCatalogFilterStore,
    navBar: navbarStore,
    teachersPage: teachersPageState,
    teacherPage: teacherPageState,
    paymentOrder: paymentOrderState,
    institutePage: institutePageState,
}