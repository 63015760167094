import FeedbackForm from '@components/form/FeedbackForm/FeedbackForm'
import { feedbackValidate } from '@utils/formValidators'
import { useFormik } from 'formik'
import React, { useState } from 'react'
import { FeedbackFormValues } from 'types/formTypes'
import { initialFeedbackValues } from '@data/forms'
import './QuestionFormBlock.scss'
import { useMutation } from 'react-query'
import { requestApi } from '@api/requestFunctions'
import { yandexMetricaApi, googleAnalyticsApi } from '../../../otherOrigins/metricsApi'

type QuestionFormBlockProps = {
    wrapClasses: string
    titlePage: string
    onSuccess?: () => void
}

const QuestionFormBlock: React.FC<QuestionFormBlockProps> = React.memo((props) => {
    const {
        wrapClasses, titlePage,
        onSuccess,
    } = props

    const [formError, setFormError] = useState<string>('')

    const onSubmit = (values: FeedbackFormValues) => {
        feedbackMutation.mutate(values)
    }

    const {
        values, errors, touched,
        submitForm, handleChange, handleBlur,
        setValues,
    } = useFormik<FeedbackFormValues>({
        initialValues: initialFeedbackValues,
        validateOnChange: false,
        validate: feedbackValidate,
        onSubmit,
    })

    const feedbackMutation = useMutation<unknown, Error, FeedbackFormValues>(
        (formValues) => requestApi.feedbackSend(formValues, {pageTitle: titlePage}),
        {
            mutationKey: 'feedback-mutation',
            onSuccess() {
                if (onSuccess) onSuccess()
                setValues(initialFeedbackValues)
                yandexMetricaApi.goalLead()
                googleAnalyticsApi.goalLead()
            },
            onError() { setFormError('Возникла ошибка при отправке') },
            onMutate() { setFormError('') }
        }
    )

    const blockClasses = () => {
        let classes = ['uif-question-block']
        if (wrapClasses) classes.push(wrapClasses)
        return classes.join(' ')
    }

    return (
        <section className={blockClasses()}>
            <div className="uif-container">
                <div className="uif-question-block__content">
                    <div className="uif-question-block__text">
                        <p className="uif-question-block__title">
                            {`получить \nконсультацию`}
                        </p>
                        <p className="uif-question-block__description">
                            Ответим на все вопросы о выборе курса, процессе обучения, стоимости и развеем все сомнения
                        </p>
                    </div>
                    <FeedbackForm
                        wrapClasses={['questions-form']}
                        isFetching={feedbackMutation.isLoading}
                        errorMessage={formError}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        onSubmit={submitForm}
                    />
                </div>
            </div>
        </section>
    )
})

export default QuestionFormBlock