import { apiBaseUrl } from '@api/urls'
import ImgFade from '@components/optimization/ImgFade/ImgFade'
import { clickWithScroll } from '@utils/styleUtils'
import React from 'react'
import { Link } from 'react-router-dom'
import './TeacherItem.scss'

interface TeacherItemProps {
    firstName: string
    secondName: string
    quality: string
    imgSrc: string
    id: number
}

const TeacherItem: React.FC<TeacherItemProps> = (props) => {

    const {
        firstName, secondName, quality,
        imgSrc, id,
    } = props

    return (
        <Link
            to={`/teacher/${id}`}
            className="uif-teacher-item"
            onClick={clickWithScroll}
        >
            <div className="uif-teacher-item__img-block">
                <ImgFade
                    src={apiBaseUrl + imgSrc}
                    alt="Преподаватель"
                    className="uif-teacher-item__img"
                />
            </div>
            <div className="uif-teacher-item__info-block">
                <p className="uif-teacher-item__name">
                    {firstName} <br /> {secondName}
                </p>
                <p className="uif-teacher-item__quality">
                    {quality}
                </p>
            </div>
        </Link>
    )
}

export default TeacherItem