import React from 'react'
import MainServiceItem from '../MainServiceItem/MainServiceItem'
import './MainServices.scss'
import { store } from '@store/index'
import { observer } from 'mobx-react-lite'
import ImgFade from '@components/optimization/ImgFade/ImgFade'

type ServicesProps = {}

const MainServices: React.FC<ServicesProps> = observer(() => {

    const goals = store.mainPage.initial?.goals || []
    const thirdBlock = store.mainPage.initial?.third_block
    const siteSettings = store.settings.initial?.settings || []

    const thirdDescription = siteSettings
        .find(s => s.hasOwnProperty('tag_text'))
        ?.tag_text + '' || ''

    const itemClass = (idx: number) => {
        let className = 'sec-block'
        if (idx === 0) className = 'first-block'
        if (idx === 3) className = 'third-block'
        return className
    }

    return (
        <>
            <section className="uif-main-sticker-sm-wrapper">
                <img
                    src="/static/img/upward-sticker.png"
                    alt="upward"
                    className="uif-main-sticker-sm"
                />
            </section>

            <section
                className="uif-main-services"
            >
                <div className="uif-container uif-main-services__container">
                    <div className="uif-main-services__lights-wrapper">
                        <img src="/static/img/parallax-lights.png" alt="lights" className="uif-main-services__lights" />
                    </div>
                    <div className="uif-main-services__content">
                        <ImgFade
                            src="/static/img/upward-sticker.png"
                            alt="upward"
                            className="uif-main-services__sticker"
                        />
                        {
                            goals.map((goal, i) => (
                                <MainServiceItem
                                    key={goal.id}
                                    goalId={goal.id}
                                    name={goal.name}
                                    description={goal.description}
                                    courses={goal.courses}
                                    courseCount={goal.courses_count}
                                    wrapClasses={[itemClass(i)]}
                                />
                            ))
                        }
                        {
                            thirdBlock &&
                            <MainServiceItem
                                name={thirdBlock.name}
                                description={thirdDescription}
                                courses={thirdBlock.courses}
                                courseCount={thirdBlock.courses_count}
                                wrapClasses={[itemClass(2)]}
                            />
                        }

                    </div>
                </div>
            </section>
        </>
    )
})

export default MainServices