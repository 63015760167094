import { replaceEntersToSpan } from '@utils/parsers'
import { clickWithScroll } from '@utils/styleUtils'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import './LinksBlockPaginate.scss'
import PaginateEndMessage from '@components/paginate/PaginateEndMessage/PaginateEndMessage'
import PaginateLoader from '@components/paginate/PaginateLoader/PaginateLoader'
import PaginateMorePlaceholder from '@components/paginate/PaginateMorePlaceholder/PaginateMorePlaceholder'
import InfiniteScroll from 'react-infinite-scroll-component'


type LinksBlockPaginateProps = {
    links: Array<{
        title: string
        path: string
    }>
    page: number
    maxPage: number
    isInitializedFilter: boolean
    isFetching: boolean
    onFetch: () => void
    placeholder?: string
    className?: string
    withEndMessage?: boolean
    scrollThreshold?: string
}

const LinksBlockPaginate: React.FC<LinksBlockPaginateProps> = (props) => {
    const {
        links, placeholder, className,
        page, maxPage,
        isInitializedFilter, isFetching,
        onFetch,
        withEndMessage = false,
        scrollThreshold = '1000px',
    } = props

    const hasMore = maxPage > page
    const listClasses = className ? `${className} uif-links-block-paginate` : 'uif-links-block-paginate'

    useEffect(() => {
        const isAvailableScroll = document.body.clientHeight > window.innerHeight
        if (!isAvailableScroll && hasMore) onFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [links, page, maxPage])

    return (
        <>
            <ul className={listClasses}>
                <InfiniteScroll
                    dataLength={links.length}
                    next={onFetch}
                    hasMore={hasMore}
                    scrollThreshold={scrollThreshold}
                    loader={<PaginateLoader text="Загрузка курсов..." />}
                    endMessage={
                        withEndMessage &&
                        <PaginateEndMessage
                            isInitFilter={isInitializedFilter}
                            itemsLength={links.length}
                        />
                    }
                    style={{ overflow: 'none' }}
                >
                    {
                        links.map(link => (
                            <li className="uif-links-block-paginate__item" key={link.path + link.title}>
                                <Link
                                    to={link.path}
                                    className="uif-links-block-paginate__item-link"
                                    onClick={clickWithScroll}
                                >
                                    <span className="uif-links-block-paginate__item-text">
                                        {replaceEntersToSpan(link.title, 'uif-span-devider')}
                                    </span>
                                    <div className="uif-links-block-paginate__item-arrow">
                                        <img
                                            className="default-arrow"
                                            src="/static/img/ui/arrow.svg"
                                            alt="arrow"
                                        >
                                        </img>
                                        <img
                                            className="hover-arrow"
                                            src="/static/img/ui/arrow-hover.svg"
                                            alt="."
                                        >
                                        </img>
                                    </div>
                                </Link>
                            </li>
                        ))
                    }
                </InfiniteScroll>
                <PaginateMorePlaceholder
                    show={hasMore && isInitializedFilter && !isFetching}
                />
            </ul>
            {
                !links.length && placeholder &&
                <p className="uif-links-block__placeholder">
                    {placeholder}
                </p>
            }
        </>
    )
}

export default LinksBlockPaginate