import InputRadioText from '@components/form/InputRadioText/InputRadioText'
import React from 'react'
import './CoursesCatalogCategories.scss'
import { CatalogCoursesFilterFields, SetFieldValue } from 'types/formTypes'
import { CourseCatalogFilterResp } from 'types/apiTypes'
import { countArray } from '@utils/dataUtils'
import { withBreakpoints } from 'breakpoints'

type CoursesCatalogCategoriesProps = {
    directionGroups: CourseCatalogFilterResp['filters']['group_directions']
    checked: string
    setFieldValue: SetFieldValue<CatalogCoursesFilterFields>
}

const CoursesCatalogCategories: React.FC<CoursesCatalogCategoriesProps> = React.memo((props) => {

    const {
        checked, directionGroups,
        setFieldValue,
    } = props

    return (
        <div className="uif-courses-categories">
            <div className="uif-container">
                <div className="uif-courses-categories__body">
                    <form className="uif-courses-categories__list">
                        {
                            directionGroups.length
                                ? <>
                                    <InputRadioText
                                        wrapClasses={['uif-courses-categories__label']}
                                        title="Все"
                                        value={''}
                                        checked={checked === ''}
                                        onChange={() => setFieldValue('group_direction', '')}
                                    />
                                    {
                                        directionGroups.map(d => (
                                            <InputRadioText
                                                wrapClasses={['uif-courses-categories__label']}
                                                key={d.id}
                                                title={d.name}
                                                value={d.id + ''}
                                                checked={checked.includes(d.id + '')}
                                                onChange={() => setFieldValue('group_direction', d.id+'')}
                                            />
                                        ))
                                    }
                                </>
                                : <ul className="uif-courses-categories__list-placeholder">
                                    {
                                        countArray(5).map((_, i) => (
                                            <li key={i} className="uif-gradient-loader"></li>
                                        ))
                                    }
                                </ul>
                        }
                    </form>
                </div>
            </div>
        </div>
    )
})

export default withBreakpoints<CoursesCatalogCategoriesProps>(CoursesCatalogCategories)