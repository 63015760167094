import NavbarModal from '@components/modals/NavbarModal/NavbarModal'
import { LinkNavbar, navLinks } from '@data/navLinks'
import { store } from '@store/index'
import { telNumberToLink } from '@utils/parsers'
import { clickWithScroll } from '@utils/styleUtils'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import './MainNavbarLayout.scss'
import {Logo} from "@pages/MainPage/components/MainCenter/components/logo/Logo";
import {ClosedBurger} from "@components/layout/MainNavbarLayout/components/Icons/ClosedBurger";
import {OpenedBurger} from "@components/layout/MainNavbarLayout/components/Icons/OpenedBurger";

type NavigationControl = {
    text: string
    onClick: () => void
    className?: string
}

interface MainNavbarLayoutProps extends RouteComponentProps {
    controls?: NavigationControl[]
}

const MainNavbarLayout: React.FC<MainNavbarLayoutProps> = observer((props) => {
    const {
        children,
        controls,
    } = props

    const telNumber = store.settings.initial?.settings?.find(setting => !!setting.tel_number)?.tel_number || ''

    const hasScrolledPage = () => window.scrollY > 0 || window.pageYOffset > 0

    const [isScrolled, setScrolled] = useState<boolean>(hasScrolledPage())
    const [showBurger, setShowBurger] = useState<boolean>(false)
    const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 })

    const showLogo = store.navBar.defaultShowLogo || isScrolled

    const headerClasses = () => {
        let classes = ['uif-main-nav']
        const rowClasses = store.navBar.rowClasses
        if (isScrolled) classes.push('scrolled')
        if(showBurger) classes.push('burger-btn-show')
        if (rowClasses) classes = [...classes, ...rowClasses]
        return classes.join(' ')
    }

    const handleBurger = (e?: React.MouseEvent) => {
        if (e) {
            const x = e.clientX
            const y = e.clientY
            setClickPosition({ x, y })
        }
        setShowBurger(!showBurger)
    }

    const handleClickLogo = (e: React.MouseEvent) => {
        clickWithScroll()
        if (showBurger) handleBurger(e)
    }

    const linkClasses = (link: LinkNavbar) => {
        const classes = ['uif-main-nav__link']
        if (link.special) classes.push('special')
        return classes.join(' ')
    }

    useEffect(() => {
        const handleScroll = (e: Event) => {
            if (hasScrolledPage()) {
                return setScrolled(true)
            }
            setScrolled(false)
        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            console.log('removed');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <section className={headerClasses()}>
                <div className="uif-container">
                    <nav className="uif-main-nav__line">

                        <Link
                            to="/"
                            className={showLogo ? "uif-main-nav__logo show" : "uif-main-nav__logo"}
                            onClick={handleClickLogo}
                        >
                            <Logo
                                className="uif-main-nav__logo-img"
                            />
                        </Link>

                        <ul className="uif-main-nav__links">
                            {
                                navLinks.map((link, i) => (
                                    <li
                                        className="uif-main-nav__link-wrapper"
                                        key={i}
                                    >
                                        {
                                            link.tagA
                                                ? <a
                                                    href={link.path}
                                                    className={linkClasses(link)}
                                                    target={link.newTab ? "_blank" : undefined}
                                                    rel="noreferrer"
                                                >
                                                    {link.name}
                                                </a>
                                                : <Link
                                                    to={link.path}
                                                    className={linkClasses(link)}
                                                    onClick={clickWithScroll}
                                                >
                                                    {link.name}
                                                </Link>
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                        <div className="uif-main-nav__contacts">
                            {
                                telNumber &&
                                <a
                                    href={telNumberToLink(telNumber + '')}
                                    className="uif-main-nav__tel"
                                >
                                    {telNumber}
                                </a>
                            }
                            <p className="uif-main-nav__city">Екатеринбург</p>
                        </div>
                        {
                            controls && controls.length &&
                            <div className="uif-main-nav__controls">
                                {
                                    controls.map((item, i) => (
                                        <button
                                            key={i}
                                            className={`uif-main-nav__control uif-btn uif-btn-dark${' ' + item.className}`}
                                            onClick={item.onClick}
                                        >
                                            { item.text}
                                        </button>
                                    ))
                                }
                            </div>
                        }
                        <button
                            className={showBurger ? "uif-main-nav__burger-btn open" : "uif-main-nav__burger-btn"}
                            onClick={handleBurger}
                        >
                            <OpenedBurger className="opened-img" />
                            <ClosedBurger className="closed-img" />
                        </button>
                    </nav>
                </div>
            </section>
            <div className="uif-main-nav-content-container" style={{ zIndex: 1 }}>
                {
                    children
                }
            </div>
            <NavbarModal
                isShow={showBurger}
                clickX={clickPosition.x}
                clickY={clickPosition.y}
                onClose={handleBurger}
            />
        </>
    )
})

export default withRouter(MainNavbarLayout)