import PaginateEndMessage from '@components/paginate/PaginateEndMessage/PaginateEndMessage'
import PaginateLoader from '@components/paginate/PaginateLoader/PaginateLoader'
import PaginateMorePlaceholder from '@components/paginate/PaginateMorePlaceholder/PaginateMorePlaceholder'
import React, { useEffect } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { CourseCatalogCourseItem } from 'types/apiTypes'
import CourseCatalogCard from '../CourseCatalogCard/CourseCatalogCard'
import './CourseCatalogCards.scss'

type CourseCatalogCardsProps = {
    page: number
    maxPage: number
    courses: CourseCatalogCourseItem[]
    isInitializedFilter: boolean
    isFetching: boolean
    onFetch: () => void
}

const CourseCatalogCards: React.FC<CourseCatalogCardsProps> = React.memo((props) => {

    const {
        page, courses, maxPage,
        isInitializedFilter, isFetching,
        onFetch,
    } = props

    const hasMore = maxPage > page

    const cardClasses = () => {
        let classes = ['uif-courses-cards']
        if (isFetching) classes.push('loading')
        return classes.join(' ')
    }

    useEffect(() => {
        const isAvailableScroll = document.body.clientHeight > window.innerHeight
        if (!isAvailableScroll && hasMore) onFetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courses, page, maxPage])

    return (
        <section className={cardClasses()}>
            <InfiniteScroll
                dataLength={courses.length}
                next={onFetch}
                hasMore={hasMore}
                scrollThreshold="2500px"
                loader={<PaginateLoader text="Загрузка курсов..." />}
                endMessage={
                    <PaginateEndMessage
                        isInitFilter={isInitializedFilter}
                        itemsLength={courses.length}
                    />
                }
                style={{ overflow: 'none' }}
            >
                {
                    courses.map(c => (
                        <CourseCatalogCard
                            title={c.name}
                            courseId={c.course_id}
                            date={c.date_start}
                            bgImage={c.background}
                            formTraining={c.form_training_name}
                            key={c.id}
                        />
                    ))
                }
            </InfiniteScroll>
            <PaginateMorePlaceholder
                show={hasMore && isInitializedFilter && !isFetching}
            />
            {
                !isInitializedFilter &&
                <div className="uif-courses-cards__placeholder"></div>
            }
        </section>
    )
})

export default CourseCatalogCards