import UifButton from '@components/ui/buttons/UifButton/UifButton'
import InputFloatLabel from '@components/form/InputFloatLabel/InputFloatLabel'
import React from 'react'
import './PaymentOrderConfirmForm.scss'
import { useFormik } from 'formik'
import { PaymentOrderConfirmFormFields } from 'types/formTypes'
import { initialPaymentOrderConfirm, validatePaymentOrderConfirm } from './formData/paymentOrderConfirmFormData'
import { useState } from 'react'
import InputCheckbox from '@components/form/InputCheckbox/InputCheckbox'
import { useMutation } from 'react-query'
import { PaymentOrderCreateResp } from 'types/apiTypes'
import { paymentOrderApi } from '@api/requestFunctions'
import { observer } from 'mobx-react-lite'
import { store } from '@store/index'

interface PaymentOrderConfirmFormProps {}

const PaymentOrderConfirmForm: React.FC<PaymentOrderConfirmFormProps> = observer(() => {

    const ordNumber = store.paymentOrder.confirmInfo?.order.serviceId || ''
    const goods = store.paymentOrder.confirmInfo?.order.goods || []

    const [formError, setFormError] = useState<string>('')

    const handleSubmit = (values: PaymentOrderConfirmFormFields) => {
        setFormError('')
        createMutate.mutate(values)
    }

    const {
        values, errors, touched,
        setFieldValue, submitForm,
    } = useFormik<PaymentOrderConfirmFormFields>({
        initialValues: initialPaymentOrderConfirm,
        validate: validatePaymentOrderConfirm,
        onSubmit: handleSubmit,
    })

    const createMutate = useMutation<PaymentOrderCreateResp, Error, PaymentOrderConfirmFormFields>({
        mutationKey: 'payment-order-create-pay',
        mutationFn: async () => paymentOrderApi.createPay(ordNumber, goods),
        onSuccess(data) {
            const url = data.payUrl
            if (!data.error) {
                if (url) window.location.href = url
                return
            }
            // error
            let errorMessage = 'Ошибка при попытке оплаты. Попробуйте обновить страницу.'
            if (data.error_code) errorMessage += ` Код: ${data.error_code}.`
            if (data.error_message) errorMessage += ` Сообщение: ${data.error_message}.`
            setFormError(errorMessage)
        },
        onError(e) {
            console.log(`Возникла ошибка при попытке оплаты: ${e}`)
            setFormError(`Возникла ошибка при попытке оплаты. Попробуйте обновить страницу`)
        }
    })

    const isSetching = createMutate.isLoading

    const isEmailError = touched.email ? !!errors.email : false

    return (
        <div className="uif-payment-order-confirm-form">
            <div className="uif-container">
                <form
                    className="uif-payment-order-confirm-form__form"
                    onSubmit={e => e.preventDefault()}
                >
                    <div className="uif-payment-order-confirm-form__inputs">
                        <InputFloatLabel
                            value={values.email}
                            isError={isEmailError}
                            placeholder="E-mail для отправки чека"
                            wrapClasses={['uif-payment-order-confirm-form__input-email']}
                            onChange={e => setFieldValue('email', e.target.value)}
                        />
                    </div>
                    <div className="uif-payment-order-confirm-form__controls">
                        <UifButton
                            styleType="dark"
                            className="uif-payment-order-confirm-form__submit"
                            loading={isSetching}
                            onClick={isSetching ? undefined : submitForm}
                        >
                            Оплатить
                        </UifButton>
                        <InputCheckbox
                            title="Согласие с условиями оплаты"
                            className="uif-payment-order-confirm-form__input-agree"
                            value={Number(values.agreement)}
                            checked={values.agreement}
                            styleMark="check"
                            onChange={() => setFieldValue('agreement', !values.agreement)}
                        />
                    </div>
                    {
                        formError &&
                        <p className="uif-payment-order-confirm-form__error">
                            {formError}
                        </p>
                    }
                    {
                        isEmailError &&
                        <p className="uif-payment-order-confirm-form__error">
                            {errors.email}
                        </p>
                    }
                    {
                        errors.agreement &&
                        <p className="uif-payment-order-confirm-form__error">
                            {errors.agreement}
                        </p>
                    }
                </form>
            </div>
        </div>
    )
})

export default PaymentOrderConfirmForm