import React from 'react'
import './TimetableDay.scss'

type TimetableDayProps = {
    date: string
}

const TimetableDay: React.FC<TimetableDayProps> = (props) => {

    const {
        date, children,
    } = props

    return (
        <div className="uif-timetable-day">
            <div className="uif-container">
                <p className="uif-timetable-day__title">
                    {date}
                </p>
                <div className="uif-timetable-day__list-container">
                    {
                        children
                    }
                </div>
            </div>
        </div>
    )
}

export default TimetableDay